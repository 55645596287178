import * as React from 'react';
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { createCustomEqual, deepEqual } from "fast-equals";

interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle: (map: google.maps.Map) => void;
    children?: React.ReactNode;
    options?: any;
}

export default function GoogleMap(props: MapProps) {
    /*
    const deepCompareEqualsForMaps = createCustomEqual(
        (deepEqual) => (a: any, b: any) => {
          if (
            isLatLngLiteral(a) ||
            a instanceof google.maps.LatLng ||
            isLatLngLiteral(b) ||
            b instanceof google.maps.LatLng
          ) {
            return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
          }
      
          // TODO extend to other types
      
          // use fast-equals for other objects
          return deepEqual(a, b);
        }
    );
    */

    function useDeepCompareMemoize(value: any) {
      const ref = React.useRef();
      
      //if (!deepCompareEqualsForMaps(value, ref.current)) {
      if (!deepEqual(value, ref.current)) { 
        ref.current = value;
      }
      
      return ref.current;
    }
      
    function useDeepCompareEffectForMaps(
      callback: React.EffectCallback,
      dependencies: any[]
    ) {
      React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
    }

    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();
  
    React.useEffect(() => {
      if (ref.current && !map) {
        console.log("Creating a new map");
        setMap(new window.google.maps.Map(ref.current, {}));
      }
    }, [ref, map]);
  
    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    useDeepCompareEffectForMaps(() => {
      console.log("Do a deep compare")
      if (map) {
        map.setOptions(props.options);
      }
    }, [map, props.options]);
  
    React.useEffect(() => {
      if (map) {
        ["click", "idle"].forEach((eventName) =>
          google.maps.event.clearListeners(map, eventName)
        );
  
        if (props.onClick) {
          map.addListener("click", props.onClick);
        }
  
        if (props.onIdle) {
          map.addListener("idle", () => props.onIdle(map));
        }
      }
    }, [map, props.onClick, props.onIdle]);
  
    return (
      <>
        <div ref={ref} style={props.style} />
        {React.Children.map(props.children, (child) => {
          if (React.isValidElement(child)) {
            // set the map prop on the child component
            console.log("Set map prop");
            return React.cloneElement(child, map);
          }
        })}
      </>
    );
};