import { addToBOM } from './MiscHelperFunctions.js';
import { getPriceForProduct, getCurrency, getValueAddedTax } from './PricelistMap.js';
import { ceilToNext500 } from './PricesHelperFunctions.js';
import { getProductById } from './CalculateHelperFunctions.js';
import { getProductDescriptionForLanguage } from './ProductI18nHelperFunctions.js';
import { translate } from '../../../Helper/Dictionary.js';
import { Yard } from '@mui/icons-material';

const availableBatteryModes = ["withoutBattery", "withBattery", "withEmergencyPower"];

export function getElectricComponentsBOMs(availableProducts, wP, bomProfile, tenant, typeOfCustomer, bomProperties, salesCountry, languageTag) {
    var discretekWp = wP.toFixed(0);
    const electricComponentsForkWp = getElectricComponentsForkWp(getElectricComponentMatrixes(availableProducts), discretekWp, salesCountry);
    
    const electricComponentsBOMs = new Object();

    const dcCableExpenses = [1,1,1,1,1,1,1,1,1,1,1.1,1.1,1.1,1.2,1.2,1.2,1.2,1.3,1.3,1.3];
    const acCableExpenses = [1,1,1,1,1,1,1,1,1,1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1,1.1];

    for (var availableBatteryMode of availableBatteryModes) {		
        electricComponentsBOMs[availableBatteryMode] = new Object();
        electricComponentsBOMs[availableBatteryMode]["bom"] = new Array();
        bomProperties[availableBatteryMode] = new Object();
        bomProperties[availableBatteryMode]["index"] = 0;
        bomProperties[availableBatteryMode]["sum"] = 0;
        const electricComponents = Object.keys(electricComponentsForkWp[availableBatteryMode]["matrix"]);
        for (var electricComponent of electricComponents) {
            const filteredProducts = availableProducts.miscProducts.filter(product => product.id == electricComponent);
            if (electricComponentsForkWp[availableBatteryMode]["matrix"][electricComponent] > 0) {
                electricComponentsBOMs[availableBatteryMode]["bom"].push(addToBOM(
                    bomProperties[availableBatteryMode]["index"] + 1,
                    electricComponentsForkWp[availableBatteryMode]["matrix"][electricComponent].toString(),
                    filteredProducts[0].code,
                    getProductDescriptionForLanguage(filteredProducts[0], languageTag),
                    getPriceForProduct(filteredProducts[0], tenant, typeOfCustomer, salesCountry),
                    bomProfile,
                    tenant,
                    typeOfCustomer,
                    salesCountry,
                    bomProperties[availableBatteryMode]
                ));
                bomProperties[availableBatteryMode]["index"] += 1;
            }
        }
        electricComponentsBOMs[availableBatteryMode]["bom"].push(addToBOM(
            bomProperties[availableBatteryMode]["index"] + 1,
            '1',
            getProductById(availableProducts.miscProducts, 308).code,
            getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 308), languageTag),
            getPriceForProduct(getProductById(availableProducts.miscProducts, 308), tenant, typeOfCustomer, salesCountry),
            bomProfile,
            tenant,
            typeOfCustomer,
            salesCountry,
            bomProperties[availableBatteryMode]
        ));
        bomProperties[availableBatteryMode]["index"] += 1;

        if (bomProfile == 1 && typeOfCustomer == "consumer") {
            console.log("add expenses for ac- and dc-cabling because of consumer mode");
            console.log("dc-cable costs for wp: " + discretekWp + " are: " + dcCableExpenses[discretekWp - 1]);
            console.log(dcCableExpenses);
            let dcCoeff = 1100;
            let acCoeff = 2800;
            if(salesCountry == "CH") {
                dcCoeff *= 1.1;
                acCoeff *= 1.1;
            }
            if ((discretekWp - 1) < dcCableExpenses.length) {
                bomProperties[availableBatteryMode]["sum"] += dcCableExpenses[discretekWp - 1] * dcCoeff;
                bomProperties[availableBatteryMode]["sum"] += acCableExpenses[discretekWp - 1] * acCoeff;
            } else {
                bomProperties[availableBatteryMode]["sum"] += dcCableExpenses.at(-1) * dcCoeff;
                bomProperties[availableBatteryMode]["sum"] += acCableExpenses.at(-1) * acCoeff;
            }

        }   


        if(bomProfile == 1 && typeOfCustomer != "consumer") {
            //bom.push(["","","","","Netto",'EUR ' + sum.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', currencyDisplay: "code"})]);
            electricComponentsBOMs[availableBatteryMode]["bom"].push(["","","","","Netto", getCurrency(tenant, typeOfCustomer, salesCountry) + ' ' + bomProperties[availableBatteryMode]["sum"].toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })]);
            electricComponentsBOMs[availableBatteryMode]["bom"].push(["","","","","MwSt.", getCurrency(tenant, typeOfCustomer, salesCountry) + ' ' + (bomProperties[availableBatteryMode]["sum"]*getValueAddedTax(salesCountry, typeOfCustomer)).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })]);
            electricComponentsBOMs[availableBatteryMode]["bom"].push(["","","","","Brutto", getCurrency(tenant, typeOfCustomer, salesCountry) + ' ' + (bomProperties[availableBatteryMode]["sum"]*(1 + getValueAddedTax(salesCountry, typeOfCustomer))).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })]);
        } else if (bomProfile == 1 && typeOfCustomer == "consumer") {
			console.log ('BOOOOOOOMGENERATE');
			console.log ('ElectricComponents');
			console.log (ceilToNext500(bomProperties[availableBatteryMode]["sum"]*(1 + getValueAddedTax(salesCountry, typeOfCustomer))));
		
            electricComponentsBOMs[availableBatteryMode]["bom"].push([translate("labelPackageExpenses", languageTag),"","", getCurrency(tenant, typeOfCustomer, salesCountry) + ' ' + ceilToNext500(bomProperties[availableBatteryMode]["sum"]*(1 + getValueAddedTax(salesCountry, typeOfCustomer))).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            + ` (${translate("labelIncludes", languageTag)} ` + getCurrency(tenant, typeOfCustomer, salesCountry) + ' ' + (ceilToNext500(bomProperties[availableBatteryMode]["sum"]*(1 + getValueAddedTax(salesCountry, typeOfCustomer))) * (1 - 1/(1 + getValueAddedTax(salesCountry, typeOfCustomer)))).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' MwSt.) '
            + '\n' + translate('labelPackagePriceDisclaimer', languageTag)]);
        }
        
        if ( electricComponentsForkWp[availableBatteryMode]["extrapolated"] == true) {
            console.log("Result for electrical components is extrapolated.");
            bomProperties[availableBatteryMode]["sum"] += electricComponentsForkWp[availableBatteryMode]["x"] * electricComponentsForkWp[availableBatteryMode]["m"];
            console.log("Sum is: " + bomProperties[availableBatteryMode]["sum"]);
            electricComponentsBOMs[availableBatteryMode]["extrapolated"] = true;
            electricComponentsBOMs[availableBatteryMode]["bom"] = null;
        }
    }

    return electricComponentsBOMs;
}

const getElectricComponentsForkWp = (electricComponentsMatrixes, discretekWp) => {
    const electricComponentsForkWp = new Object();

    for (var availableBatteryMode of availableBatteryModes) {
        const maxCalculated = parseInt(electricComponentsMatrixes[availableBatteryMode]["maxCalculated"]);
        electricComponentsForkWp[availableBatteryMode] = new Object();

        if ( discretekWp > maxCalculated ) {
            electricComponentsForkWp[availableBatteryMode]["matrix"] = electricComponentsMatrixes[availableBatteryMode][maxCalculated];
            electricComponentsForkWp[availableBatteryMode]["extrapolated"] = true;
            electricComponentsForkWp[availableBatteryMode]["x"] = discretekWp - maxCalculated;
            electricComponentsForkWp[availableBatteryMode]["m"] = parseInt(electricComponentsMatrixes[availableBatteryMode]["m"]);
        } else {
            electricComponentsForkWp[availableBatteryMode]["matrix"] = electricComponentsMatrixes[availableBatteryMode][discretekWp];
            electricComponentsForkWp[availableBatteryMode]["extrapolated"] = false;
        }
    }

    console.log(electricComponentsForkWp);

    return electricComponentsForkWp;
};

const getElectricComponentMatrixes = (availableProducts) => {
    const electricComponentsMatrixes = new Object();
    for (var virtualProduct of availableProducts.virtualProducts) {
        if (virtualProduct.id == "291") {
            electricComponentsMatrixes["withoutBattery"] = JSON.parse(virtualProduct.description);
        } else if (virtualProduct.id == "292") {
            electricComponentsMatrixes["withBattery"] = JSON.parse(virtualProduct.description);
        } else if (virtualProduct.id == "293") {
            electricComponentsMatrixes["withEmergencyPower"] = JSON.parse(virtualProduct.description);
        }
    }
    return electricComponentsMatrixes;
};