import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import bg from '../assets/loginBackground.jpg';
import autarqLogo from '../assets/autarqLogo.jpg';

const theme = createTheme({
    palette: {
      primary: {
        main: '#fff365',
      },
      secondary: {
        main: '#11cb5f',
      },
    },
});

export default function SignInPage(props: any) {
    const { instance } = useMsal();

    function handleLogin(instance: any) {
        instance.loginRedirect(loginRequest).catch((e: any) => {
            console.error(e);
        });
    }

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh', backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
                >
                    <Box borderRadius='5px' sx={{ p: 5, backgroundColor: 'white' }}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                        >
                            <img src={autarqLogo} alt='Autarq Logo' height={325/400*120} width={120}></img>
                        </Grid>
                        <Typography variant="h6" component="h2" sx={{ my: 4}}>
                            Planungstool-App
                        </Typography>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                        >
                        <Button variant="contained" onClick={() => handleLogin(instance)}>
                            Anmelden
                        </Button>
                        </Grid>
                    </Box>
                </Grid>
            </ThemeProvider>
        </React.Fragment>
      );
}