import React, { useState, useEffect } from 'react';
import { DataGrid, GridFilterModel } from '@mui/x-data-grid';
import { useMsal } from "@azure/msal-react";
import { queryMiddleware } from "../../Helper/queryMiddleware";
import { loginRequest } from "../../authConfig";
import { translate } from '../../Helper/Dictionary';
import * as XLSX from "xlsx";
import Button from '@mui/material/Button';

const DealDataGrid = (props: any) => {
  const columns = [
    { field: 'title', headerName: translate('tableHeaderBuildingProject', props.guiLanguage), width: 340 },
    { field: "279f244bc10becfe935f1db8970a109c49bf9cdc", headerName: translate('tableHeaderStreet', props.guiLanguage), width: 160 },
    { field: "5d496f9741b2fd4154fd61db66da95b339bd6edd", headerName: translate('tableHeaderStreetNumber', props.guiLanguage), width: 110 },
    { field: "1c533384908157aa5efca3abc5b434cc75c32390", headerName: translate('tableHeaderZIP', props.guiLanguage), width: 70 },
    { field: "456fc648964c054c2fb67cd8109bfcd5dcc7a55e", headerName: translate('tableHeaderCity', props.guiLanguage), width: 100 }
  ]

  const {instance, accounts} = useMsal();

  const [tableData, setTableData] = useState([]);

  var selectedEntry = new Map();
  var selectedDeal = new Map();

  useEffect(() => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };
    let url = `/api/v1/organizations`;
    const body  = {
      limit: "500",
      archived_status: "not_archived",
      sort: "update_time DESC"
    };

	console.log (accounts);

    instance.acquireTokenSilent(request).then((response) => {				
      queryMiddleware(url, body, "POST", response.accessToken)
        .then((data) => {
			console.log (data.data)
			setTableData(data.data)
		});
		
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        queryMiddleware(url, body, "POST", response.accessToken)
          .then((data) => setTableData(data.data))
      });
    });
  }
  , [])

  const getParticipantsForDeal = (dealId: String) => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    const url   = `/api/v1/deal-participants`;
    const body  = {
      dealId: dealId,
    };

    instance.acquireTokenSilent(request).then((response) => {
      queryMiddleware(url, body, "POST", response.accessToken)
        .then((data) => handleParticipantsQueryResponse(data.data))
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        queryMiddleware(url, body, "POST", response.accessToken)
          .then((data) => handleParticipantsQueryResponse(data.data))
      });
    }); 
  };

  const handleParticipantsQueryResponse = (data: any) => {
    selectedEntry.set("participants", data);
    props.setSelectedDeal(selectedEntry);


  };

  const getDealDataForDeal = (dealId: String) => {
	const request = {
		...loginRequest,
		account: accounts[0]
	};

	const url = `/api/v1/deal-details`;
	const body = {
		dealId: dealId
	}

    instance.acquireTokenSilent(request).then((response) => {
		queryMiddleware(url, body, "POST", response.accessToken)
		  .then((data) => handledetailsQueryResponse(data.data))
	  }).catch((e) => {
		instance.acquireTokenPopup(request).then((response) => {
		  queryMiddleware(url, body, "POST", response.accessToken)
			.then((data) => handledetailsQueryResponse(data.data))
		});
	  }); 
  };

  const handledetailsQueryResponse = (data: any) => {
	selectedDeal.set("details", data);
	props.sethandleDealData(selectedDeal);
  };



  const handleOnClick = (rowData: any): void => {
    for (const Deal of tableData) {
        if(rowData.id.toString() == Deal["id"]) {
            const myMap = new Map<string, string>(
                [
                    ["id", Deal["id"]],
                    ["company", Deal["org_id"]],
                    ["name", Deal["person_name"]],
                    ["title", Deal["title"]],
                    ["street", Deal["279f244bc10becfe935f1db8970a109c49bf9cdc"]],
                    ["streetNumber", Deal["5d496f9741b2fd4154fd61db66da95b339bd6edd"]],
                    ["zip", Deal["1c533384908157aa5efca3abc5b434cc75c32390"]],
                    ["city", Deal["456fc648964c054c2fb67cd8109bfcd5dcc7a55e"]],
                    ["country", Deal["e6cb070619c24efda514c7f70f545c3799a4de8e"]],
                    ["longitude", Deal["7d779ba3d2de13ab5f747c2afcb7767a775a9c26"]],
                    ["latitude", Deal["c725a440f1645feb7f455407d1ffc6d77a743ca5"]],
                    ["owner", Deal["creator_user_id"]["name"]],
                    ["date", Deal["update_time"]]
                ]
            )
            selectedEntry = myMap;
            getParticipantsForDeal(Deal["id"]);
			getDealDataForDeal(Deal["id"]);
            break;
        }
    };      
  }

	function filteredModel(model: GridFilterModel): void {
		const titleFilter = model.items[0].value;
		if (titleFilter) {
			console.log (titleFilter);

		}
	}

	function formateDate (date: any) {
		const originalDate = date;
		const dateObject = new Date(originalDate);
		const day = dateObject.getDate().toString().padStart(2, '0');
		const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
		const year = dateObject.getFullYear().toString();
		const formattedDate = `${day}/${month}/${year}`;
		return formattedDate;
	}

	function setxml (data : any) {
		
		
		let xslData = [];
		for (const Deal of data) {
			let x = [];		
			if (Deal.vl_name != undefined) {
				x.push (Deal.fb_name);
				x.push (Deal.vl_name);
				x.push (Deal.region);
			}
			x.push (formateDate (Deal.add_time));
			x.push (Deal["ea95dd17d4234226b1568774ebb1c070f4f5f43f"]?.["name"]);
			x.push (Deal.title);
			x.push (Deal.formatted_value);
			x.push (Deal.status);
			x.push ('');
			x.push ('');
			x.push (Deal["1c533384908157aa5efca3abc5b434cc75c32390"]);
			x.push (Deal["6968c2a22e6cd6b64307a5085152fde600f028e7"]);
			x.push (Deal["1e8e56a1d9bd6e74c3723ff87bbb0d8ccaefbb40"]);
			

			//let x = ["1", "2", "3"];
			xslData.push (x);
		}
		console.log (xslData);
		downloadXSL (xslData, data[0]?.vl_name);

	}

function downloadXSL(data : any, check: any) {
console.log (data);	
	let header = [];
	const columnWidths = [];
	if (check != undefined) {
		header.push ('FB -Fachberater\n(Warenempfänger)');
		header.push ('VL WE\n(Warenempfänger)');
		header.push ('VL-Region');

		columnWidths.push ({wch: 20});
		columnWidths.push ({wch: 20});
		columnWidths.push ({wch: 10});
	}

	header.push ('Angebots-datum');
	header.push ('Verarbeiter');
	header.push ('Bauvorhaben');
	header.push ('Auftrag Wert\nDach + Elektro');
	header.push ('Status');
	header.push ('wahrscheinlichkeit');
	header.push ('Bemerkung');
	header.push ('PLZ');
	header.push ('Ziegelauswahl');
	header.push ('Ziegelanzahl');

	const workbook = XLSX.utils.book_new();
	const worksheet = XLSX.utils.aoa_to_sheet([
	  header,
	  ...data
	]);

	columnWidths.push ({wch: 10});
	columnWidths.push ({wch: 20});
	columnWidths.push ({wch: 40});
	columnWidths.push ({wch: 15});
	columnWidths.push ({wch: 10});
	columnWidths.push ({wch: 10});
	columnWidths.push ({wch: 10});
	columnWidths.push ({wch: 10});
	columnWidths.push ({wch: 10});
	columnWidths.push ({wch: 8});

	worksheet['!cols'] = columnWidths;
	worksheet['!rows'] = [{ hpt: 30 }];

	XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
	const xslxContent = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });



	
  
	const blob = new Blob([xslxContent], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	const url = URL.createObjectURL(blob);
  
	const link = document.createElement('a');
	link.href = url;
	link.download = 'data.xlsx';
	link.click();
  
	URL.revokeObjectURL(url);
  }

  return (
    <div style={{ height: 740, width: '100%' }}>
		<Button
        	variant="contained"
            onClick={() => setxml(tableData)}
		>
	  		Xlsx herunterladen
	  	</Button>
      	<DataGrid
			rows={tableData}
			columns={columns}
			pageSize={12}
			rowsPerPageOptions={[12]}
			onRowClick={(rowData) => handleOnClick(rowData)}
			onFilterModelChange={(model) => filteredModel(model)}
      />
    </div>
  )
}

export default DealDataGrid
