import { siteSchemePicturedeDE, siteSchemePictureenDE } from "./Assets/SiteSchemePictures";

const dictionary = {
  //mostly GUI elements
  labelTerms: [
    { language: 'de-DE', text: 'Bedingungen' },
    { language: 'en-DE', text: 'Terms' }
  ],
  labelDisclaimerUpperText: [
    {
      language: 'de-DE',
      text: 'Geschäftsführer Kai Buntrock | Cornelius Paul\nAmtsgericht Neuruppin / HRD 11577 NP / Ust.-ID-Nummer: DE 282 856 901 /\nSteuernummer 062/105/0248',
    },
    {
      language: 'en-DE',
      text: 'director Kai Buntrock | Cornelius Paul\nAmtsgericht Neuruppin / HRD 11577 NP / value added tax number: DE 282 856 901 /\ntax number 062/105/0248',
    },
  ],
  labelDisclaimerLowerText: [
    {
      language: 'de-DE',
      text: 'Autarq GmbH, Brüssower Allee 87, 17291 Prenzlau, Germany\nwww.creaton.de / www.creaton.at / www.creaton.ch / www.autarq.com',
    },
    {
      language: 'en-DE',
      text: 'Autarq GmbH, Brüssower Allee 87, 17291 Prenzlau, Germany\nwww.creaton.de / www.creaton.at / www.creaton.ch / www.autarq.com',
    },
  ],
  labelMonth: [
    { language: 'de-DE', text: 'Monat' },
    { language: 'en-DE', text: 'Month' }
  ],
  labelCancel: [
    { language: 'de-DE', text: 'Abbrechen' },
    { language: 'en-DE', text: 'Cancel' }
  ],
  labelErrorOnUpload: [
    { language: 'de-DE', text: 'Fehler beim Erstellen des Angebotes!' },
    { language: 'en-DE', text: 'Error while creating the offer!' }
  ],
  labelCoordinates: [
    { language: 'de-DE', text: 'Koordinaten' },
    { language: 'en-DE', text: 'Coordinates' }
  ],
  textWarningDeliveryTime: [
    { language: 'de-DE', text: 'VORSICHT, SEHR LANGE UND UNSICHERE LIEFERZEITEN - BITTE DEN KUNDEN ÜBER DIESE SITUATION AUFKLÄREN'},
    { language: 'en-DE', text: 'BEWARE, VERY LONG AND UNSURE DELIVERY TIME - PLEASE INFORM THE CUSTOMER'}
  ],
  labelSelectOfferLanguage: [
    { language: 'de-DE', text: 'Angebotssprache wählen' },
    { language: 'en-DE', text: 'Select offer language' },
  ],
  labelPlanningTool: [
    { language: 'de-DE', text: 'Planungstool' },
    { language: 'en-DE', text: 'Planning Tool' },
  ],
  labelDealSelection: [
    { language: 'de-DE', text: 'Deal-Auswahl' },
    { language: 'en-DE', text: 'Deal selection' },
  ],
  labelSelectDeal: [
    { language: 'de-DE', text: 'Deal Auswählen' },
    { language: 'en-DE', text: 'Select deal' },
  ],
  labelSelectAgent: [
    { language: 'de-DE', text: 'Agentenauswahl' },
    { language: 'en-DE', text: 'Agent selection' },
  ],
  labelTechnicalInput: [
    { language: 'de-DE', text: 'Technische Dokumentation' },
    { language: 'en-DE', text: 'Technical Documentation' },
  ],
  labelTechnicalInformation: [
    { language: 'de-DE', text: 'Technische Information' },
    { language: 'en-DE', text: 'Technical Information' },
  ],
  labelReviewOfferPage: [
    { language: 'de-DE', text: 'Angebot überprüfen' },
    { language: 'en-DE', text: 'Review offer' },
  ],
  labelDownloadOfferPage: [
    { language: 'de-DE', text: 'Angebot herunterladen' },
    { language: 'en-DE', text: 'Download offer' },
  ],
  labelAddressDetermination: [
    { language: 'de-DE', text: 'Adressermittlung' },
    { language: 'en-DE', text: 'Address determination' },
  ],
  labelAddressOfBuildingProject: [
    { language: 'de-DE', text: 'Adresse des Bauprojektes' },
    { language: 'en-DE', text: 'Address of building project' },
  ],
  tableHeaderBuildingProject: [
    { language: 'de-DE', text: 'Bauvorhaben (Projektadresse)' },
    { language: 'en-DE', text: 'Building project (project address)' },
  ],
  tableHeaderStreet: [
    { language: 'de-DE', text: 'Straße' },
    { language: 'en-DE', text: 'Street' },
  ],
  tableHeaderStreetNumber: [
    { language: 'de-DE', text: 'Hausnummer' },
    { language: 'en-DE', text: 'House number' },
  ],
  tableHeaderZIP: [
    { language: 'de-DE', text: 'PLZ' },
    { language: 'en-DE', text: 'Postcode' },
  ],
  tableHeaderCity: [
    { language: 'de-DE', text: 'Stadt' },
    { language: 'en-DE', text: 'City' },
  ],
  buttonNext: [
    { language: 'de-DE', text: 'Weiter' },
    { language: 'en-DE', text: 'Next' },
  ],
  buttonBack: [
    { language: 'de-DE', text: 'Zurück' },
    { language: 'en-DE', text: 'Back' },
  ],
  buttonChangelog: [
    { language: 'de-DE', text: 'PLZ' },
    { language: 'en-DE', text: 'Postcode' },
  ],
  labelSelectTenant: [
    { language: 'de-DE', text: 'Mandant auswählen' },
    { language: 'en-DE', text: 'Select tenant' },
  ],
  labelSelectRecipient: [
    { language: 'de-DE', text: 'Adressat auswählen' },
    { language: 'en-DE', text: 'Select recipient' },
  ],
  labelSelectGUILanguage: [
    { language: 'de-DE', text: 'Frontend-Sprache auswählen' },
    { language: 'en-DE', text: 'Select frontend language' },
  ],
  labelTenant: [
    { language: 'de-DE', text: 'Mandant' },
    { language: 'en-DE', text: 'Tenant' },
  ],
  labelRecipient: [
    { language: 'de-DE', text: 'Empfänger' },
    { language: 'en-DE', text: 'Recipient' },
  ],
  labelLanguage: [
    { language: 'de-DE', text: 'Sprache' },
    { language: 'en-DE', text: 'Language' },
  ],
  labelIndividual: [
    { language: 'de-DE', text: 'Einzelperson' },
    { language: 'en-DE', text: 'Individual' },
  ],
  labelIndividual: [
    { language: 'de-DE', text: 'Einzelperson' },
    { language: 'en-DE', text: 'Individual' },
  ],
  labelGerman: [
    { language: 'de-DE', text: 'Deutsch' },
    { language: 'en-DE', text: 'German' },
  ],
  labelEnglish: [
    { language: 'de-DE', text: 'Englisch' },
    { language: 'en-DE', text: 'English' },
  ],
  labelSouth: [
    { language: 'de-DE', text: 'Süd' },
    { language: 'en-DE', text: 'South' },
  ],
  labelNorth: [
    { language: 'de-DE', text: 'Nord' },
    { language: 'en-DE', text: 'North' },
  ],
  labelEast: [
    { language: 'de-DE', text: 'Ost' },
    { language: 'en-DE', text: 'East' },
  ],
  labelWest: [
    { language: 'de-DE', text: 'West' },
    { language: 'en-DE', text: 'West' },
  ],
  labelSouthEast: [
    { language: 'de-DE', text: 'Südost' },
    { language: 'en-DE', text: 'Southeast' },
  ],
  labelNorthEast: [
    { language: 'de-DE', text: 'Nordost' },
    { language: 'en-DE', text: 'Northeast' },
  ],
  labelNorthWest: [
    { language: 'de-DE', text: 'Nordwest' },
    { language: 'en-DE', text: 'Northwest' },
  ],
  labelSouthEast: [
    { language: 'de-DE', text: 'Südost' },
    { language: 'en-DE', text: 'Southeast' },
  ],
  labelSouthWest: [
    { language: 'de-DE', text: 'Südwest' },
    { language: 'en-DE', text: 'Southwest' },
  ],
  labelUnknown: [
    { language: 'de-DE', text: 'Unbekannt' },
    { language: 'en-DE', text: 'Unknown' },
  ],
  labelNot: [
    { language: 'de-DE', text: 'keine' },
    { language: 'en-DE', text: 'not' },
  ],
  labelLow: [
    { language: 'de-DE', text: 'leicht' },
    { language: 'en-DE', text: 'low' },
  ],
  labelMedium: [
    { language: 'de-DE', text: 'mittel' },
    { language: 'en-DE', text: 'medium' },
  ],
  labelHigh: [
    { language: 'de-DE', text: 'hoch' },
    { language: 'en-DE', text: 'high' },
  ],
  labelWithoutRoleIdentifier: [
    { language: 'de-DE', text: 'ohne Positionsbezeichnung' },
    { language: 'en-DE', text: 'without role identifier' },
  ],
  labelWithoutPosition: [
    { language: 'de-DE', text: 'Ohne Positionsbezeichnung' },
    { language: 'en-DE', text: 'Without position' },
  ],
  labelEnterTechnicalInput: [
    { language: 'de-DE', text: 'Technische Details eingeben' },
    { language: 'en-DE', text: 'Enter technical details' },
  ],
  labelShowMapsInPDF: [
    { language: 'de-DE', text: 'Zeige Bild im PDF' },
    { language: 'en-DE', text: 'Show Image in PDF' },
  ],
  labelTile: [
    { language: 'de-DE', text: 'Dachziegel' },
    { language: 'en-DE', text: 'Roof tile' },
  ],
  labelTypeOfBOM: [
    { language: 'de-DE', text: 'Stücklistenart' },
    { language: 'en-DE', text: 'Bill of material (BOM) type' },
  ],
  optionNormalBOM: [
    { language: 'de-DE', text: 'Stückliste' },
    { language: 'en-DE', text: 'Bill of material (BOM)' },
  ],
  optionElectroBOM: [
    { language: 'de-DE', text: 'Preisliste' },
    { language: 'en-DE', text: 'Price list' },
  ],
  labelElectroComponent: [
    { language: 'de-DE', text: 'Elektrokomponenten' },
    { language: 'en-DE', text: 'Electrical components' },
  ],
  optionWithoutElectro: [
    { language: 'de-DE', text: 'ohne Elektrokomponenten' },
    { language: 'en-DE', text: 'Without electrical components' },
  ],
  optionWithElectro: [
    { language: 'de-DE', text: 'nur Wechselrichter' },
    { language: 'en-DE', text: 'Standard solar inverter only' },
  ],
  optionWithElectroAndBattery: [
    { language: 'de-DE', text: 'Wechselrichter mit Batterie' },
    { language: 'en-DE', text: 'Standard solar inverter and battery storage' },
  ],
  optionWithElectroAndBatteryAndEmergencyPower: [
    { language: 'de-DE', text: 'Wechselrichter mit Batterie und Notstrom' },
    { language: 'en-DE', text: 'Standard solar inverter, battery storage and emergency power' },
  ],
  labelAddRoof: [
    { language: 'de-DE', text: 'Dach hinzufügen' },
    { language: 'en-DE', text: 'Add roof' },
  ],
  labelArea: [
    { language: 'de-DE', text: 'Nutzbare Fläche' },
    { language: 'en-DE', text: 'Usable area' },
  ],
  labelUseArea: [
    { language: 'de-DE', text: 'Fläche' },
    { language: 'en-DE', text: 'Roof area' },
  ],
  labelOrientation: [
    { language: 'de-DE', text: 'Himmelsrichtung' },
    { language: 'en-DE', text: 'Roof orientation' },
  ],
  labelAngle: [
    { language: 'de-DE', text: 'Neigung' },
    { language: 'en-DE', text: 'Pitch' },
  ],
  labelClouding: [
    { language: 'de-DE', text: 'Verschattung' },
    { language: 'en-DE', text: 'Clouding' },
  ],
  optionNone: [
    { language: 'de-DE', text: 'ohne Elektrokomponenten' },
    { language: 'en-DE', text: 'Without electrical components' },
  ],
  optionLow: [
    { language: 'de-DE', text: 'nur Wechselrichter' },
    { language: 'en-DE', text: 'Standard solar inverter only' },
  ],
  optionMedium: [
    { language: 'de-DE', text: 'Wechselrichter mit Batterie' },
    { language: 'en-DE', text: 'Standard solar inverter and battery storage' },
  ],
  optionHigh: [
    { language: 'de-DE', text: 'Wechselrichter mit Batterie und Notstrom' },
    { language: 'en-DE', text: 'Standard solar inverter, battery storage and emergency power' },
  ],
  buttonRemoveRoofs: [
    { language: 'de-DE', text: 'Dächer löschen' },
    { language: 'en-DE', text: 'Remove roofs' },
  ],
  labelDownloadOffer: [
    { language: 'de-DE', text: 'Indikative Kostenschätzung herunterladen' },
    { language: 'en-DE', text: 'Download indicative cost estimate' },
  ],
  buttonDownloadPreviewOffer: [
    { language: 'de-DE', text: 'Angebotsentwurf herunterladen' },
    { language: 'en-DE', text: 'Download preview offer' },
  ],
  buttonRetry: [
    { language: 'de-DE', text: 'Erneut versuchen' },
    { language: 'en-DE', text: 'Retry' },
  ],
  buttonUploadToPipedrive: [
    { language: 'de-DE', text: 'Upload Angebot zu Pipedrive' },
    { language: 'en-DE', text: 'Upload offer to Pipedrive' },
  ],
  buttonFinish: [
    { language: 'de-DE', text: 'Angebot erstellen' },
    { language: 'en-DE', text: 'Create offer' },
  ],
  labelAnnualPowerDemand: [
    { language: 'de-DE', text: 'Jährlicher Strombedarf' },
    { language: 'en-DE', text: 'Annual power demand' },
  ],
  labelFinish: [
    { language: 'de-DE', text: 'Vorgang Abgeschlossen!' },
    { language: 'en-DE', text: 'Process completed!' },
  ],
  labelFinishText: [
    { language: 'de-DE', text: 'Die technische Dokumentation wurde erfolgreich erstellt und kann nun im Vertriebskanal weitergegeben werden.' },
    { language: 'en-DE', text: 'The technical documentation has been successfully created and can now be shared in the distribution channel.' },
  ],
  labelErrorCalculationPVW: [
    { language: 'de-DE', text: 'Fehler bei der Berechnung der Anzahl der PVW!' },
    { language: 'en-DE', text: 'Error calculating PVW count!' },
  ],
  //mostly PDF elements
  labelDraft: [
    { language: 'de-DE', text: 'Entwurf' },
    { language: 'en-DE', text: 'Draft' },
  ],
  labelJanuary: [
    { language: 'de-DE', text: 'Januar' },
    { language: 'en-DE', text: 'January' },
  ],
  labelFebruary: [
    { language: 'de-DE', text: 'Februar' },
    { language: 'en-DE', text: 'February' },
  ],
  labelMarch: [
    { language: 'de-DE', text: 'März' },
    { language: 'en-DE', text: 'March' },
  ],
  labelApril: [
    { language: 'de-DE', text: 'April' },
    { language: 'en-DE', text: 'April' },
  ],
  labelMay: [
    { language: 'de-DE', text: 'Mai' },
    { language: 'en-DE', text: 'May' },
  ],
  labelJune: [
    { language: 'de-DE', text: 'Juni' },
    { language: 'en-DE', text: 'June' },
  ],
  labelJuly: [
    { language: 'de-DE', text: 'Juli' },
    { language: 'en-DE', text: 'July' },
  ],
  labelAugust: [
    { language: 'de-DE', text: 'August' },
    { language: 'en-DE', text: 'August' },
  ],
  labelSeptember: [
    { language: 'de-DE', text: 'September' },
    { language: 'en-DE', text: 'September' },
  ],
  labelOctober: [
    { language: 'de-DE', text: 'Oktober' },
    { language: 'en-DE', text: 'October' },
  ],
  labelNovember: [
    { language: 'de-DE', text: 'November' },
    { language: 'en-DE', text: 'November' },
  ],
  labelDecember: [
    { language: 'de-DE', text: 'Dezember' },
    { language: 'en-DE', text: 'December' },
  ],
  labelTotalAmount: [
    { language: 'de-DE', text: 'Gesamt' },
    { language: 'en-DE', text: 'Total' },
  ],
  textFooter: [
    { language: 'de-DE', text: 'Geschäftsführer: Kai Buntrock | Cornelius Paul\nAmtsgericht Neuruppin / HRB 11577 NP / Ust.-ID: DE 282 856 901 / Steuernummer 062/105/02848' },
    { language: 'en-DE', text: 'Managing Directors: Kai Buntrock | Cornelius Paul\nRegister court Neuruppin / HRB 11577 NP / VAT ID: DE 282 856 901 / Tax number: 062/105/02848' },
  ],
  labelSalutationMr: [
    { language: 'de-DE', text: 'Herr' },
    { language: 'en-DE', text: 'Mr.' },
  ],
  labelSalutationMs: [
    { language: 'de-DE', text: 'Frau' },
    { language: 'en-DE', text: 'Mrs.' },
  ],
  labelLegendLogo: [
    { language: 'de-DE', text: 'Autarq im Auftrag von' },
    { language: 'en-DE', text: 'Autarq on behalf of' },
  ],
  labelOf: [
    { language: 'de-DE', text: 'vom' },
    { language: 'en-DE', text: 'of' },
  ],
  labelCreatedBy: [
    { language: 'de-DE', text: 'Erstellt von' },
    { language: 'en-DE', text: 'Created by' },
  ],
  labelAnd: [
    { language: 'de-DE', text: 'und' },
    { language: 'en-DE', text: 'and' },
  ],
  textSquareMeterOnRoofPane: [
    { language: 'de-DE', text: 'qm auf der Dachfläche' },
    { language: 'en-DE', text: 'sqm on the roof surface' },
  ],
  labelYieldPrognosisAnd: [
    { language: 'de-DE', text: 'Ertragsprognose und' },
    { language: 'en-DE', text: 'Yield forecast and' },
  ],
  labelElectricCoverage: [
    { language: 'de-DE', text: 'Deckungsgrad des Strombedarfs' },
    { language: 'en-DE', text: 'coverage of electricity needs' },
  ],
  labelYieldForecastHeader: [
    { language: 'de-DE', text: '1. Ertragsprognose und Deckungsgrad des Strombedarfs' },
    { language: 'en-DE', text: '1. Yield forecast and coverage of electricity needs' },
  ],
  textYieldForecastDisclaimer: [
    { language: 'de-DE', text: `Die Ertragsprognose wurde auf Basis der PVGIS (Photovoltaic Geographical Information System) Strahlungsdaten der Europäischen Kommission erstellt\n\nAbschätzung des Strombedarfs und des entsprechenden Deckungsgrads aus dem Solardach (bei Nutzung eines Batteriespeichers) in Bezug auf den von Ihnen angegebenen Stromverbrauch von {{annualPowerRequirement}} kWh.` },
    { language: 'en-DE', text: `The yield forecast was created based on the PVGIS (Photovoltaic Geographical Information System) radiation data of the European Commission\n\nEstimation of the electricity needs and the corresponding coverage rate from the solar roof (when using a battery storage) in relation to the electricity consumption of {{annualPowerRequirement}} kWh you specified.` },
  ],
  labelYieldForecastTableTitleDaily: [
    { language: 'de-DE', text: 'Erzeugung in kWh Ø pro Tag' },
    { language: 'en-DE', text: 'Generation in kWh Ø per day' },
  ],
  labelYieldForecastTableTitleMonthly: [
    { language: 'de-DE', text: 'Erzeugung in kWh pro Monat' },
    { language: 'en-DE', text: 'Generation in kWh per month' },
  ],
  labelTitleDailyNeed: [
    { language: 'de-DE', text: 'Strombedarf in kWh pro Tag' },
    { language: 'en-DE', text: 'Electricity needs in kWh per day' },
  ],
  labelAutarqy: [
    { language: 'de-DE', text: 'Autarkiegrad' },
    { language: 'en-DE', text: 'Autarky' },
  ],
  labelCoverRatio: [
    { language: 'de-DE', text: 'Deckungsgrad durch Solarstrom' },
    { language: 'en-DE', text: 'Coverage by solar power' },
  ],
  labelTableHeaderNoOfRoof: [
    { language: 'de-DE', text: 'Dach-Nummer' },
    { language: 'en-DE', text: 'Roof number' },
  ],
  labelOrientation: [
    { language: 'de-DE', text: 'Dachausrichtung' },
    { language: 'en-DE', text: 'Roof orientation' },
  ],
  labelAngle: [
    { language: 'de-DE', text: 'Dachneigung' },
    { language: 'en-DE', text: 'Roof pitch' },
  ],
  labelTileCount: [
    { language: 'de-DE', text: 'Anzahl Solarziegel' },
    { language: 'en-DE', text: 'Number of solar roof tiles' },
  ],
  labelSolarArea: [
    { language: 'de-DE', text: 'Solarfläche' },
    { language: 'en-DE', text: 'Solar surface' },
  ],
  labelPower: [
    { language: 'de-DE', text: 'Nennleistung' },
    { language: 'en-DE', text: 'Nominal output power' },
  ],
  labelAnnualYield: [
    { language: 'de-DE', text: 'Jahresertrag' },
    { language: 'en-DE', text: 'Annual yield' },
  ],
  labelAssignmentOf: [
    { language: 'de-DE', text: 'Belegung der' },
    { language: 'en-DE', text: 'Tiling of' },
  ],
  labelRoofSurfaces: [
    { language: 'de-DE', text: 'der Dachflächen' },
    { language: 'en-DE', text: 'roof surfaces' },
  ],
  labelVisualisation: [
    { language: 'de-DE', text: 'Visualisierung' },
    { language: 'en-DE', text: 'Visualisation' },
  ],
  labelAndReferences: [
    { language: 'de-DE', text: 'und Musterdächer' },
    { language: 'en-DE', text: 'and References' },
  ],
  labelProductPlanningDescription: [
    { language: 'de-DE', text: 'Planung\n▪ Dachbelegungspläne erstellen, Bedarf berechnen, Leistungsplan erstellen' },
    { language: 'en-DE', text: 'Planning\n▪ Create roof tile layout, calculate tile requirement, generate performance plan' },
  ],
  labelSolarTile: [
    { language: 'de-DE', text: 'Solardachziegel' },
    { language: 'en-DE', text: 'Solar roof tiles' },
  ],
  labelNet: [
    { language: 'de-DE', text: 'Netto' },
    { language: 'en-DE', text: 'Net' },
  ],
  labelValueAddedTax: [
    { language: 'de-DE', text: 'MwSt.' },
    { language: 'en-DE', text: 'VAT' },
  ],
  labelGross: [
    { language: 'de-DE', text: 'Brutto' },
    { language: 'en-DE', text: 'Gross' },
  ],
  labelPackageExpenses: [
    { language: 'de-DE', text: 'Paketpreis exkl. Installation' },
    { language: 'en-DE', text: 'Package rate excl. Installation' },
  ],
  labelIncludesEuro: [
    { language: 'de-DE', text: 'enthält EUR' },
    { language: 'en-DE', text: 'incl. EUR' },
  ],
  labelIncludes:  [
    { language: 'de-DE', text: 'enthält' },
    { language: 'en-DE', text: 'incl.' },
  ],
  textDisclaimerCustomerPrice: [
    { language: 'de-DE', text: 'Der Paketpreis stellt eine erste Abschätzung vor einem Ortstermin dar, und beinhaltet keine Installationskosten. Ein vollständiges Angebot (inkl. Dachsanierung bzw. Dacheindeckung auch der nicht solar genutzten Flächen) erhalten Sie von Ihrem Zimmerei- oder Dachdeckerbetrieb nach Prüfung der örtlichen Gegebenheiten.' },
    { language: 'en-DE', text: 'The package rate is an initial estimate before an on-site visit and does not include installation costs. You will receive a complete offer (incl. roof refurbishment or the tiling of the areas that are not used for solar energy) from your carpentry or roofing company after checking the local conditions.' },
  ],
  labelTitlePosition: [
    { language: 'de-DE', text: 'Pos.' },
    { language: 'en-DE', text: 'Pos.' },
  ],
  labelTitleCount: [
    { language: 'de-DE', text: 'Anzahl' },
    { language: 'en-DE', text: 'Number' },
  ],
  labelTitleNumber: [
    { language: 'de-DE', text: 'Art.-Nr.' },
    { language: 'en-DE', text: 'Art.-Nr.' },
  ],
  labelTitleDescription: [
    { language: 'de-DE', text: 'Beschreibung' },
    { language: 'en-DE', text: 'Description' },
  ],
  labelTitlePrice: [
    { language: 'de-DE', text: 'Preis' },
    { language: 'en-DE', text: 'Price' },
  ],
  labelTitleTotal: [
    { language: 'de-DE', text: 'Total' },
    { language: 'en-DE', text: 'Total' },
  ],
  labelBOM: [
    { language: 'de-DE', text: 'Stückliste' },
    { language: 'en-DE', text: 'Bill of material (BOM)' },
  ],
  labelCraftElectrical: [
    { language: 'de-DE', text: 'Gewerk Elektro' },
    { language: 'en-DE', text: 'electrical' },
  ],
  labelCraftRoof: [
    { language: 'de-DE', text: 'Gewerk Dach' },
    { language: 'en-DE', text: 'roof' },
  ],
  labelWithoutBattery: [
    { language: 'de-DE', text: 'ohne Batterie und ohne Notstrom' },
    { language: 'en-DE', text: 'without battery storage, without emergency power' },
  ],
  labelWithBattery: [
    { language: 'de-DE', text: 'mit Batterie und ohne Notstrom' },
    { language: 'en-DE', text: 'with battery storage, without emergency power' },
  ],
  labelWithEmergencyPower: [
    { language: 'de-DE', text: 'mit Batterie und mit Notstrom' },
    { language: 'en-DE', text: 'with battery storage, with emergency power' },
  ],
  textPackageInverterOnly: [
    { language: 'de-DE', text: '▪ Netzgeführter Einspeisewechselrichter eines namhaften Herstellers' },
    { language: 'en-DE', text: '▪ Grid-connected feed-in solar inverter from a renowned manufacturer' },
  ],
  textPackageInverterAndBattery: [
    { language: 'de-DE', text: '▪ Netzgeführter Hybridwechselrichter eines namhaften Herstellers\n▪ Energieflußsensor\n▪ Lithium-Eisenphosphat Batteriespeicher eines namhaften Herstellers' },
    { language: 'en-DE', text: '▪ Grid-connected hybrid solar inverter from a well-known manufacturer\n▪ Energy flow sensor\n▪ Lithium iron phosphate battery storage from a well-known manufacturer' },
  ],
  labelkWp: [
    { language: 'de-DE', text: 'kWp' },
    { language: 'en-DE', text: 'kWp' },
  ],
  labelElectroPackage: [
    { language: 'de-DE', text: 'Elektropaket' },
    { language: 'en-DE', text: 'Electrical package' },
  ],
  labelUsualMarketPrice: [
    { language: 'de-DE', text: 'Marktübliche Kosten' },
    { language: 'en-DE', text: 'Standard market costs' },
  ],
  labelDisclaimerElectro: [
    { language: 'de-DE', text: 'Die Lieferung und Montage der Elektrokomponenten erfolgt vorzugweise durch Ihren lokalen Elektropartner. Bei Fragen bzw. zur Unterstützung stehen Autarq Anwendungstechniker:innen zur Verfügung.\n\nWechselrichter und Speicher sollten im Keller oder im Technikraum installiert werden. Auf eine ausreichende Belüftung muss geachtet werden. Die genauen Anforderungen an den Aufstellungsort entnehmen Sie bitte den Datenblättern.' },
    { language: 'en-DE', text: 'The delivery and installation of the electrical components is preferably carried out by your local electrical partner. Autarq application engineers are available to answer any questions or provide support.\n\nInverters and storage tanks should be installed in the basement or in the utility room. Sufficient ventilation must be ensured. Please refer to the data sheets for the exact requirements for the installation location.' },
  ],
  labelElectroPackagePriceDescription: [
    { language: 'de-DE', text: 'Paketpreis inkl. Installation' },
    { language: 'en-DE', text: 'Package rate incl. Installation' },
  ],
  labelPackagePriceDisclaimer: [
    { language: 'de-DE', text: 'Ein detailliertes Angebot erhalten Sie von Ihrem Elektropartner-Betrieb nach Prüfung der örtlichen Gegebenheiten.' },
    { language: 'en-DE', text: 'You will receive a detailed offer from your electrical partner after checking the local conditions.' },
  ],
  labelFrom: [
    { language: 'de-DE', text: 'vom' },
    { language: 'en-DE', text: 'from' }
  ],
  labelAverage: [
    { language: 'de-DE', text: 'Durchschnitt' },
    { language: 'en-DE', text: 'Average' }
  ],
  siteSchemePicture: [
    { language: 'de-DE', text: siteSchemePicturedeDE},
    { language: 'en-DE', text: siteSchemePictureenDE}
  ]
};

const translate = (value, language) => {
  if (Array.isArray(dictionary[value])) {
    const translateObject = dictionary[value].filter(
      (template) => template.language === language,
    )[0];
    if (translateObject instanceof Object) {
      // eslint-disable-next-line no-prototype-builtins
      if (translateObject.hasOwnProperty('text')) {
        return translateObject.text;
      }
    }
  }
  return '';
};

export { translate };