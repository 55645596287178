//import jsPDF from "jspdf";
import { autarqLogoGreyBG } from '../../Assets/autarqLogo';
import { getTemplate } from "../../MiscHelperFunctions";

export default function pdfAddHeader(doc, agentUserInput, upperHeader, lowerHeader) {
    const tenantName = agentUserInput.get('tenant');

    doc.setTextColor(16, 25, 32);
    doc.setFillColor(235, 236, 230);
    doc.rect(0, 0, 210, 45, 'F');

    doc.addImage(autarqLogoGreyBG, 'jpeg', 20.8, 8.82, 31.5, 7.1);

    doc.setTextColor(16, 25, 32);
    doc.setFontSize(18);
    doc.setFont('TT_Firs_Neue', 'bold');
    doc.text(upperHeader, 20.8, 24.7, { baseline: 'top' });
  
    doc.setTextColor(104, 109, 111);
    doc.text(lowerHeader, 20.8, 32.1, { baseline: 'top' });

    if(agentUserInput.get(tenantName)["logoOnAdditionalPages"]) {
      const logoMetadata = agentUserInput.get(tenantName)["logoMetadata"];
      doc.addImage(getTemplate(agentUserInput.get(tenantName)["logo"], 'logo', 'bg', 'grey'), 'jpeg', ( 210 - ( 20.8 + 31.5 ) ), 8.82 + 0.5 * (7.1 - 31.5*logoMetadata["height"]/logoMetadata["width"]), 31.5, 31.5*logoMetadata["height"]/logoMetadata["width"]);
    }
};