import React, { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl';
import { Select, InputLabel, MenuItem } from '@mui/material';
import { translate } from '../../Helper/Dictionary';

export default function BrickSelector(props: any) {
    const [selectedBrick, setSelectedBrick] = React.useState({id: "", name:"Ziegel auswählen", code: ""});

    useEffect(() => {
		getSelectedBrick (props);
    }, [])

	function getSelectedBrick (props : any) {
		if(props.handleDealData.has("details")) {
			if (props.handleDealData.get("details")["fcba96eb3fef7cb89e96e52ec2222e1bdfae919c"]){}
			const brickNum = props.handleDealData.get("details")["fcba96eb3fef7cb89e96e52ec2222e1bdfae919c"];

			const bricks = props.availableProducts.bricks;
			let foundbrick;
			for (const brick of bricks) {
				if (brick.hasOwnProperty("id") && brick.id === brickNum) {
					foundbrick = brick;
					break; 
				}
			}
			if (foundbrick) updateBrickForTechnicalUserInput(foundbrick.id, true);

		}
	}

    const updateTechnicalUserInput = (k: any, v: any) => {
        props.setTechnicalUserInput(new Map(props.technicalUserInput.set(k,v)));
    }

    const updateTechnicalUserInputFromBrickSelector = (e: any): void => {
        updateBrickForTechnicalUserInput(e.target.value, true);
    }

    const updateBrickForTechnicalUserInput = (value: any, updateSelector: boolean): void => {
        for(var brick of props.availableProducts.bricks) {			
            console.log("Compare value: " + value + " with brick.code: " + brick.id);
            if (value == brick.id) {
                var newBrick = new Map<string, any>();
                newBrick.set("id", brick.id);
                newBrick.set("name", brick.name);
                newBrick.set("code", brick.code);
                newBrick.set("description", brick.description);
                newBrick.set("peakPower", brick.peakPower);
                newBrick.set("countPerSquareMeter", brick.countPerSquareMeter);
                newBrick.set("current", brick.current);
                newBrick.set("voltage", brick.voltage);
                newBrick.set("compatibleTo", brick.compatibleTo);
                newBrick.set("cablingSystem", brick.cablingSystem);
                newBrick.set("prices", brick.prices);
                newBrick.set("1c99d55f805a7fc510b7cb1f85b6ff4cab781ee6", brick["1c99d55f805a7fc510b7cb1f85b6ff4cab781ee6"]);

                console.log("Update Brick in State: " + newBrick.get("id"));
                updateTechnicalUserInput("brick", newBrick);

                if (updateSelector) {
                    setSelectedBrick( {id: brick.id, name: brick.name, code: brick.code} );
                }
            }
        }
    };

    return (
        <FormControl sx={{ my: 2 }} fullWidth>
            <InputLabel id="brickSelectorInputLabel">{translate('labelSolarTile', props.guiLanguage)}</InputLabel>
            <Select
                labelId="brickSelectorLabel"
                id="brickSelector"
                value={selectedBrick.id}
                label="Ziegel"
                onChange={updateTechnicalUserInputFromBrickSelector}
            >
                {
                props.availableProducts.bricks.filter((brick: { availableFor: any; }) => brick.availableFor == props.agentUserInput.get("tenant")).map((brick: any) => (
                <MenuItem value={brick.id} key={brick.id}>
                  {brick.name}
                </MenuItem>
                ))
                }
            </Select>
        </FormControl>
    );
  }