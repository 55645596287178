const getPriceForProduct = (product, tenant, typeOfCustomer, salesCountry) => {

		
	var taxJson;
	try {
		var jsonString = product['3c3357a6c9f22b8bf8afbd9bb4ff91fdbb050749'];
		const validJsonString = jsonString.replace(/(\w+):/g, '"$1":');
		taxJson = JSON.parse (validJsonString);
	}
	catch {
		console.log ('Kein Steuersatz gefunden');
	}
	var tax;
	let currency = "EUR";
	if (salesCountry == 'CH') currency = "CHF";

	try {

		tax = 1.0 + (parseFloat (taxJson[salesCountry]) / 100);
	}
	catch {
		console.log (taxJson);
		console.log ('Es wurde Kein Tax gefunden: '+product.id);
	}
	console.log (product.id);
	// if there are price variations, then dig deeper, if not, just take the first price
    if(product.hasOwnProperty('product_variations') == true) {
        if(product.product_variations.filter(variation => variation.name == typeOfCustomer)[0] != undefined) {
			if (product.product_variations.filter(variation => variation.name == typeOfCustomer)[0].prices.filter(prices => prices.currency == currency)[0] != undefined) {
				return product.product_variations.filter(variation => variation.name == typeOfCustomer)[0].prices[0].price;
			}
        }
    }
}

const getCurrency = (tenant, typeOfCustomer, salesCountry) => {
    if(salesCountry == "CH") {
        return 'CHF';
    }
    return 'EUR';
}

const getValueAddedTax = (salesCountry, typeOfCustomer) => {
	let json = {"DE" : 0.19, "CH" : 0.21, "AT" : 0.20}
	let mwst = json[salesCountry];
	if (salesCountry == "DE" && typeOfCustomer == "consumer") {
		mwst = 0.0;
	}
    return mwst;
}


export { getPriceForProduct, getCurrency, getValueAddedTax };