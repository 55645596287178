import { addToBOM, getTemplate } from './MiscHelperFunctions';
import { getProductById } from './CalculateHelperFunctions.js';
import { getProductDescriptionForLanguage } from './ProductI18nHelperFunctions.js';
import { getPriceForProduct, getCurrency, getValueAddedTax } from './PricelistMap.js';
import { translate } from '../../../Helper/Dictionary';
import { ceilToNext500 } from './PricesHelperFunctions';

const generateBOM = (agentUserInput, availableProducts, processedTechnicalInformation, selectedDeal, technicalUserInput, tenantsAvailable, bomVariables) => {
    const bomProperties = new Object();
    bomProperties["sum"] = 0;

    const tenantName = bomVariables.tenantName;
    const typeOfCustomer = bomVariables.typeOfCustomer;
    const salesCountry = bomVariables.salesCountry;
    const inverter45 = bomVariables.inverter45;
    const inverter90 = bomVariables.inverter90;
  
    var bom = new Array();
    bom.push(addToBOM((bom.length + 1).toString(), '1', getProductById(availableProducts.miscProducts, 312).code, getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 312), agentUserInput.get("offerLanguage")), getPriceForProduct(getProductById(availableProducts.miscProducts, 312), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    bom.push(addToBOM((bom.length + 1).toString(),
    processedTechnicalInformation.get("siteBrickCount").toString(),
    technicalUserInput.get("brick").get("code"), translate("labelSolarTile", agentUserInput.get("offerLanguage")) + " " + technicalUserInput.get("brick").get("name").toString() + "\n" + getProductDescriptionForLanguage(technicalUserInput.get("brick"), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.bricks, technicalUserInput.get('brick').get('id')), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    if (agentUserInput.get('tenant') === 'creaton') {
      bom.push(addToBOM((bom.length + 1).toString(), '1',
      getProductById(availableProducts.miscProducts, 313).code,
      getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 313), agentUserInput.get("offerLanguage")),
      getPriceForProduct(getProductById(availableProducts.miscProducts, 313), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    }
    if (agentUserInput.get('tenant') === 'creaton') {
      bom.push(addToBOM((bom.length + 1).toString(), '1',
      getProductById(availableProducts.miscProducts, 315).code,
      getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 315), agentUserInput.get("offerLanguage")),
      getPriceForProduct(getProductById(availableProducts.miscProducts, 315), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    }
    if (selectedDeal.get('country') === 'Schweiz') {
      bom.push(addToBOM((bom.length + 1).toString(), '1',
      getProductById(availableProducts.miscProducts, 314).code,
      getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 314), agentUserInput.get("offerLanguage")),
      getPriceForProduct(getProductById(availableProducts.miscProducts, 314), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    }
    bom.push(addToBOM((bom.length + 1).toString(), Math.ceil(processedTechnicalInformation.get("siteBrickCount") * 0.005).toString(),
    technicalUserInput.get("brick").get("cablingSystem") == 'jacobiwalther' ? getProductById(availableProducts.miscProducts, 247).code : getProductById(availableProducts.miscProducts, 256).code,
    technicalUserInput.get("brick").get("cablingSystem") == 'jacobiwalther' ? getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 247), agentUserInput.get("offerLanguage")) : getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 256), agentUserInput.get("offerLanguage")),
    technicalUserInput.get("brick").get("cablingSystem") == 'jacobiwalther' ? getPriceForProduct(getProductById(availableProducts.miscProducts, 247), tenantName, typeOfCustomer, bomProperties, salesCountry) : getPriceForProduct(getProductById(availableProducts.miscProducts, 256), tenantName, typeOfCustomer, salesCountry),
    technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    bom.push(addToBOM((bom.length + 1).toString(), Math.ceil(processedTechnicalInformation.get("siteBrickCount") * 0.0025).toString(),
    getProductById(availableProducts.miscProducts, 245).code,
    getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 245), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.miscProducts, 245), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    bom.push(addToBOM((bom.length + 1).toString(), Math.ceil(processedTechnicalInformation.get("siteBrickCount") * 0.0025).toString(),
    getProductById(availableProducts.miscProducts, 246).code,
    getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 246), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.miscProducts, 246), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    bom.push(addToBOM((bom.length + 1).toString(), (Math.ceil(processedTechnicalInformation.get("siteBrickCount") * 0.0025) + Math.ceil(processedTechnicalInformation.get("siteBrickCount") * 0.0025)).toString(),
    getProductById(availableProducts.miscProducts, 248).code,
    getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 248), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.miscProducts, 248), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    bom.push(addToBOM((bom.length + 1).toString(), Math.ceil(processedTechnicalInformation.get("siteBrickCount") * 0.0015).toString(),
    getProductById(availableProducts.miscProducts, 249).code,
    getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 249), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.miscProducts, 249), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    bom.push(addToBOM((bom.length + 1).toString(), Math.ceil(processedTechnicalInformation.get("siteBrickCount") * 0.0015).toString(),
    getProductById(availableProducts.miscProducts, 250).code,
    getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 250), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.miscProducts, 250), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    bom.push(addToBOM((bom.length + 1).toString(), Math.ceil(processedTechnicalInformation.get("siteBrickCount") * 0.002).toString(),
    getProductById(availableProducts.miscProducts, 296).code,
    getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 296), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.miscProducts, 296), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    bom.push(addToBOM((bom.length + 1).toString(), Math.ceil(processedTechnicalInformation.get("siteBrickCount") * 0.00064).toString(),
    getProductById(availableProducts.miscProducts, 297).code,
    getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 297), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.miscProducts, 297), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    bom.push(addToBOM((bom.length + 1).toString(), Math.ceil(processedTechnicalInformation.get("siteBrickCount") * 0.00064).toString(),
    getProductById(availableProducts.miscProducts, 298).code,
    getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 298), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.miscProducts, 298), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    bom.push(addToBOM((bom.length + 1).toString(), Math.ceil(processedTechnicalInformation.get("siteBrickCount") * 0.002).toString(),
    getProductById(availableProducts.miscProducts, 299).code,
    getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 299), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.miscProducts, 299), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    bom.push(addToBOM((bom.length + 1).toString(), Math.ceil(processedTechnicalInformation.get("siteBrickCount") * 0.002).toString(),
    getProductById(availableProducts.miscProducts, 310).code,
    getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 310), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.miscProducts, 310), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    bom.push(addToBOM((bom.length + 1).toString(), Math.ceil(processedTechnicalInformation.get("siteBrickCount") * 0.002).toString(),
    getProductById(availableProducts.miscProducts, 300).code,
    getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 300), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.miscProducts, 300), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    bom.push(addToBOM((bom.length + 1).toString(), Math.ceil(processedTechnicalInformation.get("siteBrickCount") * 0.0005).toString(),
    getProductById(availableProducts.miscProducts, 301).code,
    getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 301), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.miscProducts, 301), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    if (inverter90 > 0) {
      bom.push(addToBOM((bom.length + 1).toString(), inverter90.toString(),
      getProductById(availableProducts.miscProducts, 251).code,
      getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 251), agentUserInput.get("offerLanguage")),
      getPriceForProduct(getProductById(availableProducts.miscProducts, 251), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    }
    if (inverter45 > 0) {
      bom.push(addToBOM((bom.length + 1).toString(), inverter45.toString(),
      getProductById(availableProducts.miscProducts, 252).code,
      getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 252), agentUserInput.get("offerLanguage")),
      getPriceForProduct(getProductById(availableProducts.miscProducts, 252), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    }
    bom.push(addToBOM((bom.length + 1).toString(), '1',
    getProductById(availableProducts.miscProducts, 271).code,
    getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 271), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.miscProducts, 271), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    bom.push(addToBOM((bom.length + 1).toString(), '1',
    getProductById(availableProducts.miscProducts, 306).code,
    getProductDescriptionForLanguage(getProductById(availableProducts.miscProducts, 306), agentUserInput.get("offerLanguage")),
    getPriceForProduct(getProductById(availableProducts.miscProducts, 306), tenantName, typeOfCustomer, salesCountry), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, salesCountry, bomProperties));
    if(technicalUserInput.get("bomProfile") == 1 && typeOfCustomer != "consumer") {
      bom.push(["","","","",translate("labelNet", agentUserInput.get("offerLanguage")), getCurrency(tenantName, typeOfCustomer, salesCountry) + ' ' + bomProperties["sum"].toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })]);
      bom.push(["","","","",translate("labelValueAddedTax", agentUserInput.get("offerLanguage")), getCurrency(tenantName, typeOfCustomer, salesCountry) + ' ' + (bomProperties["sum"]*getValueAddedTax(salesCountry, typeOfCustomer)).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })]);
      bom.push(["","","","",translate("labelGross", agentUserInput.get("offerLanguage")), getCurrency(tenantName, typeOfCustomer, salesCountry) + ' ' + (bomProperties["sum"]*(1 + getValueAddedTax(salesCountry, typeOfCustomer))).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })]);
    } else if (technicalUserInput.get("bomProfile") == 1 && typeOfCustomer == "consumer") {
	  bom.push([translate("labelPackageExpenses", agentUserInput.get("offerLanguage")),"","", getCurrency(tenantName, typeOfCustomer, salesCountry) + ' ' + ceilToNext500(bomProperties["sum"]*(1 + getValueAddedTax(salesCountry, typeOfCustomer))).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ` (${translate('labelIncludesEuro', agentUserInput.get("offerLanguage"))} ` + (ceilToNext500(bomProperties["sum"]*(1 + getValueAddedTax(salesCountry, typeOfCustomer))) * (1 - 1/(1 + getValueAddedTax(salesCountry, typeOfCustomer)))).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' MwSt.)\n' + translate('textDisclaimerCustomerPrice', agentUserInput.get("offerLanguage"))]);
    }
  
    return bom;
  };

  export { generateBOM };