var Mustache = require('mustache');

require('../TT_Firs_Neue_400');
require('../TT_Firs_Neue_500');

import { translate } from '../../../../Helper/Dictionary';
import { getTemplate } from '../MiscHelperFunctions';
import { getFriendlyNameForAspect } from '../CalculateHelperFunctions.js';

import { autarqLogoGreyBG } from '../Assets/autarqLogo';
import pdfAddDraftWatermark from './Helper/PDFAddDraftWatermark';

const generateRoofText = (agentUserInput, processedTechnicalInformation, technicalUserInput) => {
    var roofText = "";
    const roofCount = processedTechnicalInformation.get("roofs").length;
    for(var i = 0; i < processedTechnicalInformation.get("roofs").length; i++) {
      if (roofCount > 1 && (i + 1) == roofCount) {
        roofText += `${translate("labelAnd", agentUserInput.get("offerLanguage"))} `;
      }
      roofText += parseFloat(technicalUserInput.get("roofs")[i].get("area")).toFixed(0).toString() + " " + translate("textSquareMeterOnRoofPane", agentUserInput.get("offerLanguage")) + " " + getFriendlyNameForAspect(technicalUserInput.get("roofs")[i].get("orientation"), agentUserInput.get("offerLanguage"));
      if (roofCount > 1) {
        if ((i + 1) == (roofCount - 1)) {
          roofText += " "
        } else if ((i + 1) == (roofCount)) {
          roofText += ")."
        }  else {
          roofText += ", "
        }
      } else {
        roofText += ")."
      }
    }
    return roofText;
};

function addRecipientAddress(doc, agentUserInput) {
  doc.setTextColor(16, 25, 32);
  doc.setFontSize(8);

  doc.text(
    `${agentUserInput.get("creatorCompany") || ""}\n${(agentUserInput.get("creatorSalutation")) ? agentUserInput.get("creatorSalutation") + " " : ""}${agentUserInput.get("creatorForename") || ""} ${agentUserInput.get("creatorLastname") || ""}\n${agentUserInput.get("creatorStreet") || ""} ${agentUserInput.get("creatorStreetNumber") || ""}\n${agentUserInput.get("creatorZip") || ""} ${agentUserInput.get("creatorCity") || ""}\n${agentUserInput.get("creatorCountry") || ""}`,
    20.8,
    68.4
  );
};

export default function addCoverLetterSection(doc, agentUserInput, processedTechnicalInformation, selectedDeal, technicalUserInput, tenantsAvailable, draft) {
  const tenantName = agentUserInput.get('tenant');
  const date = new Date(selectedDeal.get("date"));

  doc.addPage();
  doc.setTextColor(16, 25, 32);
  doc.setFillColor(235, 236, 230);
  doc.rect(0, 0, 210, 88, 'F');

  doc.addImage(autarqLogoGreyBG, 'jpeg', 20.8, 8.82, 31.5, 7.1);

  if(agentUserInput.get(tenantName)["logoOnAdditionalPages"]) {
    const logoMetadata = agentUserInput.get(tenantName)["logoMetadata"];
    doc.addImage(getTemplate(agentUserInput.get(tenantName)["logo"], 'logo', 'bg', 'grey'), 'jpeg', ( 210 - ( 20.8 + 31.5 ) ), 8.82 + 0.5 * (7.1 - 31.5*logoMetadata["height"]/logoMetadata["width"]), 31.5, 31.5*logoMetadata["height"]/logoMetadata["width"]);
  }

  doc.setTextColor(16, 25, 32);
  doc.setFontSize(18);
  doc.setFont('TT_Firs_Neue', 'bold');
  doc.text(getTemplate(agentUserInput.get(tenantName).dictionary, "title", 'language', agentUserInput.get("offerLanguage")), 20.8, 24.7, { baseline: 'top' });
  
  doc.setTextColor(104, 109, 111);
  doc.text(getTemplate(agentUserInput.get(tenantName).dictionary, "subTitle", 'language', agentUserInput.get("offerLanguage")), 20.8, 32.108, { baseline: 'top' }, 20.8, 32.1, { baseline: 'top' });

  doc.setFont('TT_Firs_Neue', 'normal');
  doc.setFontSize(6);
  doc.setTextColor(126, 131, 131);
  doc.text(date.toLocaleDateString('de-DE'), 20.8, 54, { baseline: 'top' });

  addRecipientAddress(doc, agentUserInput);

  doc.setFont('TT_Firs_Neue', 'bold');
  doc.setFontSize(10);
  doc.text(`${getTemplate(agentUserInput.get(tenantName).dictionary, "title", 'language', agentUserInput.get("offerLanguage"))} ${selectedDeal.get("title")} ${translate("labelFrom", agentUserInput.get("offerLanguage"))} ${date.toLocaleDateString('DE-de')}`, 20.8, 97);
  doc.text(`${translate("labelCreatedBy", agentUserInput.get("offerLanguage"))}: ${tenantsAvailable.get('tenants').displayName}`, 20.8, 101);

  const letterVariables = {
    agentName: agentUserInput.get('creatorForename') + ' ' + agentUserInput.get('creatorLastname'),
    dealDate: date.toLocaleDateString('de-DE'),
    siteBrickCount: processedTechnicalInformation.get("siteBrickCount").toLocaleString('de-DE'),
    brickName: technicalUserInput.get("brick").get("name").toString(),
    peakPower: technicalUserInput.get("brick").get("peakPower").toString(),
    siteTotalPowerRating: processedTechnicalInformation.get("siteTotalPowerRating").toLocaleString('de-DE'),
    roofText: generateRoofText(agentUserInput, processedTechnicalInformation, technicalUserInput),
    siteTotalAnnualOutput: processedTechnicalInformation.get("siteTotalAnnualOutput").toLocaleString('de-DE'),
    batteryCapacity: Math.ceil(processedTechnicalInformation.get("siteTotalAnnualOutput")/1000).toLocaleString('de-DE')
  };

  doc.setFont('TT_Firs_Neue', 'normal');
  doc.setFontSize(10);
  doc.text(
    Mustache.render(getTemplate(agentUserInput.get(tenantName).dictionary, "letterTemplate", 'language', agentUserInput.get("offerLanguage")), letterVariables),
    20.8,
    110,
    {maxWidth: 168.4, align: "left"}
  );
  if (draft === true) {
    pdfAddDraftWatermark(doc, agentUserInput);
  }
};