import { getCurrency } from './PricelistMap.js';

export const addToBOM = (index, quantity, shortname, description, price, bomProfile, tenant, typeOfCustomer, salesCountry, bomProperties) => {
    var entry = new Array();
    entry.push(index);
    entry.push(quantity);
    entry.push(shortname);
    entry.push(description);
    if(bomProfile == 1 && typeOfCustomer != "consumer") {
      entry.push(getCurrency(tenant, typeOfCustomer, salesCountry) + ' ' + price.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
      entry.push(getCurrency(tenant, typeOfCustomer, salesCountry) + ' ' + (quantity * price).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    }
    bomProperties["sum"] += quantity * (price);
    return entry;
}

export const getTemplate = (dictionary, key, subkey, subvalue) => {
  if (Array.isArray(dictionary[key])) {
    const translateObject = dictionary[key].filter(
      (template) => template[subkey] === subvalue,
    )[0];
    if (translateObject instanceof Object) {
      // eslint-disable-next-line no-prototype-builtins
      if (translateObject.hasOwnProperty('text')) {
        return translateObject.text;
      }
    }
  }
  return '';
};