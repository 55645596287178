import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MapsPane from './DetailsPaneComponents/MapsPane';
import CustomerCard from './DetailsPaneComponents/CustomerCard';
import AddRoofPane from './DetailsPaneComponents/AddRoofPane';
import BrickSelector from './DetailsPaneComponents/BrickSelector';
import BOMSelector from './DetailsPaneComponents/BOMSelector';
import ElectroComponentsSelector from './DetailsPaneComponents/ElectroComponentsSelector';
import { translate } from '../Helper/Dictionary';

export default function DetailsPane(props: any) {
  const [technicalUserInputProvided, setTechnicalUserInputProvided] = useState(false);

  useEffect(() => {
	if (props.technicalUserInput.get("coordinates")) {
		if(technicalUserInputProvided && props.technicalUserInput.has("brick") && props.technicalUserInput.has("roofs") && (props.technicalUserInput.get("coordinates")["lat"] != 0 || props.technicalUserInput.get("coordinates")["lng"] != 0)) {
			props.setCanGoOn(true);
		} else {
			props.setCanGoOn(false);
		}	
	}
	else {
		props.setCanGoOn(false);
	}
  });

  useEffect(() => {
	if (props.handleDealData.has("details")) {		
		if (props.handleDealData.get("details")["fddb53881a13870fc421edb9d38afdd4c937bbc4"]) {
			checkForSufficientInformationsProvided("annualPowerRequirement", props.handleDealData.get("details")["fddb53881a13870fc421edb9d38afdd4c937bbc4"]);
			props.setTechnicalUserInput(new Map(props.technicalUserInput.set("annualPowerRequirement", props.handleDealData.get("details")["fddb53881a13870fc421edb9d38afdd4c937bbc4"])));
		}
		else {
			checkForSufficientInformationsProvided("", "");
		}
	}
	else {
		checkForSufficientInformationsProvided ("","");
	}
	checkForSufficientInformationsProvided("annualPowerRequirement", "0");


  }, []);

  const getPrepopulateValue = (fieldValue: string): string => {
		if (props.handleDealData.has("details")) {		
			if (props.handleDealData.get("details")["fddb53881a13870fc421edb9d38afdd4c937bbc4"]) {
					return props.handleDealData.get("details")["fddb53881a13870fc421edb9d38afdd4c937bbc4"].toString();
				}
		}
		return "";
	};


  const technicalUserInputFields:string[] = ["annualPowerRequirement", "bomProfile"];

  const checkForSufficientInformationsProvided = (fieldChanged: string, changedValue: string):void => {
    var checksPassed = true;

    for (const field of technicalUserInputFields) {
      var value = "";
      if(field == fieldChanged) {
        value = changedValue;
      } else {
        value = props.technicalUserInput.get(field);
      }
      if (value == "" || value == null) {
        checksPassed = false;
        break;
      }
      if (field == "annualPowerRequirement") {
        if (isNaN(value as any) || parseInt(value) < 0 || parseInt(value) > 200000) {
          checksPassed = false;
          break;
        }
      }
    }
    console.log("Are the checks passed? " + checksPassed);
    if (checksPassed) {
      setTechnicalUserInputProvided(true);
    } else {
      setTechnicalUserInputProvided(false);
    }
  };

  const updateTechnicalUserInputFromTextField = (textFieldValue: any): void => {
    checkForSufficientInformationsProvided(textFieldValue.target.name, textFieldValue.target.value);
    props.setTechnicalUserInput(new Map(props.technicalUserInput.set(textFieldValue.target.name, textFieldValue.target.value)));
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {translate('labelEnterTechnicalInput', props.guiLanguage)}
      </Typography>
      <MapsPane selectedDeal = {props.selectedDeal} technicalUserInput = {props.technicalUserInput} setTechnicalUserInput = {props.setTechnicalUserInput} guiLanguage = {props.guiLanguage}/>
      <BrickSelector  agentUserInput = {props.agentUserInput} technicalUserInput = {props.technicalUserInput} setTechnicalUserInput = {props.setTechnicalUserInput} availableProducts = {props.availableProducts} setAvailableProducts = {props.setAvailableProducts} guiLanguage = {props.guiLanguage} handleDealData = {props.handleDealData}/>
      <TextField name="annualPowerRequirement" id="outlined-basic" label={translate('labelAnnualPowerDemand', props.guiLanguage)} variant="outlined" defaultValue={getPrepopulateValue("annualPowerRequirement")} onChange={updateTechnicalUserInputFromTextField} required/>
      <BOMSelector technicalUserInput = {props.technicalUserInput} setTechnicalUserInput = {props.setTechnicalUserInput} guiLanguage = {props.guiLanguage} tenantsAvailable={props.tenantsAvailable}/>
      <ElectroComponentsSelector technicalUserInput = {props.technicalUserInput} setTechnicalUserInput = {props.setTechnicalUserInput} guiLanguage = {props.guiLanguage}/>
      <AddRoofPane technicalUserInput = {props.technicalUserInput} setTechnicalUserInput = {props.setTechnicalUserInput} guiLanguage = {props.guiLanguage} handleDealData = {props.handleDealData}/>
    </React.Fragment>
  );
}
function value(key: any, arg1: string, value: any, arg3: any) {
  throw new Error('Function not implemented.');
}

