require('../TT_Firs_Neue_400');
require('../TT_Firs_Neue_500');

import { translate } from '../../../../Helper/Dictionary';

import { autarqLogoSquared } from '../Assets/autarqLogo';
import { dgnb, esmc, fundedByEU } from '../Assets/FooterMedia';
import pdfAddDraftWatermark from './Helper/PDFAddDraftWatermark';

export default function addOutroSection(doc, agentUserInput, draft) {
  doc.addPage();

  doc.addImage(autarqLogoSquared, 'jpeg', 82.2353, 128.2673, 45.882, 37.2571);

  doc.setFontSize(5);
  doc.setFont('TT_Firs_Neue', 'normal');
  doc.setTextColor(16, 25, 32);
  doc.text(
    translate('labelDisclaimerUpperText', agentUserInput.get("offerLanguage")),
    189.1765,
    262.0796,
    {
      baseline: 'top',
      align: 'right',
      lineHeightFactor: 1.35,
    },
  );

  const dgnbRatio = 1442 / 438;
  const esmcRatio = 776 / 219;
  const fundedByEURatio = 909/922;
  const height = 6;
  const margin = 3;

  doc.addImage(dgnb, 'jpeg', 20.8, 266.0796, height * dgnbRatio, height);
  doc.addImage(esmc, 'jpeg', 20.8 + 1 * margin + height * dgnbRatio, 266.0796, height * esmcRatio ,height);
  doc.addImage(fundedByEU, 'jpeg', 20.8 + 1 * margin + height * dgnbRatio + 1 * margin + height * esmcRatio, 266.0796, height * fundedByEURatio ,height);

  doc.setDrawColor(16, 25, 32);
  doc.setFillColor(16, 25, 32);
  doc.line(20.8235, 275.8361, 188.8235, 275.8361);

  doc.setFont('TT_Firs_Neue', 'normal');
  doc.text(
    translate('labelDisclaimerLowerText', agentUserInput.get("offerLanguage")),
    189.1765,
    282.538,
    {
      baseline: 'top',
      align: 'right',
      lineHeightFactor: 1.35,
    },
  );

  if (draft === true) {
    pdfAddDraftWatermark(doc, agentUserInput);
  }
}