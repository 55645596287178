import React, { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl';
import { Select, InputLabel, MenuItem } from '@mui/material';
import { translate } from '../../Helper/Dictionary';

export default function LocationServiceSelector(props: any) {
    const updateLocationServiceFromSelector = (e: any): void => {
        //updateConsumeProfileForTechnicalUserInput(e.target.value, true);
        updateTechnicalUserInput("locationService", e.target.value);
        props.setLocationService(e.target.value);
    }

    const updateTechnicalUserInput = (k: any, v: any) => {
        props.setTechnicalUserInput(new Map(props.technicalUserInput.set(k,v)));
    }

    return (
        <FormControl sx={{ my: 2 }} fullWidth>
            <InputLabel id="brickSelectorInputLabel">{translate('labelAddressDetermination', props.guiLanguage)}</InputLabel>
            <Select
                labelId="brickSelectorLabel"
                id="brickSelector"
                value={props.locationService}
                label={translate('labelAddressDetermination', props.guiLanguage)}
                onChange={updateLocationServiceFromSelector}
            >
                {
                props.selectedDeal.get("availableLocationServices").map((locationService: any) => (
                <MenuItem value={locationService.value} key={locationService.value}>
                  {translate(locationService.name, props.guiLanguage)}
                </MenuItem>
                ))
                }
            </Select>
        </FormControl>
    );
}