import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { translate } from "../Helper/Dictionary";
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

import { useMsal } from "@azure/msal-react";
import { queryMiddleware } from "../Helper/queryMiddleware";
import { loginRequest } from "../authConfig";

import generatePDF from './ReviewPDFPaneFolder/GeneratePDF';
import { getTemplate } from './ReviewPDFPaneFolder/Resources/MiscHelperFunctions';

export default function CompleteSurveyPane(props: any) {
	const [query, setQuery] = React.useState('idle');
	const [offer, setOffer] = React.useState({});

	const { instance, accounts } = useMsal();

	useEffect(() => {
		const doc = generatePDF(
			props.selectedDeal,
			props.availableProducts,
			props.technicalUserInput,
			props.processedTechnicalInformation,
			props.agentUserInput,
			props.tenantsAvailable,
			false
		);
		setOffer({ ...doc });
		uploadPDF(doc);
	}, []);

	const handleQueryResponse = (data: any, doc: any) => {
		if (!(data.success === true || data.success === 'true')) {
			setQuery('error');
			return;
		}
		setQuery('success');
		let pdf = doc as any;
		if (pdf !== undefined) {
			const date = new Date(Date.now());
			doc.save(`${getTemplate(props.agentUserInput.get(props.agentUserInput.get('tenant')).dictionary, "title", 'language', props.agentUserInput.get("offerLanguage"))}_${props.selectedDeal.get("title")}_${props.selectedDeal.get("id")}_${props.agentUserInput.get('tenant')}_${props.agentUserInput.get("typeOfCustomer")}_${date.toISOString().split('T')[0]}.pdf`);
		}
	};

	const uploadPDF = (doc: any) => {
		const request = {
			...loginRequest,
			account: accounts[0]
		};

		let bricks = 0;
		let roofNumber = 0;
		for (var roof of props.technicalUserInput.get("roofs")) {
			const brickCount = parseFloat(props.technicalUserInput.get("brick").get("countPerSquareMeter")) * parseFloat(props.technicalUserInput.get("roofs")[roofNumber].get("area"));
			bricks += brickCount;
			roofNumber++;
		}

		console.log(bricks);

		setQuery('progress');

		const date = new Date(Date.now());

		const url = `/api/v1/offer`;
		const brickType = props.technicalUserInput.get("brick").get("name");
		const annualPowerRequirement = props.technicalUserInput.get ("annualPowerRequirement");
		const tileTypeNum = props.technicalUserInput.get("brick").get("id");

		const roofsMap = props.technicalUserInput.get("roofs");
		const jsonArray: any[] = [];
		roofsMap.forEach((roofMap: any) => {
			const jsonObject: any = {};
			roofMap.forEach((value: any, key: any) => {
				jsonObject[key] = value;
			});
			jsonArray.push(jsonObject);
		});

		const body = {
			offer: btoa(doc.output()),
			dealId: props.selectedDeal.get("id"),
			tileCount: bricks,
			tileType: brickType,
			annualPower: annualPowerRequirement,
			tileTypeNum: tileTypeNum,
			filename: `${getTemplate(props.agentUserInput.get(props.agentUserInput.get('tenant')).dictionary, "title", 'language', props.agentUserInput.get("offerLanguage"))}_${props.selectedDeal.get("title")}_${props.selectedDeal.get("id")}_${props.agentUserInput.get('tenant')}_${props.agentUserInput.get("typeOfCustomer")}_${date.toISOString().split('T')[0]}`,
			roofInfos: jsonArray,

		};

		instance.acquireTokenSilent(request).then((response) => {
			queryMiddleware(url, body, "POST", response.accessToken)
				.then((data) => handleQueryResponse(data, doc));
		}).catch((e) => {
			instance.acquireTokenPopup(request).then((response) => {
				queryMiddleware(url, body, "POST", response.accessToken)
					.then((data) => handleQueryResponse(data, doc));
			});
		});
	};

	function Confirmation(props: any) {
		if (query === 'success') {
			return (
				<React.Fragment>
					<Typography variant="h5" gutterBottom>
						{translate('labelFinish', props.guiLanguage)}
					</Typography>
					<Typography variant="subtitle1">
						{translate('labelFinishText', props.guiLanguage)}
					</Typography>
				</React.Fragment>
			);
		} else if (query === 'progress') {
			return (
				<React.Fragment>
					<Fade
						in={query === 'progress'}
						style={{
							transitionDelay: query === 'progress' ? '200ms' : '0ms',
						}}
						unmountOnExit
					>
						<CircularProgress size="1.5rem" />
					</Fade>
				</React.Fragment>
			);
		} else if (query === 'error') {
			return (
				<React.Fragment>
					<Alert
						severity="error"
						sx={{ mb: 2 }}
					>
						{translate('labelErrorOnUpload', props.guiLanguage)}
					</Alert>
					<Button onClick={(e) => uploadPDF(props.offer)} sx={{}} variant="contained" color="error">
						{translate('buttonRetry', props.guiLanguage)}
					</Button>
				</React.Fragment>
			);
		}
		return null;
	}

	return (
		<Confirmation offer={offer} guiLanguage={props.guiLanguage} />
	);
}