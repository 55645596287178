import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Select, InputLabel, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { translate } from '../../Helper/Dictionary';
import Grid from "@mui/material/Grid";
const { v4: uuidv4 } = require('uuid');


const bull = (
	<Box
		component="span"
		sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
	>
		•
	</Box>
);

export default function NewRoofCard(this: any, props: any) {
	const [addButtonDisabled, setAddButtonDisabled] = React.useState(true);
	const [area, setArea] = React.useState();
	const [usedArea, setusedArea] = React.useState();
	const [angle, setAngle] = React.useState();
	const [orientation, setOrientation] = React.useState();
	const [clouding, setClouding] = React.useState("none");

	const checkForSufficientInformationsProvided = (checkForFields: any[]): void => {
		var checksPassed = false;
		var index = 0;
		for (var field of checkForFields) {
			console.log("Check for field: " + field);
			if (field == null || field == '') {
				checksPassed = false;
				break;
			} else if (index == 0 && (parseInt(field) <= 0) || isNaN(field as any)) {
				checksPassed = false;
				break;
			} else if (index == 1 && (parseInt(field) <= 0) || isNaN(field as any)) {
				checksPassed = false;
				break;
			} else if (index == 2 && (parseInt(field) < 0 || parseInt(field) > 90) || isNaN(field as any)) {
				checksPassed = false;
				break;
			} else if (index == 3 && (parseInt(field) < -180 || parseInt(field) > 180) || isNaN(field as any)) {
				checksPassed = false;
				break;
			} else {
				checksPassed = true;
			}
			index += 1;
		}
		if (checksPassed) {
			setAddButtonDisabled(false);
		} else {
			setAddButtonDisabled(true);
		}
	};

	const updateArea = (textFieldValue: any): void => {
		checkForSufficientInformationsProvided([textFieldValue.target.value, usedArea, angle, orientation]);
		setArea(textFieldValue.target.value);
	};

	const updateUsedArea = (textFieldValue: any): void => {
		checkForSufficientInformationsProvided([area, textFieldValue.target.value, angle, orientation]);
		setusedArea(textFieldValue.target.value);
	};

	const updateAngle = (textFieldValue: any): void => {
		checkForSufficientInformationsProvided([area, usedArea, textFieldValue.target.value, orientation]);
		setAngle(textFieldValue.target.value);
	};

	const updateOrientation = (textFieldValue: any): void => {
		checkForSufficientInformationsProvided([area, usedArea, angle, textFieldValue.target.value]);
		setOrientation(textFieldValue.target.value);
	};

	const handleCloudingChange = (e: any): void => {
		props.setTechnicalUserInput(new Map(props.technicalUserInput.set("clouding", e.target.value)));
		setClouding(e.target.value);
	};

	const updateTechnicalUserInput = (): void => {
		const mapTechnicalUserInput = props.technicalUserInput;

		var updatedTechnicalUserInput = new Map<string, any>();

		for (const [key, value] of mapTechnicalUserInput.entries()) {
			updatedTechnicalUserInput.set(key, mapTechnicalUserInput.get(key))
		}

		const roof = new Map<string, string>();
		roof.set("id", uuidv4());
		roof.set("area", String(area));
		roof.set("usedArea", String(usedArea));
		roof.set("orientation", String(orientation));
		roof.set("angle", String(angle));
		roof.set("clouding", String(clouding));

		if (!mapTechnicalUserInput.has("roofs")) {
			const roofs = [roof];

			updatedTechnicalUserInput.set("roofs", roofs);
		} else {
			var roofs = mapTechnicalUserInput.get("roofs");
			roofs.push(roof);

			updatedTechnicalUserInput.set("roofs", roofs);
		}
		props.setTechnicalUserInput(updatedTechnicalUserInput);
	};

	return (
		<Card sx={{ minWidth: 275 }}>
			<CardContent>
				<Typography sx={{ fontSize: 18, marginBottom: 1 }} color="text.secondary" gutterBottom>
					{translate('labelAddRoof', props.guiLanguage)}
				</Typography>
				<Grid container spacing={2}>
					<Grid item>
						<TextField sx={{ marginRight: 2 }} id="outlined-basic" label={translate('labelArea', props.guiLanguage)} variant="outlined" onChange={updateArea} required />
					</Grid>
					<Grid item>
						<TextField sx={{ marginRight: 2 }} id="outlined-basic" label={translate('labelOrientation', props.guiLanguage)} variant="outlined" onChange={updateOrientation} required />
					</Grid>
					<Grid item>
						<TextField sx={{ marginRight: 2 }} id="outlined-basic" label={translate('labelAngle', props.guiLanguage)} variant="outlined" onChange={updateAngle} required />
					</Grid>
					<Grid item>
						<TextField sx={{ marginRight: 2 }} id="outlined-basic" label={translate('labelUseArea', props.guiLanguage)} variant="outlined" onChange={updateUsedArea} required />
					</Grid>
				</Grid>
				<FormControl sx={{ my: 2 }} fullWidth>
					<InputLabel id="clouding-select-label">{translate('labelClouding', props.guiLanguage)}</InputLabel>
					<Select
						labelId="clouding-label"
						id="clouding-select"
						value={props.technicalUserInput.get("clouding") || "none"}
						label="Verschattung"
						onChange={handleCloudingChange}
					>
						<MenuItem value={"none"}>{translate('labelNot', props.guiLanguage)}</MenuItem>
						<MenuItem value={"low"}>{translate('labelLow', props.guiLanguage)}</MenuItem>
						<MenuItem value={"medium"}>{translate('labelMedium', props.guiLanguage)}</MenuItem>
						<MenuItem value={"high"}>{translate('labelHigh', props.guiLanguage)}</MenuItem>
					</Select>
				</FormControl>
			</CardContent>
			<CardActions>
				<Tooltip title="Add" >
					<span>
						<IconButton onClick={updateTechnicalUserInput} disabled={addButtonDisabled}>
							<AddIcon />
						</IconButton>
					</span>
				</Tooltip>
			</CardActions>
		</Card>
	);
}