import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import Button from '@mui/material/Button';
import { getFriendlyNameForAspect } from '../ReviewPDFPaneFolder/Resources/CalculateHelperFunctions.js';
import { translate } from '../../Helper/Dictionary.js';
const { v4: uuidv4 } = require('uuid');

const RoofsDataGrid = (props: any) => {
    const columns = [
        { field: 'usedArea', headerName: translate('labelUseArea', props.guiLanguage) },
        { field: 'orientation', headerName: translate('labelOrientation', props.guiLanguage), width: 140},
        { field: 'angle', headerName: translate('labelAngle', props.guiLanguage), width: 80 },
		{ field: 'area', headerName: translate('labelArea', props.guiLanguage), width: 80 },
        { field: 'clouding', headerName: translate('labelClouding', props.guiLanguage), width: 80 }
      ]

	  useEffect(() => {
		if (!props.technicalUserInput.has("roofs")) {
			const getInitialRoofs = getRoofs(props.handleDealData);			
			var newTechnicalUserInput = new Map(props.technicalUserInput);
			newTechnicalUserInput.set("roofs", getInitialRoofs);
			props.setTechnicalUserInput(newTechnicalUserInput);
		}
	  }, [props.technicalUserInput]);
	  
	const getRoofs = (data: any) : any =>  {
		var roofs = [];
		let orientation = data.get("details")["bce5d774fca45fd7e6d67b61040f1380569b5fda"];
		let angle = data.get("details")["a9ab0b61cb6c64f1250578b9b2913437f0c20cd0"];
		let area = data.get("details")["0ae72d32815126c158cf2cbaf95a61335934f4ae"];
		let usedArea = data.get("details")["98b7d545c8206c664d4480f84c80b46b34a584c8"];
		let clouding = data.get("details")["b3a1cf7707c4699901fa92d27fb16a1dda1d56bb"] || 'none';
		//ROOF 1
		if (orientation && angle && area && usedArea) {
		
			const roof = new Map<string, string> ();
			roof.set("id", uuidv4());
			roof.set("area", String(area));
			roof.set("usedArea", String(usedArea));
			roof.set("orientation", String(orientation));
			roof.set("angle", String(angle));
			roof.set("clouding", String(clouding));

			roofs.push(roof);
		}

		orientation = data.get("details")["5ef7de6f7268a9b011b2f239726c665be53af33f"];
		angle = data.get("details")["8831f0759fe1a526b1df6554acf4719745add0db"];
		area = data.get("details")["c660c0536c48c6f49ccc404570d6d7654abe3564"];
		usedArea = data.get("details")["84f0648b9c25123a54ce66689f4950733718ffe8"];
		clouding = data.get("details")["0b933a4aad5a71cd55553af521b179896f3d3f12"] || 'none';

		if (orientation && angle && area && usedArea) {
			const roof = new Map<string, string> ();
			roof.set("id", uuidv4());
			roof.set("usedArea", String(area));
			roof.set("area", String(usedArea));
			roof.set("orientation", String(orientation));
			roof.set("angle", String(angle));
			roof.set("clouding", String(clouding));
			roofs.push(roof);
		}

		orientation = data.get("details")["20c6aaf5d46909aabd6b087c445b63753bd87a26"];
		angle = data.get("details")["eeab3f320233d3e06dc69e62e4ce9cec69ca8b2e"];
		area = data.get("details")["a91f53c41a77839ea3817e6cfa65fb6f27eb6ca5"];
		usedArea = data.get("details")["5a736f9a4b8a3ef21499b2d3c451b3dce9408073"];
		clouding = data.get("details")["76b896ca9082ceb1e50bac4ed984086cd19fdbc7"] || 'none';

		if (orientation && angle && area && usedArea) {
			const roof = new Map<string, string> ();
			roof.set("id", uuidv4());
			roof.set("usedArea", String(area));
			roof.set("area", String(usedArea));
			roof.set("orientation", String(orientation));
			roof.set("angle", String(angle));
			roof.set("clouding", String(clouding));
			roofs.push(roof);
		}

		orientation = data.get("details")["4253c0ddcf3c5f674d4a2d5907db89564f725bda"];
		angle = data.get("details")["b1bce9ca1d9d99dbe7fcb2a1a8938a61a2291b3f"];
		area = data.get("details")["ee97759e2a34fc105e1f14c16982eb21542716e5"];
		usedArea = data.get("details")["69a8d933c668740a9fe72a1ec683894f867a6cfb"];
		clouding = data.get("details")["2883136a9de6fa87be76ecec106eb7a152431b26"] || 'none';

		if (orientation && angle && area && usedArea) {
			const roof = new Map<string, string> ();
			roof.set("id", uuidv4());
			roof.set("usedArea", String(area));
			roof.set("area", String(usedArea));
			roof.set("orientation", String(orientation));
			roof.set("angle", String(angle));
			roof.set("clouding", String(clouding));
			roofs.push(roof);
		}

		orientation = data.get("details")["fff358d1097a5e3cc2fbc580acf5c09ed62441a6"];
		angle = data.get("details")["c19a54a7d7f8c6c335261050dd5baadde27828de"];
		usedArea = data.get("details")["795b477ef866862bc76e9e3e601e351d8569c957"];
		area = data.get("details")["55bd00a6a02e7c7d1c96521d88a35795d0eb51cb"];
		clouding = data.get("details")["3e16b8eadd62dc80650784ae4e53d3666ca91bb7"] || 'none';

		if (orientation && angle && area && usedArea) {
			const roof = new Map<string, string> ();
			roof.set("id", uuidv4());
			roof.set("usedArea", String(area));
			roof.set("area", String(usedArea));
			roof.set("orientation", String(orientation));
			roof.set("angle", String(angle));
			roof.set("clouding", String(clouding));
			roofs.push(roof);
		}
		return roofs;
	};

	  
    const [selectedRows, setSelectedRows] = useState<string[]>([]);


    const deleteRoof = (id: string) => {
        console.log("Will delete a roof row");
        const updatedRoofs = [];
        for (var roof of props.technicalUserInput.get("roofs")) {
            if(!(roof.get("id").toString() == id)) {
                updatedRoofs.push(roof);
            }
        }
        var newTechnicalUserInput = new Map(props.technicalUserInput);
        if(updatedRoofs.length == 0) {
            newTechnicalUserInput.delete("roofs");

        } else {
            newTechnicalUserInput.set("roofs", updatedRoofs)
        }
        props.setTechnicalUserInput(newTechnicalUserInput);
    }; 

    const handlePurge = () => {
        selectedRows.forEach(e => deleteRoof(e));
      };

    const getTableRows = (): any => {
        if (props.technicalUserInput.has("roofs")) {
            const tableRows: any[] = [];
            var i = 0;
            for(var roof of props.technicalUserInput.get("roofs")) {
                tableRows[i] = Object.fromEntries(roof)
                tableRows[i]["orientation"] += "° (" + getFriendlyNameForAspect(tableRows[i]["orientation"], props.guiLanguage) + ")";
                tableRows[i]["angle"] += "°";
                tableRows[i]["usedArea"] += "m²"
                if (tableRows[i]["clouding"] == "none") {
                    tableRows[i]["clouding"] = translate('labelNot', props.guiLanguage)
                } else if (tableRows[i]["clouding"] == "low") {
                    tableRows[i]["clouding"] = translate('labelLow', props.guiLanguage)
                } else if (tableRows[i]["clouding"] == "medium") {
                    tableRows[i]["clouding"] = translate('labelMedium', props.guiLanguage)
                } else if (tableRows[i]["clouding"] == "high") {
                    tableRows[i]["clouding"] = translate('labelHigh', props.guiLanguage)
                }
                i += 1;
            }
            return tableRows;
        } else {
            return [];
        }
    }
  
    return (
        <React.Fragment>
        <div style={{ height: 400, width: '100%' }}>
        <DataGrid
        rows={getTableRows()}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        onSelectionModelChange={(ids) => {
            const _selectedRows : string[] = [];
            ids.forEach(e => (_selectedRows.push(e.toString())))
            setSelectedRows(_selectedRows);
          }}
        />
        </div>
        <Button variant="contained" color="primary" onClick={handlePurge}>
          {translate('buttonRemoveRoofs', props.guiLanguage)}
        </Button>
        </React.Fragment>
    )
}
  
export default RoofsDataGrid