function getDiscreteAbsoluteAspect(aspect) {
    if (Math.abs(aspect) >= 0 && Math.abs(aspect) <= 75) {
        return 60;
    } else if (Math.abs(aspect) > 75 &&  Math.abs(aspect) <= 100) {
        return 90;
    } else if (Math.abs(aspect) > 100 &&  Math.abs(aspect) <= 122.5) {
        return 110;
    } else if (Math.abs(aspect) > 122.5 &&  Math.abs(aspect) <= 150) {
        return 135;
    } else if (Math.abs(aspect) > 150 &&  Math.abs(aspect) <= 180) {
        return 180;
    } else {
        return -1;
    }
}

function getDiscreteAngle(angle) {
    if (angle <= 15) {
        return 15;
    } else if (angle <= 30) {
        return 30;
    } else if (angle <= 45) {
        return 45;
    } else if (angle <= 60) {
        return 60;
    } else if (angle <= 75) {
        return 75;
    } else if (angle <= 90) {
        return 90;
    } else {
        return -1;
    }
}

export function getLossForClouding(availableProducts, angle, orientation, clouding) {
    for (var virtualProduct of availableProducts.virtualProducts) {
        if (virtualProduct.id == "283") {
            const lossForCloudingTable = JSON.parse(virtualProduct.description);
                const lossForClouding = lossForCloudingTable[getDiscreteAbsoluteAspect(orientation).toString()];
                const lossRatio = 1 - parseFloat(lossForClouding[getDiscreteAngle(angle).toString()]);
                let loss = 2;
                if (clouding == "none") {
                    loss = lossRatio;
                } else if (clouding == "low") {
                    loss = lossRatio * 0.9;
                } else if (clouding == "medium") {
                    loss = lossRatio * 0.75;
                } else if (clouding == "high") {
                    loss = lossRatio * 0.5;
                }
            return ((1-loss) * 100);
        }
    }
}