var Mustache = require('mustache');
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

require('../TT_Firs_Neue_400');
require('../TT_Firs_Neue_500');

import { translate } from '../../../../Helper/Dictionary';
import { getFriendlyNameForAspect } from '../CalculateHelperFunctions.js';
import pdfAddHeader from './Helper/PDFAddHeader';
import pdfAddDraftWatermark from './Helper/PDFAddDraftWatermark';

export default function addRoofsAreaSection(doc, agentUserInput, processedTechnicalInformation, selectedDeal, technicalUserInput, draft) {
  const tenantName = agentUserInput.get('tenant');

  var startY = 0;

  for(var i = 0; i < processedTechnicalInformation.get("roofs").length; i += 6) {
    var roofsOnPage = Math.min(processedTechnicalInformation.get("roofs").length - i, 6);
    doc.addPage();
    pdfAddHeader(doc, agentUserInput, translate("labelBOM", agentUserInput.get("offerLanguage")), translate("labelCraftRoof", agentUserInput.get("offerLanguage")));
  
    for(var j = 0; j < roofsOnPage; j++) {
      var roofIndex = i + j;

      var startX = 20.8;
      startY = 140;
      if (j == 0) {
        startY = 52;
        startX = 20.8;
      } else if (j == 1) {
        startY = 52;
        startX = 109.2;
      } else if (j == 2) {
        startY = 121;
        startX = 20.8;
      } else if (j == 3) {
        startY = 121;
        startX = 109.2;
      } else if (j == 4) {
        startY = 200;
        startX = 20.8;
      } else if (j == 5) {
        startY = 200;
        startX = 109.2;
      }

      doc.autoTable({
        startY: startY,
        margin: { left: startX },
        tableWidth: 80,
        styles: {font: "TT_Firs_Neue"},
        head: [[translate("labelTableHeaderNoOfRoof", agentUserInput.get("offerLanguage")), (i + j + 1)]],
        body: [
          [translate("labelOrientation", agentUserInput.get("offerLanguage")), technicalUserInput.get("roofs")[i + j].get("orientation") + "° (" + getFriendlyNameForAspect(technicalUserInput.get("roofs")[i + j].get("orientation"), agentUserInput.get("offerLanguage")) + ")"],
          [translate("labelAngle", agentUserInput.get("offerLanguage")), technicalUserInput.get("roofs")[i + j].get("angle").toString() + '°'],
          [translate("labelTileCount", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("roofs")[i + j].get("brickCount").toFixed(0).toLocaleString('de-DE')],
          [translate("labelSolarArea", agentUserInput.get("offerLanguage")), parseFloat(technicalUserInput.get("roofs")[i + j].get("area")).toFixed(0).toLocaleString('de-DE') + ' qm'],
          [translate("labelPower", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("roofs")[i + j].get("peakPower").toLocaleString('de-DE') + ' kWp'],
          [translate("labelAnnualYield", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("roofs")[i + j].get("totalAnnualOutput").toLocaleString('de-DE') + ' kWh']
        ]
      });
    }
    doc.setFontSize(10);
    doc.setFont('TT_Firs_Neue_Regular', 'normal');
  }

  doc.setFont('TT_Firs_Neue', 'normal');
  doc.setFillColor(255, 243, 101);
  doc.setTextColor(16, 25, 3);
  doc.setFontSize(10);

  if(technicalUserInput.get("printMap")) {
    if (processedTechnicalInformation.get("roofs").length <= 2) {
      doc.addImage(processedTechnicalInformation.get("customerRoofPicture"), 'jpeg', 20.8, 121, 120, 120*300/600);
      doc.roundedRect(22.8, 124, 50, 15, 5, 5, 'f');
      doc.text(`${selectedDeal.get("street")} ${selectedDeal.get("streetNumber")}\n${selectedDeal.get("zip")} ${selectedDeal.get("city")}`, 24.8, 131, {maxWidth: 50});
    } else if (processedTechnicalInformation.get("roofs").length <= 4 && processedTechnicalInformation.get("roofs").length > 2) {
      doc.addImage(processedTechnicalInformation.get("customerRoofPicture"), 'jpeg', 20.8, 200, 120, 120*300/600);
      doc.roundedRect(22.8, 203, 50, 15, 5, 5, 'f');
      doc.text(`${selectedDeal.get("street")} ${selectedDeal.get("streetNumber")}\n${selectedDeal.get("zip")} ${selectedDeal.get("city")}`, 24.8, 210, {maxWidth: 50});
    } else if (processedTechnicalInformation.get("roofs").length <= 6 && processedTechnicalInformation.get("roofs").length > 2) {
      doc.addPage();
      pdfAddHeader(doc, agentUserInput, translate("labelBOM", agentUserInput.get("offerLanguage")), translate("labelCraftRoof", agentUserInput.get("offerLanguage")));

      doc.setFont('TT_Firs_Neue', 'normal');
      doc.setFillColor(255, 243, 101);
      doc.setTextColor(16, 25, 3);
      doc.setFontSize(10);

      doc.addImage(processedTechnicalInformation.get("customerRoofPicture"), 'jpeg', 20.8, 55, 120, 120*300/600);
      doc.roundedRect(22.8, 58, 50, 15, 5, 5, 'f');
      doc.text(`${selectedDeal.get("street")} ${selectedDeal.get("streetNumber")}\n${selectedDeal.get("zip")} ${selectedDeal.get("city")}`, 24.8, 65, {maxWidth: 50});
    }
  }

  if (draft === true) {
    pdfAddDraftWatermark(doc, agentUserInput);
  }

  doc.setTextColor(0,0,0);
};