import jsPDF from "jspdf";

import addCoverSection from './Resources/Library/PDFCoverSection.js'
import addCoverLetterSection from './Resources/Library/PDFCoverLetterSection.js';
import addYieldEstimationSection from './Resources/Library/PDFYieldEstimationSection.js';
import addRoofsAreaSection from './Resources/Library/PDFRoofsAreaSection.js';
import addVisualisationAndSampleSection from './Resources/Library/PDFVisualisationAndSampleSection.js';
import addBOMSection from './Resources/Library/PDFBOMSection.js';
import addElectroSection from './Resources/Library/PDFElectroSection.js';
import addOutroSection from './Resources/Library/PDFOutroSection.js';

const generatePDF = (selectedDeal, availableProducts, technicalUserInput, processedTechnicalInformation, agentUserInput, tenantsAvailable, isDraft) => {
    var doc = new jsPDF({
      orientation: 'p', 
      unit: 'mm', 
      format: 'a4'
    });

    addCoverSection(doc, agentUserInput, isDraft);
    addCoverLetterSection(doc, agentUserInput, processedTechnicalInformation, selectedDeal, technicalUserInput, tenantsAvailable, isDraft);
    addYieldEstimationSection(doc, agentUserInput, processedTechnicalInformation, selectedDeal, technicalUserInput, isDraft);
    addRoofsAreaSection(doc, agentUserInput, processedTechnicalInformation, selectedDeal, technicalUserInput, isDraft);
    addVisualisationAndSampleSection(doc, agentUserInput, isDraft);
    addBOMSection(doc, agentUserInput, availableProducts, processedTechnicalInformation, selectedDeal, technicalUserInput, tenantsAvailable, isDraft);
    addElectroSection(doc, agentUserInput, availableProducts, processedTechnicalInformation, selectedDeal, technicalUserInput, isDraft);
    addOutroSection(doc, agentUserInput, isDraft);

    return doc;
  };

  export default generatePDF;