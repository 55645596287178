import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Select, InputLabel, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { translate } from '../Helper/Dictionary';

export default function AgentSelection(props: any) {
    const [agentUserInputProvided, setAgentUserInputProvided] = useState([true, false]);
    const [creator, setCreator] = useState("");

    useEffect(() => {		
        if(agentUserInputProvided[0] && agentUserInputProvided[1]) {
          props.setCanGoOn(true);
        } else {
          props.setCanGoOn(false);
        }
    });

    useEffect(() => {		
        if(props.agentUserInput.has("creatorId")) {
          setCreator(props.agentUserInput.get("creatorId"));
          setAgentUserInputProvided([true, true]);
        }
        setAvailableLocationServices();
    }, []);

    const handleTenantChange = (e: any):void => {
      if (e.target.value == "creaton") {
        props.setAgentUserInput(new Map(props.agentUserInput.set("tenant", "creaton")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("typeOfCustomer", "consumer")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("salesCountry", "DE")));
      } else if (e.target.value == "jacobiWalther") {
        props.setAgentUserInput(new Map(props.agentUserInput.set("tenant", "jacobiWalther")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("typeOfCustomer", "consumer")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("salesCountry", "DE")));
      } else if (e.target.value == "autarq") {
        props.setAgentUserInput(new Map(props.agentUserInput.set("tenant", "autarq")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("typeOfCustomer", "consumer")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("salesCountry", "DE")));
      } else if (e.target.value == "creatonFabricator") {
        props.setAgentUserInput(new Map(props.agentUserInput.set("tenant", "creaton")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("typeOfCustomer", "fabricator")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("salesCountry", "DE")));
      } else if (e.target.value == "creatonTrading") {
        props.setAgentUserInput(new Map(props.agentUserInput.set("tenant", "creaton")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("typeOfCustomer", "trading")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("salesCountry", "DE")));
      } else if (e.target.value == "creatonPartnerTrading") {
        props.setAgentUserInput(new Map(props.agentUserInput.set("tenant", "creaton")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("typeOfCustomer", "partnerTrading")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("salesCountry", "DE")));
      }  else if (e.target.value == "creatonSwitzerland") {
        props.setAgentUserInput(new Map(props.agentUserInput.set("tenant", "creaton")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("typeOfCustomer", "consumer")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("salesCountry", "CH")));
      }  else if (e.target.value == "creatonFabricatorSwitzerland") {
        props.setAgentUserInput(new Map(props.agentUserInput.set("tenant", "creaton")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("typeOfCustomer", "fabricator")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("salesCountry", "CH")));
      }  else if (e.target.value == "creatonTradingSwitzerland") {
        props.setAgentUserInput(new Map(props.agentUserInput.set("tenant", "creaton")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("typeOfCustomer", "trading")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("salesCountry", "CH")));
      } else if (e.target.value == "creatonPartnerTradingSwitzerland") {
        props.setAgentUserInput(new Map(props.agentUserInput.set("tenant", "creaton")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("typeOfCustomer", "partnerTrading")));
        props.setAgentUserInput(new Map(props.agentUserInput.set("salesCountry", "CH")));
      }
      //Flush technical user input when changing the tenant
      props.setTechnicalUserInput(new Map());
    };

    const setAvailableLocationServices = ():void => {
      const availableLocationServices = new Array();
      availableLocationServices.push({value: "geocode", name: 'labelAddressOfBuildingProject'});
      if(props.selectedDeal.get("latitude") != null && props.selectedDeal.get("longitude") != null) {
        console.log("Deal has coordinates available!");
        availableLocationServices.push({value: 'coordinates', name: 'labelCoordinates'});
      }
      props.setSelectedDeal(new Map(props.selectedDeal.set("availableLocationServices", availableLocationServices)));
    };

    const handleCreatorChange = (e: any):void => {
      setCreator(e.target.value);
      props.setAgentUserInput(new Map(props.agentUserInput.set("creatorId", e.target.value)));
      for (const participant of props.selectedDeal.get("participants")) {
        if (participant.person.id == e.target.value) {
          props.setAgentUserInput(new Map(props.agentUserInput.set("creatorCompany", participant.person.org_name)));
          props.setAgentUserInput(new Map(props.agentUserInput.set("creatorSalutation", participant["727f60c0d57d6c1c71d8ee42a8d7006c77beada7"])));
          props.setAgentUserInput(new Map(props.agentUserInput.set("creatorForename", participant.person.first_name)));
          props.setAgentUserInput(new Map(props.agentUserInput.set("creatorLastname", participant.person.last_name)));
          props.setAgentUserInput(new Map(props.agentUserInput.set("creatorStreet", participant.person["71310f172eea39b933c9eec0853a98fdcb2b7ba2"])));
          props.setAgentUserInput(new Map(props.agentUserInput.set("creatorStreetNumber", participant.person["769802467d90d689252d80347ee24212566ce47c"])));
          props.setAgentUserInput(new Map(props.agentUserInput.set("creatorZip", participant.person["c3c90386d2794052173a4b36da79a70f62395714"] ? participant.person["c3c90386d2794052173a4b36da79a70f62395714"] : participant.person["1a56c3fe83a3c4d6289c41a34576f2a467dead3e"])));
          props.setAgentUserInput(new Map(props.agentUserInput.set("creatorCity", participant.person["88b5693597ba57dabb69048fb990ae156b6408c4"])));
          props.setAgentUserInput(new Map(props.agentUserInput.set("creatorCountry", participant.person["c819ab470bcf56ba060cf42b011a6641cdaff3a9"])));
        }
      }
      setAgentUserInputProvided([true, true]);
    };

    const handleOfferLanguageChange = (e: any):void => {
      props.setAgentUserInput(new Map(props.agentUserInput.set("offerLanguage", e.target.value)));
    }

    const getValueForSelect = ():String => {
      const tenant = props.agentUserInput.get("tenant");
      const typeOfCustomer = props.agentUserInput.get("typeOfCustomer");
      const salesCountry = props.agentUserInput.get("salesCountry");

      if (tenant == "creaton") {
        if (typeOfCustomer == "consumer"  && salesCountry == "DE") {
          return tenant;
        } else if (typeOfCustomer == "fabricator" && salesCountry == "DE") {
          return "creatonFabricator";
        } else if (typeOfCustomer == "trading" && salesCountry == "DE") {
          return "creatonTrading";
        } else if (typeOfCustomer == "partnerTrading" && salesCountry == "DE") {
          return "creatonPartnerTrading";
        } else if (typeOfCustomer == "consumer"  && salesCountry == "CH") {
          return "creatonSwitzerland";
        } else if (typeOfCustomer == "fabricator" && salesCountry == "CH") {
          return "creatonFabricatorSwitzerland";
        } else if (typeOfCustomer == "trading" && salesCountry == "CH") {
          return "creatonTradingSwitzerland";
        } else if (typeOfCustomer == "partnerTrading" && salesCountry == "CH") {
          return "creatonPartnerTradingSwitzerland";
        }
      } else {
        return tenant;
      }
      return "autarq";
    };

    return (
        <React.Fragment>
          <Typography variant="h6" gutterBottom>
            { translate('labelSelectTenant', props.guiLanguage) }
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="tenant-select-label">{ translate('labelTenant', props.guiLanguage) }</InputLabel>
            <Select
              labelId="tenant-label"
              id="tenant-select"
              //value={props.agentUserInput.get("tenant")}
              value={getValueForSelect()}
              label="tenant"
              onChange={handleTenantChange}
            >
              { (props.tenantsAvailable.get('tenants').tenantsData.filter((tenant: any) => tenant.tenant === 'autarq').length === 0) ? ( null ) : ( <MenuItem value={"autarq"}>Autarq</MenuItem> ) }
              { (props.tenantsAvailable.get('tenants').tenantsData.filter((tenant: any) => tenant.tenant === 'jacobiWalther').length === 0) ? ( null ) : ( <MenuItem value={"jacobiWalther"}>Jacobi Walther</MenuItem> ) }
              { (props.tenantsAvailable.get('tenants').tenantsData.filter((tenant: any) => tenant.tenant === 'creaton').length === 0) ? ( null ) : ( <MenuItem value={"creaton"}>Creaton Endkunde</MenuItem> ) }
              { (props.tenantsAvailable.get('tenants').tenantsData.filter((tenant: any) => tenant.tenant === 'creaton').length === 0) ? ( null ) : ( <MenuItem value={"creatonTrading"}>Creaton Handel</MenuItem> ) }
              { (props.tenantsAvailable.get('tenants').tenantsData.filter((tenant: any) => tenant.tenant === 'creaton').length === 0) ? ( null ) : ( <MenuItem value={"creatonPartnerTrading"}>Creaton lagerführenden Händler</MenuItem> ) }
              { (props.tenantsAvailable.get('tenants').tenantsData.filter((tenant: any) => tenant.tenant === 'creaton').length === 0) ? ( null ) : ( <MenuItem value={"creatonFabricator"}>Creaton Verarbeiter</MenuItem> ) }
              { (props.tenantsAvailable.get('tenants').tenantsData.filter((tenant: any) => tenant.tenant === 'creaton').length === 0) ? ( null ) : ( <MenuItem value={"creatonSwitzerland"}>Creaton Endkunde Schweiz</MenuItem> ) }
              { (props.tenantsAvailable.get('tenants').tenantsData.filter((tenant: any) => tenant.tenant === 'creaton').length === 0) ? ( null ) : ( <MenuItem value={"creatonTradingSwitzerland"}>Creaton Handel Schweiz</MenuItem> ) }
              { (props.tenantsAvailable.get('tenants').tenantsData.filter((tenant: any) => tenant.tenant === 'creaton').length === 0) ? ( null ) : ( <MenuItem value={"creatonPartnerTradingSwitzerland"}>Creaton lagerführenden Händler Schweiz</MenuItem> ) }
              { (props.tenantsAvailable.get('tenants').tenantsData.filter((tenant: any) => tenant.tenant === 'creaton').length === 0) ? ( null ) : ( <MenuItem value={"creatonFabricatorSwitzerland"}>Creaton Verarbeiter Schweiz</MenuItem> ) }
            </Select>
          </FormControl>
          <Typography variant="h6" gutterBottom>
            { translate('labelSelectRecipient', props.guiLanguage) }
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="creator-select-label">{ translate('labelRecipient', props.guiLanguage) }</InputLabel>
            <Select
              labelId="creator-label"
              id="creator-select"
              value={creator}
              label="Ersteller"
              onChange={handleCreatorChange}
            >
				{
				props.selectedDeal.get("participants") && props.selectedDeal.get("participants").length > 0 ? (
					props.selectedDeal.get("participants").map((participant: any) => (
					<MenuItem value={participant.person.id} key={participant.person.id}>
						{
						(participant.person.org_name || translate('labelIndividual', props.guiLanguage)) + ": " + participant.person.first_name + " " + participant.person.last_name + " (" +
						props.handlePipedriveInfos.find((item: any) => item.id ===  (parseInt ((participant.person['b51d6e812905a59b23039fb34e654b9dc1659a66'] || 0))) || 0).label  + ")"
						}
					</MenuItem>
					))
				) : (
					<MenuItem value="" key="empty-participant">
					Kein Empfänger gefunden.
					</MenuItem>
				)
				}		  
            </Select>

          </FormControl>
          <Typography variant="h6" gutterBottom>
            { translate('labelSelectOfferLanguage', props.guiLanguage) }
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="offer-language-select-label">{ translate('labelLanguage', props.guiLanguage) }</InputLabel>
            <Select
              labelId="offer-language-select-label"
              id="offer-language-select"
              //value={props.agentUserInput.get("tenant")}
              value={props.agentUserInput.get("offerLanguage")}
              label="Mandant"
              onChange={handleOfferLanguageChange}
            >
              <MenuItem value={"de-DE"}>{translate('labelGerman', props.guiLanguage)}</MenuItem>
              <MenuItem value={"en-DE"}>{translate('labelEnglish', props.guiLanguage)}</MenuItem>
            </Select>
          </FormControl>
        </React.Fragment>
    );
}