require('../TT_Firs_Neue_400');
require('../TT_Firs_Neue_500');

import { getTemplate } from '../MiscHelperFunctions';

import { coverImage } from '../Assets/coverImage';
import { autarqLogo } from '../Assets/autarqLogo';
import { germanDesignAward, rdDesignAward, ifDesignAward } from '../Assets/FooterMedia';
import pdfAddDraftWatermark from './Helper/PDFAddDraftWatermark';

export default function addCoverSection(doc, agentUserInput, draft) {
    doc.setFontSize(18);
    doc.setTextColor(16, 25, 32);

    const date = new Date(Date.now());
  
    const autarqLogoWidth = 31.4378;
    const autarqLogoHeight = 7.085;
    const autarqLogoY = 8.82;

    doc.addImage(autarqLogo, 'jpeg', 20.8, autarqLogoY, autarqLogoWidth, autarqLogoHeight);

    if(agentUserInput.get(agentUserInput.get('tenant'))["isExternal"]) {
      const logoMetadata = agentUserInput.get(agentUserInput.get('tenant'))["logoMetadata"]
      doc.addImage(getTemplate(agentUserInput.get(agentUserInput.get('tenant'))["logo"], 'logo', 'bg', 'white'), 'jpeg', ( 210 - ( 20.8 + autarqLogoWidth ) ) , autarqLogoY + 0.5 * (autarqLogoHeight - autarqLogoWidth*logoMetadata["height"]/logoMetadata["width"]), autarqLogoWidth, autarqLogoWidth*logoMetadata["height"]/logoMetadata["width"]);
    }
  
    doc.addImage(coverImage, 'jpeg', 17.294, 24.691, 175.4118, 98.7648);
  
    doc.setFontSize(18);
    doc.setFont('TT_Firs_Neue', 'bold');
    doc.text(getTemplate(agentUserInput.get(agentUserInput.get('tenant')).dictionary, "title", 'language', agentUserInput.get("offerLanguage")), 20.8, 129.805, { baseline: 'top' });
    
    doc.setTextColor(104, 109, 111);
    doc.text(getTemplate(agentUserInput.get(agentUserInput.get('tenant')).dictionary, "subTitle", 'language', agentUserInput.get("offerLanguage")), 20.8, 137.213, { baseline: 'top' });

    const germanDesignAwardRatio = 1298 / 779;
    const ifDesignAwardRatio = 1124 / 591;
    const rdDesignAwardRatio = 1378 / 945;
    const logoHeight = 10;
    const margin = 5;

    doc.addImage(ifDesignAward, 'jpeg', 20.8, 262.0796, logoHeight * ifDesignAwardRatio, logoHeight);
    doc.addImage(rdDesignAward, 'jpeg', 20.8 + 1 * margin + logoHeight * ifDesignAwardRatio, 262.0796, logoHeight * rdDesignAwardRatio ,logoHeight);
    doc.addImage(germanDesignAward, 'jpeg', 20.8 + 2 * margin + logoHeight * ifDesignAwardRatio + logoHeight * rdDesignAwardRatio, 262.0796, logoHeight * germanDesignAwardRatio ,logoHeight);
  
    doc.setFont('TT_Firs_Neue', 'normal');
    doc.setFontSize(6);
    doc.setTextColor(16, 25, 32);
    doc.text(date.toLocaleDateString('DE-de'), 20.8, 279.011, { baseline: 'top' });
    doc.text('www.autarq.com', 178.235, 279.011, { baseline: 'top' });

    if (draft === true) {
      pdfAddDraftWatermark(doc, agentUserInput);
    }
}