/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
let _middlewareUrl = 'http://localhost:3001';
if (process.env.NODE_ENV === 'production') {
  // dev / staging is on 3000
  _middlewareUrl = 'https://planning-api.autarq.net';
} else if (process.env.NODE_ENV === 'test') {
  _middlewareUrl = 'https://planning-api.autarq.net';
}
export let middlewareUrl = _middlewareUrl;

export const queryMiddleware = async (uri: string, body: any, method: string, accessToken: any) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Content-Type', 'application/json');

    const options = {
        method: method,
        headers: headers,
        body: JSON.stringify(body)
    };

    return fetch(_middlewareUrl + uri, options)
        .then(response => response.json())
        .catch(error => ({success: false, response: error}));
}
