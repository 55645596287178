import * as React from 'react';
import NewRoofCard from './NewRoofCard';
import RoofsDataGrid from './RoofsDataGrid';


const AddRoofPane = (props: any) => {
  return (
    <React.Fragment>
      <NewRoofCard technicalUserInput = {props.technicalUserInput} setTechnicalUserInput = {props.setTechnicalUserInput} guiLanguage = {props.guiLanguage} handleDealData = {props.handleDealData}/>
      <RoofsDataGrid technicalUserInput = {props.technicalUserInput} setTechnicalUserInput = {props.setTechnicalUserInput} guiLanguage = {props.guiLanguage} handleDealData = {props.handleDealData}/>
    </React.Fragment>
  )
}

export default AddRoofPane