export const msalConfig = {
  auth: {
    clientId: "dc08861f-5a6f-47e7-a7d7-9ec355bc2ae7",
    authority: "https://login.microsoftonline.com/af112989-8a95-47d5-b644-9a9fc9e52498",
    //redirectUri: "https://planning.autarq.net"
    //redirectUri: "http://localhost:3000"
    redirectUri: process.env.REDIRECT_URI
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: ["api://dc08861f-5a6f-47e7-a7d7-9ec355bc2ae7/Planungstool"]
};
