import { RawOff } from "@mui/icons-material";
import { translate } from "../../../Helper/Dictionary";

export function getFriendlyNameForAspect(aspect, language) {
    if (aspect <= 25 && aspect >= -25) {
        return translate('labelSouth', language);
    } else if (aspect < -25 && aspect > -66) {
        return translate('labelSouthEast', language);
    } else if (aspect <= -66 && aspect >= -115) {
        return translate('labelEast', language);
    } else if (aspect < -115 && aspect > -150) {
        return translate('labelNorthEast', language);
    } else if (aspect < 150 && aspect > 115) {
        return translate('labelNorthWest', language);
    } else if (aspect <= 115 && aspect >= 66) {
        return translate('labelWest', language);
    } else if (aspect < 66 && aspect > 25) {
        return translate('labelSouthWest', language);
    } else if (Math.abs(aspect) >= 150 &&  Math.abs(aspect) <= 180) {
        return translate('labelNorth', language);
    } else {
        return translate('labelUnknown', language);
    }
}

function getDiscreteAspect(aspect) {
    if (aspect <= 25 && aspect >= -25) {
        return 0;
    } else if (aspect < -25 && aspect > -66) {
        return -45;
    } else if (aspect <= -66 && aspect >= -115) {
        return -90;
    } else if (aspect < -115 && aspect > -150) {
        return -135;
    } else if (aspect < 150 && aspect > 115) {
        return 135;
    } else if (aspect <= 115 && aspect >= 66) {
        return 90;
    } else if (aspect < 66 && aspect > 25) {
        return 45;
    } else if (Math.abs(aspect) >= 150 &&  Math.abs(aspect) <= 180) {
        return 180;
    } else {
        return -1;
    }
}

function getDiscreteAngle(angle) {
    if (angle < 5) {
        return 0;
    } else if (angle < 15) {
        return 10;
    } else if (angle < 25) {
        return 20;
    } else if (angle < 35) {
        return 30;
    } else if (angle < 45) {
        return 40;
    } else if (angle < 55) {
        return 50;
    } else if (angle < 65) {
        return 60;
    } else if (angle < 75) {
        return 70;
    } else {
        return -1;
    }
}

function calculateDistanceOnCirle(aspect1, aspect2) {
    console.log("Calculating distance between " + aspect1 + " and " + aspect2 + ".");
    var difference = aspect1 - aspect2;
    if(Math.abs(difference) > 180) {
        difference = Math.abs(aspect1) - Math.abs(aspect2);
    }
    return Math.abs(difference);
}

function createMergedRoof(combineList, roofs, currentInterpretation, maxCurrent) {
    var totalBricksOfMergedRoof = 0;
    var calculatedAspect = 0;
    var calculatedAngle = 0;
    var calculatedBrickCurrent = 0;
    for(var i = 0; i < combineList.length; i++) {
        var roofNumber = combineList[i];
        totalBricksOfMergedRoof += roofs[roofNumber].get("brickCount");
    }
    for(var i = 0; i < combineList.length; i++) {
        var roofNumber = combineList[i];
        var ratio = roofs[roofNumber].get("brickCount")/totalBricksOfMergedRoof;

        calculatedAspect += ratio * roofs[roofNumber].get("aspect");
        calculatedAngle += ratio * roofs[roofNumber].get("angle");
        calculatedBrickCurrent += ratio * roofs[roofNumber].get("brickCurrent");
    }

    const discreteAngle = getDiscreteAngle(calculatedAngle);
    const discreteAspect = getDiscreteAspect(calculatedAspect);
    const correctionFactorObject = currentInterpretation[Math.abs(discreteAspect).toString()];
    const correctionFactor = correctionFactorObject[discreteAngle.toString()];

    const rawCurrent = totalBricksOfMergedRoof * calculatedBrickCurrent / 2 * correctionFactor;
    const rawInverterCount = rawCurrent / maxCurrent;

    const mergedRoof = new Map(Object.entries(
        {
            mergedRoofs: combineList,
            brickCount: totalBricksOfMergedRoof,
            angle: calculatedAngle,
            aspect: calculatedAspect,
            brickCurrent: calculatedBrickCurrent,
            discreteAngle: discreteAngle,
            discreteAspect: discreteAspect,
            rawCurrent: rawCurrent,
            rawInverterCount: rawInverterCount
        }
        ));
    return mergedRoof;
}

function findPotentialMergePartners(roofs) {
    var potentialMergePartners = new Array();
    var doublettes = new Array();
    var triplets = new Array();
    var tripletsFlat = new Array();
    for (var i = 0; i < roofs.length; i++) {
        for (var j = 0; j < roofs.length; j++) {
            if (calculateDistanceOnCirle(roofs[i].get("aspect"), roofs[j].get("aspect")) <= 60 && i != j) {
                if(!potentialMergePartners.find(element => (element[0] == j && element[1] == i) || (element[0] == i && element[1] == j))) {
                    potentialMergePartners.push([i,j]);
                }
            }
        }
    }
    console.log("Find potential triplets")
    for (var i = 0; i < roofs.length; i++) {
        const potentialTriplets = potentialMergePartners.filter(element => element[0] == i);
        if(potentialTriplets.length >= 2) {
            for (var j = 0; j < potentialTriplets.length - 1; j++) {
                var potentialTripletTuple = [potentialTriplets[j][1], potentialTriplets[j + 1][1]];
                if(potentialMergePartners.find(element => (element[0] == potentialTripletTuple[0] && element[1] == potentialTripletTuple[1]))) {
                    tripletsFlat.push(i,potentialTripletTuple[0],potentialTripletTuple[1]);
                    triplets.push([i,potentialTripletTuple[0],potentialTripletTuple[1]]);
                }
            }
        }
    }
    for (var i = 0; i < potentialMergePartners.length; i++) {
        if(!tripletsFlat.find(element => element == potentialMergePartners[i][0])) {
            doublettes.push(potentialMergePartners[i]);
        }
    }
    console.log(doublettes);
    console.log(triplets);
    return [...doublettes, ...triplets];
}

function calculateRoofPair(firstRoofIndex, secondRoofIndex, roofs, maxCurrent, roofCombinerMatrix) {
    console.log("Roof indexes are " + firstRoofIndex + " and " + secondRoofIndex + ".");
    console.log(roofs);
    const addedCurrents = roofs[firstRoofIndex].get("rawCurrent") + roofs[secondRoofIndex].get("rawCurrent");
    var combinedCurrent = 0;
    console.log("Distance on cirle: " + calculateDistanceOnCirle(roofs[firstRoofIndex].get("aspect"), roofs[secondRoofIndex].get("aspect")) + "°.");
    if (getDiscreteAspect(calculateDistanceOnCirle(roofs[firstRoofIndex].get("aspect"), roofs[secondRoofIndex].get("aspect"))) == 180) {
        console.log("Anwendung der 180°-Regel.");
        const combinerFactors = [];
        var combinerFactorObject = roofCombinerMatrix[roofs[firstRoofIndex].get("discreteAngle").toString()];
        console.log("Discrete Angle of first roof is: " + roofs[firstRoofIndex].get("discreteAngle"))
        var combinerFactor = combinerFactorObject['180'];
        combinerFactors.push(combinerFactor);
        combinerFactorObject = roofCombinerMatrix[roofs[secondRoofIndex].get("discreteAngle").toString()];
        console.log("Discrete Angle of second roof is: " + roofs[secondRoofIndex].get("discreteAngle"))
        combinerFactor = combinerFactorObject['180'];
        combinerFactors.push(combinerFactor);
        combinedCurrent = Math.max(...combinerFactors) * addedCurrents;
    } else if (getDiscreteAspect(calculateDistanceOnCirle(roofs[firstRoofIndex].get("aspect"), roofs[secondRoofIndex].get("aspect"))) == 135) {
        console.log("Anwendung der 135°-Regel.");
        const combinerFactors = [];
        var combinerFactorObject = roofCombinerMatrix[roofs[firstRoofIndex].get("discreteAngle").toString()];
        var combinerFactor = combinerFactorObject['135'];
        combinerFactors.push(combinerFactor);
        combinerFactorObject = roofCombinerMatrix[roofs[secondRoofIndex].get("discreteAngle").toString()];
        combinerFactor = combinerFactorObject['135'];
        combinerFactors.push(combinerFactor);
        combinedCurrent = Math.max(...combinerFactors) * addedCurrents;
    } else {
        console.log("Keine Anwendung von 135°-Regel oder 180°-Regel.");
        combinedCurrent = addedCurrents;
    }
    console.log("Combined current: " + combinedCurrent + ", rawCurrent erster Partner: " + roofs[firstRoofIndex].get("rawCurrent") + ", rawCurrent zweiter Partner: " + roofs[secondRoofIndex].get("rawCurrent"));
    if (combinedCurrent > roofs[firstRoofIndex].get("rawCurrent") && combinedCurrent > roofs[secondRoofIndex].get("rawCurrent")) {
        console.log("Returning inverterCount for combinedCurrent: " + Math.ceil(combinedCurrent / maxCurrent));
        return Math.ceil(combinedCurrent / maxCurrent - 0.1);
    } else {
        console.log("Returning inverterCount as addedCurrent: " + (Math.ceil(roofs[firstRoofIndex].get("rawCurrent") / maxCurrent) + Math.ceil(roofs[secondRoofIndex].get("rawCurrent") / maxCurrent)));
        return Math.ceil(roofs[firstRoofIndex].get("rawCurrent") / maxCurrent - 0.1) + Math.ceil(roofs[secondRoofIndex].get("rawCurrent") / maxCurrent - 0.1);
    }
}

export function getProductById(arr, id) {
    for (var i=0, iLen=arr.length; i<iLen; i++) {
        if (arr[i].id == id) return arr[i];
    }
}

function createPermutationMatrix(depth) {
    var result = []

    // currentSize should be invoked with the array size
    function permutation(arr, currentSize) {
        if (currentSize == 1) { // recursion base-case (end)
            result.push(arr.join(""));
            return;
        }
    
        for (let i = 0; i < currentSize; i++){
            permutation(arr, currentSize - 1);
            if (currentSize % 2 == 1) {
                let temp = arr[0];
                arr[0] = arr[currentSize - 1];
                arr[currentSize - 1] = temp;
            } else {
                let temp = arr[i];
                arr[i] = arr[currentSize - 1];
                arr[currentSize - 1] = temp;
            }
        }
    }
    let array = new Array();
    if(depth == 3) {
        array = ["0","1","2"];
    } else if (depth === 4) {
        array = ["0","1","2","3"];
    } else if (depth === 5) {
        console.log("array depth = 5")
        array = ["0","1","2","3","4"];
    } else if (depth === 6) {
        array = ["0","1","2","3","4","5"];
    }
    permutation(array, array.length);
    return result;
}

export function calculateInverterCount(availableProducts, technicalUserInfo, roofs, siteBrickCount) {
    if (siteBrickCount <= 500) {
        console.log("Returning one inverter because of less or equal 500 bricks");
        return 1;
    }

    const maxCurrent = 55;
    var currentInterpretation = new Object();
    var roofCombinerMatrix = new Object();

    console.log("Virtual Product description: " + JSON.parse(availableProducts.virtualProducts[0].description));
    for (var virtualProduct of availableProducts.virtualProducts) {
        if (virtualProduct.id == "261") {
            currentInterpretation = JSON.parse(virtualProduct.description);
        } else if (virtualProduct.id == "262") {
            roofCombinerMatrix = JSON.parse(virtualProduct.description);
        }
    }

    var i = 0;
    for (var roof of roofs) {
        const discreteAngle = getDiscreteAngle(technicalUserInfo.get("roofs")[i].get("angle"));
        console.log("Called discrete angle: " + discreteAngle);
        const discreteAspect = getDiscreteAspect(technicalUserInfo.get("roofs")[i].get("orientation"));
        console.log("Called discrete aspect: " + discreteAspect);
        const correctionFactorObject = currentInterpretation[Math.abs(discreteAspect).toString()];
        const correctionFactor = correctionFactorObject[discreteAngle.toString()];

        roof.set("brickCurrent", parseFloat(technicalUserInfo.get("brick").get("current")));
        roof.set("angle", technicalUserInfo.get("roofs")[i].get("angle"));
        roof.set("aspect", technicalUserInfo.get("roofs")[i].get("orientation"));
        roof.set("discreteAspect", discreteAspect);
        roof.set("discreteAngle", discreteAngle);

        console.log("Brick Count for roof " + i + ": " + roof.get("brickCount"));
        console.log("Brick Current for roof " + i + ": " + technicalUserInfo.get("brick").get("current"));
        const rawCurrent = parseFloat(roof.get("brickCount")) * parseFloat(technicalUserInfo.get("brick").get("current")) / 2 * correctionFactor;
        roof.set("rawCurrent", rawCurrent);
        const rawInverterCount = rawCurrent / maxCurrent;
        console.log("Raw Inverter Count for roof " + i + ": " + rawInverterCount);
        roof.set("rawInverterCount", rawInverterCount);
        i += 1;
    }

    var mergedRoofs = new Array();
    var mergePartners = findPotentialMergePartners(roofs);
    for (var mergePartner of mergePartners) {
        const mergedRoof = createMergedRoof(mergePartner, roofs, currentInterpretation, maxCurrent);
        mergedRoofs.push(mergedRoof);
    }
    console.log(mergePartners);

    if(roofs.length == 1) {
        console.log("Returning inverterCount: " + Math.ceil(roofs[0].get("rawInverterCount")));
        return Math.ceil(roofs[0].get("rawInverterCount") - 0.1);
    } else if (roofs.length == 2) {
        return calculateRoofPair(0,1,roofs, maxCurrent, roofCombinerMatrix);
    } else if (roofs.length == 3) {
        const pvwCount = new Array();
        pvwCount.push(calculateRoofPair(0, 1 ,roofs, maxCurrent, roofCombinerMatrix) + Math.ceil(roofs[2].get("rawInverterCount") - 0.1));
        pvwCount.push(calculateRoofPair(0, 2 ,roofs, maxCurrent, roofCombinerMatrix) + Math.ceil(roofs[1].get("rawInverterCount") - 0.1));
        pvwCount.push(calculateRoofPair(1, 2 ,roofs, maxCurrent, roofCombinerMatrix) + Math.ceil(roofs[0].get("rawInverterCount") - 0.1));
        if(mergePartners.length > 0) {
            for(var i = 0; i < mergePartners.length; i++) {
                var roofList = [0,1,2];
                if(mergePartners[i].length === 3) {
                    break;
                }
                var indexToDelete1 = roofList.findIndex(element => element == mergePartners[i][0]);
                roofList.splice(indexToDelete1, 1);
                var indexToDelete2 = roofList.findIndex(element => element == mergePartners[i][1]);
                roofList.splice(indexToDelete2, 1);
                pvwCount.push(calculateRoofPair([...roofList], i + 3 , [...roofs, ...mergedRoofs], maxCurrent, roofCombinerMatrix));
            }
        }
        console.log(pvwCount);
        return Math.min(...pvwCount);
    } else if (roofs.length == 4) {
        const pvwCount = new Array();
        pvwCount.push(calculateRoofPair(0,1,roofs, maxCurrent, roofCombinerMatrix) + calculateRoofPair(2,3,roofs, maxCurrent, roofCombinerMatrix));
        pvwCount.push(calculateRoofPair(0,2,roofs, maxCurrent, roofCombinerMatrix) + calculateRoofPair(1,3,roofs, maxCurrent, roofCombinerMatrix));
        pvwCount.push(calculateRoofPair(0,3,roofs, maxCurrent, roofCombinerMatrix) + calculateRoofPair(1,2,roofs, maxCurrent, roofCombinerMatrix));
        if(mergePartners.length > 0) {
            for(var i = 0; i < mergePartners.length; i++) {
                var roofList = [0,1,2,3];
                if(mergePartners[i].length === 3) {
                    var indexToDelete1 = roofList.findIndex(element => element == mergePartners[i][0]);
                    roofList.splice(indexToDelete1, 1);
                    var indexToDelete2 = roofList.findIndex(element => element == mergePartners[i][1]);
                    roofList.splice(indexToDelete2, 1);
                    var indexToDelete3 = roofList.findIndex(element => element == mergePartners[i][2]);
                    roofList.splice(indexToDelete3, 1);
                    console.log(mergedRoofs);
                    pvwCount.push(calculateRoofPair([...roofList], i + 4 , [...roofs, ...mergedRoofs], maxCurrent, roofCombinerMatrix));
                } else if (mergePartners[i].length === 2) {
                    var indexToDelete1 = roofList.findIndex(element => element == mergePartners[i][0]);
                    roofList.splice(indexToDelete1, 1);
                    var indexToDelete2 = roofList.findIndex(element => element == mergePartners[i][1]);
                    roofList.splice(indexToDelete2, 1);
                    pvwCount.push(calculateRoofPair(roofList[0], roofList[1] ,roofs, maxCurrent, roofCombinerMatrix) + Math.ceil(mergedRoofs[i].get("rawInverterCount") - 0.1));
                    pvwCount.push(calculateRoofPair(roofList[0], 4 ,[...roofs, ...mergedRoofs], maxCurrent, roofCombinerMatrix) + Math.ceil(roofs[roofList[1]].get("rawInverterCount") - 0.1));
                    pvwCount.push(calculateRoofPair(roofList[1], 4 ,[...roofs, ...mergedRoofs], maxCurrent, roofCombinerMatrix) + Math.ceil(roofs[roofList[0]].get("rawInverterCount") - 0.1));
                }
            }
        }
        console.log(pvwCount);
        return Math.min(...pvwCount);
    } else if (roofs.length == 5) {
        const pvwCount = new Array();
        const permutationMatrix = createPermutationMatrix(5);
        for(var i = 0; i < permutationMatrix.length; i++) {
            var activePermutation = permutationMatrix[i];
            console.log("Permutation round number: " + i + " with last substring: " + activePermutation.substring(4,5));
            pvwCount.push(calculateRoofPair(
                parseInt(activePermutation.substring(0,1)),
                parseInt(activePermutation.substring(1,2)),
                roofs,
                maxCurrent,
                roofCombinerMatrix) + calculateRoofPair(
                parseInt(activePermutation.substring(2,3)),
                parseInt(activePermutation.substring(3,4)),
                roofs,
                maxCurrent,
                roofCombinerMatrix) + Math.ceil(
                roofs[parseInt(activePermutation.substring(4,5))].get("rawInverterCount")
                 - 0.1));
        }
        console.log(pvwCount);
        return Math.min(...pvwCount);
    } else if (roofs.length == 6) {
        const pvwCount = new Array();
        const permutationMatrix = createPermutationMatrix(6);
        for(var i = 0; i < permutationMatrix.length; i++) {
            var activePermutation = permutationMatrix[i];
            console.log("Permutation round number: " + i + " with last substring: " + activePermutation.substring(4,5));
            pvwCount.push(calculateRoofPair(
                parseInt(activePermutation.substring(0,1)),
                parseInt(activePermutation.substring(1,2)),
                roofs,
                maxCurrent,
                roofCombinerMatrix) + calculateRoofPair(
                parseInt(activePermutation.substring(2,3)),
                parseInt(activePermutation.substring(3,4)),
                roofs,
                maxCurrent,
                roofCombinerMatrix) + calculateRoofPair(
                parseInt(activePermutation.substring(4,5)),
                parseInt(activePermutation.substring(5,6)),
                roofs,
                maxCurrent,
                roofCombinerMatrix));
        }
        console.log(pvwCount);
        console.log("Return pvwCound: " + Math.min(...pvwCount));
        return Math.min(...pvwCount);
    }
    var aggregatedInverterCount = 0;
    for (let z = 0; z < roofs.length; z++) {
        aggregatedInverterCount += Math.ceil(roofs[z].get("rawInverterCount") - 0.1);
    }
    console.log("Returning AggregatedInverterCount: " + aggregatedInverterCount);
    return aggregatedInverterCount;
}