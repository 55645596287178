import React, { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl';
import { Select, InputLabel, MenuItem } from '@mui/material';
import { translate } from '../../Helper/Dictionary';

export default function BOMSelector(props: any) {
    const [selectedBOMProfile, setSelectedBOMProfile] = React.useState(0);

    useEffect(() => {
        if(props.technicalUserInput.has("bomProfile")) {
            const bomProfile = props.technicalUserInput.get("bomProfile");
            setSelectedBOMProfile( bomProfile );
        } else {
            updateBOMProfileForTechnicalUserInput(0, false);
        }
    }, [])

    const updateTechnicalUserInput = (k: any, v: any) => {
        props.setTechnicalUserInput(new Map(props.technicalUserInput.set(k,v)));
    }

    const updateTechnicalUserInputFromBOMSelector = (e: any): void => {
        updateBOMProfileForTechnicalUserInput(e.target.value, true);
    }

    const updateBOMProfileForTechnicalUserInput = (value: any, updateSelector: boolean): void => {
        for(var bomProfile of [0,1]) {
            console.log("Compare value: " + value + " with BOMProfile: " + bomProfile);
            if( value == bomProfile ) {
                console.log("BOMprofile matched!");
                updateTechnicalUserInput("bomProfile", value.toString());

                if (updateSelector) {
                    setSelectedBOMProfile( value );
                }
            }
        }
    };

    return (
        <FormControl sx={{ my: 2 }} fullWidth>
            <InputLabel id="bomSelectorInputLabel">{translate('labelTypeOfBOM', props.guiLanguage)}</InputLabel>
            <Select
                labelId="bomSelectorLabel"
                id="bomSelector"
                value={selectedBOMProfile}
                label="Stücklistenart"
                onChange={updateTechnicalUserInputFromBOMSelector}
            >
                <MenuItem value={0}>{translate('optionNormalBOM', props.guiLanguage)}</MenuItem>
                { (props.tenantsAvailable.get('tenants').profile === 'externalBOMOnly') ? (null) : ( <MenuItem value={1}>{translate('optionElectroBOM', props.guiLanguage)}</MenuItem> ) }
            </Select>
        </FormControl>
    );
  }