import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { queryMiddleware } from "../../Helper/queryMiddleware";
import { loginRequest } from "../../authConfig";
import Card from '@mui/material/Card';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import GoogleMap from './GoogleMap';
import LocationServiceSelector from './LocationServiceSelector';
import { translate } from '../../Helper/Dictionary';

export default function MapsPane(props: any) {
  const {instance, accounts} = useMsal();

  const [clicks, setClicks] = React.useState<google.maps.LatLng[]>([]);
  const [zoom, setZoom] = React.useState(3);

  const [checked, setChecked] = React.useState(true);

  const [locationService, setLocationService] = React.useState("geocode");

  useEffect(() => {
    if(props.technicalUserInput.has("printMap")) {
      if(props.technicalUserInput.get("printMap") == true) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    } else {
      props.setTechnicalUserInput(new Map(props.technicalUserInput.set("printMap", true)));
    }

    updateMaps();
  }, [])

  useEffect(() => {
    console.log("Location service changed, invoke update of the map!");
    updateMaps();
  }, [locationService])

  const updateMaps = (): void => {
    console.log("Initial coordinates are: " + props.technicalUserInput.get("coordinates"));

    if(props.technicalUserInput.has("locationService")) {
      if(props.technicalUserInput.get("locationService") == "coordinates") {
        setLocationService("coordinates");
        console.log("Coordinate Location Service Lat is: " + props.selectedDeal.get("latitude"));
        updateTechnicalUserInput("coordinates", {lat: parseFloat(props.selectedDeal.get("latitude")), lng: parseFloat(props.selectedDeal.get("longitude"))});
        setZoom(20);
        return;
      }
    }

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    const url   = `/api/v1/geocode`;
    const body  = {
      street: props.selectedDeal.get("street") + " " + props.selectedDeal.get("streetNumber"),
      zip: props.selectedDeal.get("zip"),
      city: props.selectedDeal.get("city"),
      country: props.selectedDeal.get("country")
    };

    instance.acquireTokenSilent(request).then((response) => {
      queryMiddleware(url, body, "POST", response.accessToken)
        .then((data) => updateCenter(data.data.data));
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        queryMiddleware(url, body, "POST", response.accessToken)
          .then((data) => updateCenter(data.data.data));
      });
    });
  };

  const updateTechnicalUserInput = (k: any, v: google.maps.LatLngLiteral) => {
    props.setTechnicalUserInput(new Map(props.technicalUserInput.set(k,v)));
  }

  const updateCenter = (geoLocation: any): void => {
    console.log(geoLocation);
    updateTechnicalUserInput("coordinates", {lat: geoLocation.results[0].geometry.location.lat, lng: geoLocation.results[0].geometry.location.lng});
    setZoom(20);
  };
 
  const onClick = (e: google.maps.MapMouseEvent) => {
    setClicks([...clicks, e.latLng!]);
  };

  const onIdle = (m: google.maps.Map) => {
    console.log("onIdle");
    console.log(m.getZoom());
    setZoom(m.getZoom()!);
    //setCenter(m.getCenter()!.toJSON());
  };

  const handleCheckboxChange = (e: any) => {
    if(checked) {
      setChecked(false);
      props.setTechnicalUserInput(new Map(props.technicalUserInput.set("printMap", false)));
    } else {
      setChecked(true);
      props.setTechnicalUserInput(new Map(props.technicalUserInput.set("printMap", true)));
    }
  };

  return (
    <Card sx={{ minWidth: 275, minHeight: 500 }}>
      <div style={{ display: "flex", height: "500px" }}>
        <Wrapper apiKey={"AIzaSyD9HMeRKEEAbMcfExGldcKr6V9xrS7nJrY"}>
          <GoogleMap
            onClick={onClick}
            onIdle={onIdle}
            options={{zoom: zoom, center: props.technicalUserInput.get("coordinates"), mapTypeId: 'satellite'}}
            style={{ flexGrow: "1", height: "100%" }}
          >
          </GoogleMap>
        </Wrapper>
      </div>
      <FormGroup>
        <FormControlLabel control={<Switch checked={checked} onClick={handleCheckboxChange} inputProps={{ 'aria-label': 'controlled' }}/>} label={translate('labelShowMapsInPDF', props.guiLanguage)} />
      </FormGroup>
      <LocationServiceSelector setZoom = {setZoom} selectedDeal = {props.selectedDeal} technicalUserInput = {props.technicalUserInput} setTechnicalUserInput = {props.setTechnicalUserInput} locationService = {locationService} setLocationService = {setLocationService} guiLanguage = {props.guiLanguage}></LocationServiceSelector>
    </Card>
  );
}