const getProductDescriptionForLanguage = (product, languageTag) => {
    var productObject = product;
    if (product instanceof Map) {
        productObject = Object.fromEntries(product);
    }
    const language = languageTag.substring(0, 2);
    if (productObject["1c99d55f805a7fc510b7cb1f85b6ff4cab781ee6"] != ( undefined || null ) ) {
        const intlDescription = JSON.parse(productObject["1c99d55f805a7fc510b7cb1f85b6ff4cab781ee6"]);		
        if (Array.isArray(intlDescription)) {
            const languageDescription = intlDescription.filter(
                (template) => template.language === language,
            )[0];
            if (languageDescription instanceof Object) {
                if (languageDescription.hasOwnProperty('text')) {
                    return languageDescription.text;
                  }
            }
        }
    }
    // fall back to the standard description field
    return productObject.description;
};
  
  export { getProductDescriptionForLanguage };