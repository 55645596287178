var Mustache = require('mustache');
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

require('../TT_Firs_Neue_400');
require('../TT_Firs_Neue_500');

import { translate } from '../../../../Helper/Dictionary';
import { getElectricComponentsBOMs } from '../ElectricComponentsHelperFunctions.js';
import pdfAddHeader from './Helper/PDFAddHeader';
import { ceilToNext500 } from "../PricesHelperFunctions";
import { getValueAddedTax } from "../PricelistMap";
import { autarqLogoGreyBG } from '../Assets/autarqLogo';
import { getTemplate } from "../MiscHelperFunctions";
import pdfAddDraftWatermark from './Helper/PDFAddDraftWatermark';

export default function addElectroSection(doc, agentUserInput, availableProducts, processedTechnicalInformation, selectedDeal, technicalUserInput, draft) {
  if (technicalUserInput.get("electroComponentsProfile") != 0) {
    const tenantName = agentUserInput.get('tenant');
    const typeOfCustomer = agentUserInput.get('typeOfCustomer');
    const salesCountry = agentUserInput.get('salesCountry');

    doc.addPage();
    pdfAddHeader(doc, agentUserInput, translate("labelBOM", agentUserInput.get("offerLanguage")), translate("labelCraftElectrical", agentUserInput.get("offerLanguage")));
    
    doc.setFontSize(10);
    doc.setFont('TT_Firs_Neue_Regular', 'normal');

    const electricComponentsBOMProperties = new Object();
    const electricComponentsBOMs = getElectricComponentsBOMs(availableProducts, processedTechnicalInformation.get("siteTotalPowerRating"), technicalUserInput.get("bomProfile"), tenantName, typeOfCustomer, electricComponentsBOMProperties, salesCountry, agentUserInput.get("offerLanguage"));

    var selectedBatteryModes = new Array();
    let electroComponentsExtrapolatedTableHeader = "";
    let electroComponentsExtrapolatedTableBody = "";

    doc.setFont('TT_Firs_Neue', 'normal');
    if (technicalUserInput.get("electroComponentsProfile") == 0) {
    // do nothing
    } else if (technicalUserInput.get("electroComponentsProfile") == 1) {
      selectedBatteryModes = ["withoutBattery"];
      electroComponentsExtrapolatedTableHeader = translate("labelWithoutBattery", agentUserInput.get("offerLanguage"));
      electroComponentsExtrapolatedTableBody = [translate("textPackageInverterOnly", agentUserInput.get("offerLanguage"))]; 
    } else if (technicalUserInput.get("electroComponentsProfile") == 2) {
      selectedBatteryModes = ["withBattery"];
      electroComponentsExtrapolatedTableHeader = translate("labelWithBattery", agentUserInput.get("offerLanguage"));
      electroComponentsExtrapolatedTableBody = [translate("textPackageInverterAndBattery", agentUserInput.get("offerLanguage"))]
    } else if (technicalUserInput.get("electroComponentsProfile") == 3) {
      selectedBatteryModes = ["withEmergencyPower"];
      electroComponentsExtrapolatedTableHeader = translate("labelWithEmergencyPower", agentUserInput.get("offerLanguage"));
      electroComponentsExtrapolatedTableBody = [translate("textPackageInverterAndBattery", agentUserInput.get("offerLanguage"))]
    }

    var head;
    var body;
    var columnStyles;
    var [index, startY] = [0, 55];

    
    for (var selectedBatteryMode of selectedBatteryModes) {
      if (electricComponentsBOMs[selectedBatteryMode]["extrapolated"]) {
        head = [[`${processedTechnicalInformation.get("siteTotalPowerRating").toFixed(0).toString()} ${translate("labelkWp", agentUserInput.get("offerLanguage"))} ${translate("labelElectroPackage", agentUserInput.get("offerLanguage"))} ${electroComponentsExtrapolatedTableHeader}`]];
        body = [electroComponentsExtrapolatedTableBody];
        if (technicalUserInput.get("bomProfile") == 1) {
          body.push([translate("labelUsualMarketPrice", agentUserInput.get("offerLanguage")) + ' EUR ' + ceilToNext500(electricComponentsBOMProperties[selectedBatteryMode]["sum"] * (1 + getValueAddedTax(salesCountry, typeOfCustomer))).toLocaleString('de-DE') + ' ( ' + translate("labelIncludesEuro", agentUserInput.get("offerLanguage")) + ' ' + (ceilToNext500(electricComponentsBOMProperties[selectedBatteryMode]["sum"] * (1 + getValueAddedTax(salesCountry, typeOfCustomer))) * (1 - 1/(1 + getValueAddedTax(salesCountry, typeOfCustomer)))).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' MwSt.)']);
        }
      } else {
        head = [(technicalUserInput.get("bomProfile") == 1 && typeOfCustomer != "consumer") ? [translate("labelTitlePosition", agentUserInput.get("offerLanguage")), translate("labelTitleCount", agentUserInput.get("offerLanguage")), translate("labelTitleNumber", agentUserInput.get("offerLanguage")), translate("labelTitleDescription", agentUserInput.get("offerLanguage")), translate("labelTitlePrice", agentUserInput.get("offerLanguage")), translate("labelTitleTotal", agentUserInput.get("offerLanguage"))] : [translate("labelTitlePosition", agentUserInput.get("offerLanguage")), translate("labelTitleCount", agentUserInput.get("offerLanguage")), translate("labelTitleNumber", agentUserInput.get("offerLanguage")), translate("labelTitleDescription", agentUserInput.get("offerLanguage"))]];
        body = electricComponentsBOMs[selectedBatteryMode]["bom"];
        columnStyles = {3: {detailsList: "yes"}};
      }

      doc.autoTable({
        startY: startY + 4,
        margin: { left: 20.8 },
        tableWidth: 168.4,
        styles: {font: "TT_Firs_Neue"},
        head: head,
        body: body,
        columnStyles: columnStyles,
        halign: "right",
        rowPageBreak: "avoid",
      });
      index += 1;
    }

    var newPageAfterElectroComponentsTable = false;
    if (doc.lastAutoTable.finalY > 230) {
      newPageAfterElectroComponentsTable = true;
      doc.addPage();

      doc.setTextColor(16, 25, 32);
      doc.setFillColor(235, 236, 230);
      doc.rect(0, 0, 210, 45, 'F');

      doc.addImage(autarqLogoGreyBG, 'jpeg', 20.8, 8.82, 29.6, 7.1);

      doc.setTextColor(16, 25, 32);
      doc.setFontSize(18);
      doc.setFont('TT_Firs_Neue', 'bold');
      doc.text('Bedingungen', 20.8, 24.7, { baseline: 'top' });
  
      doc.setTextColor(104, 109, 111);
      doc.text('Gewerk Elektro', 20.8, 32.1, { baseline: 'top' });

      if(agentUserInput.get(tenantName)["logoOnAdditionalPages"]) {
        const logoMetadata = agentUserInput.get(tenantName)["logoMetadata"];
        doc.addImage(getTemplate(agentUserInput.get(tenantName)["logo"], 'logo', 'bg', 'grey'), 'jpeg', ( 210 - ( 20.8 + 31.5 ) ), 8.82 + 0.5 * (7.1 - 31.5*logoMetadata["height"]/logoMetadata["width"]), 31.5, 31.5*logoMetadata["height"]/logoMetadata["width"]);
      }
      if (draft === true) {
        pdfAddDraftWatermark(doc, agentUserInput);
      }
    }

    doc.setTextColor(16, 25, 32);
    doc.setFontSize(10);
    doc.setFont('TT_Firs_Neue', 'normal');
    doc.text(translate("labelDisclaimerElectro", agentUserInput.get("offerLanguage"))
    , 20.8, newPageAfterElectroComponentsTable ? 55 : ( doc.lastAutoTable.finalY + 15 ), {maxWidth: 168.4});

    if (draft === true) {
      pdfAddDraftWatermark(doc, agentUserInput);
    }
  }

};