import { translate } from '../../../Helper/Dictionary';

const useProfiles = [
    ["0.095","0.134"],
    ["0.083","0.113"],
    ["0.084","0.094"],
    ["0.081","0.061"],
    ["0.078","0.048"],
    ["0.075","0.0465"],
    ["0.078","0.048"],
    ["0.078","0.048"],
    ["0.08","0.06"],
    ["0.084","0.094"],
    ["0.088","0.118"],
    ["0.096","0.1355"]
  ];

function getDaysForMonth(month, year) {
    if(month != 2) {
        if(month == 9 ||
            month == 4 ||
            month == 6 ||
            month == 11) {
                return 30;
            } else {
                return 31;
            }
    } else {
        return (year % 4) == "" && (year % 100) !="" ? 29 : 28;
    }
}

function getDailyUseForProfile(annualPowerRequirement, profileIndex, siteOutputDaily, month, ratio) {
    const dailyUseForProfile = annualPowerRequirement * useProfiles[month][profileIndex] / getDaysForMonth(month, 2022);

    if(!ratio) {
        return dailyUseForProfile;
    } else {
        return (siteOutputDaily[month] / dailyUseForProfile * 100);
    }
}

export function getCoverRatioTable(annualPowerRequirement, profileIndex, siteOutputDaily, asNumbers, offerLanguage) {
    const coverRatioTable = new Array();
    var cumulatedRatio = 0;
    var monthlyRatio = 0;
    var ratios = [];
    for (var month = 0; month < 12; month++) {
        monthlyRatio = getDailyUseForProfile(annualPowerRequirement, profileIndex, siteOutputDaily, month, true);
        ratios[month] = monthlyRatio;
        cumulatedRatio += monthlyRatio;
        coverRatioTable.push(
            [
                asNumbers ?  getDailyUseForProfile(annualPowerRequirement, profileIndex, siteOutputDaily, month, false) : getDailyUseForProfile(annualPowerRequirement, profileIndex, siteOutputDaily, month, false).toFixed(1).toString(),
                '',
                asNumbers ? monthlyRatio : monthlyRatio.toFixed(0).toString() + " %"
            ]);
    }
    asNumbers ? coverRatioTable.push([0, 0, (cumulatedRatio / 12)]) : coverRatioTable.push([translate("labelAverage", offerLanguage), (cumulatedRatio / 12).toFixed(0).toString() + " %"]);
    return coverRatioTable;
}
