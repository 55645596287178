import * as React from 'react';
import Button from '@mui/material/Button';
import { translate } from '../../Helper/Dictionary.js';

import { getTemplate } from './Resources/MiscHelperFunctions';

import generatePDF from './GeneratePDF.js';

export default function DownloadPreviewPDF(props) {
  const createPDF = () => {
    const date = new Date(Date.now());

    const doc = generatePDF(
      props.selectedDeal,
      props.availableProducts,
      props.technicalUserInput,
      props.processedTechnicalInformation,
      props.agentUserInput,
      props.tenantsAvailable,
      true
    );

    doc.save(`${getTemplate(props.agentUserInput.get(props.agentUserInput.get('tenant')).dictionary, "title", 'language', props.agentUserInput.get("offerLanguage"))}_${props.selectedDeal.get("title")}_${props.selectedDeal.get("id")}_${props.agentUserInput.get('tenant')}_${props.agentUserInput.get("typeOfCustomer")}_${date.toISOString().split('T')[0]}.pdf`);
    props.setOffer(doc);
  };

  return (
    <Button disabled={props.pdfButtonDisabled} onClick={createPDF} sx={{ mt: 3, ml: 1 }}>
      {translate('buttonDownloadPreviewOffer', props.guiLanguage)}
    </Button>
  );
}
