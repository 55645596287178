import React, { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl';
import { Select, InputLabel, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { translate } from '../../Helper/Dictionary';

export default function ElectroComponentsSelector(props: any) {
    const [selectedElectroComponentsProfile, setSelectedElectroComponentsProfile] = React.useState(0);
    const [warningState, setWarningState] = React.useState(false);

    useEffect(() => {
        if(props.technicalUserInput.has("electroComponentsProfile")) {
            const electroComponentsProfile = props.technicalUserInput.get("electroComponentsProfile");
            setSelectedElectroComponentsProfile( electroComponentsProfile );
        } else {
            updateElectroComponentsProfileForTechnicalUserInput(0, false);
        }
    }, [])

    const updateTechnicalUserInput = (k: any, v: any) => {
        props.setTechnicalUserInput(new Map(props.technicalUserInput.set(k,v)));
    }

    const updateTechnicalUserInputFromElectroComponentsSelector = (e: any): void => {
        updateElectroComponentsProfileForTechnicalUserInput(e.target.value, true);
    }

    const updateElectroComponentsProfileForTechnicalUserInput = (value: any, updateSelector: boolean): void => {
        for(var electroComponentsProfile of [0,1,2,3]) {
            console.log("Compare value: " + value + " with ElectroComponentsProfile: " + electroComponentsProfile);
            if( value == electroComponentsProfile ) {
                console.log("Electro Components profile matched!");
                updateTechnicalUserInput("electroComponentsProfile", value.toString());

                if (updateSelector) {
                    setSelectedElectroComponentsProfile( value );
                }
            }
            if (value == 3) {
                setWarningState(true);
            }
        }
    };

    return (
        <React.Fragment>
            <FormControl sx={{ marginBottom: 2 }} fullWidth>
                <InputLabel id="emergencyPowerSelectorInputLabel">{translate('labelElectroComponent', props.guiLanguage)}</InputLabel>
                <Select
                    labelId="emrgencyPowerSelectorLabel"
                    id="emergencyPowerSelector"
                    value={selectedElectroComponentsProfile}
                    label="Notstrom"
                    onChange={updateTechnicalUserInputFromElectroComponentsSelector}
                >
                    <MenuItem value={0}>{translate('optionWithoutElectro', props.guiLanguage)}</MenuItem>
                    <MenuItem value={1}>{translate('optionWithElectro', props.guiLanguage)}</MenuItem>
                    <MenuItem value={2}>{translate('optionWithElectroAndBattery', props.guiLanguage)}</MenuItem>
                    <MenuItem value={3}>{translate('optionWithElectroAndBatteryAndEmergencyPower', props.guiLanguage)}</MenuItem>
                </Select>
            </FormControl>
            <Collapse in={warningState}>
                <Alert
                severity="warning"
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setWarningState(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {translate('textWarningDeliveryTime', props.guiLanguage)}
                </Alert>
            </Collapse>
        </React.Fragment>
        
    );
  }