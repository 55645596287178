import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import DealDataGrid from './DealSelectionComponents/DealDataGrid';
import { useMsal } from "@azure/msal-react";
import { queryMiddleware } from "../Helper/queryMiddleware";
import { loginRequest } from "../authConfig";
import { translate } from "../Helper/Dictionary";

interface Products {
  bricks?: any;
  virtualProducts?: any;
  miscProducts?: any;
}

export default function DealSelection(props: any) {
  const {instance, accounts} = useMsal();

  const [productsLoaded, setProductsLoaded] = useState(false);
  const [tenantsLoaded, setTenantsLoaded] = useState(false);

  useEffect(() => {	
    if(tenantsLoaded && productsLoaded && props.selectedDeal.has("id") && props.selectedDeal.has("street")) {
      if (props.selectedDeal.get("street") != null) {
        props.setCanGoOn(true);
      } else {
        props.setCanGoOn(false);
      }
    } else {
      props.setCanGoOn(false);
    }
  });

  useEffect(() => {
    if (!("bricks" in props.availableProducts)) {
      const request = {
        ...loginRequest,
        account: accounts[0]
      };

      const url   = `/api/v1/products`;
      const body  = {
      };

      instance.acquireTokenSilent(request).then((response) => {
        queryMiddleware(url, body, "POST", response.accessToken)
          .then((data) => handleJSONReceive(data.data))
          .then(() => queryTenantInfo())
		  .then(() => getContactTypes())
      }).catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          queryMiddleware(url, body, "POST", response.accessToken)
            .then((data) => handleJSONReceive(data.data))
            .then(() => queryTenantInfo())
			.then(() => getContactTypes());
        });
		
      });
    } else {
      setProductsLoaded(true);
      setTenantsLoaded(true);
    }
  }, []);

  const idsForVirtualProducts = [261,262,264,283,291,292,293];
  const idsForMiscProducts = [247,245,246,248,249,250,239,240,271,241,256,270,306,308,296,297,298,299,300,301,310,312,313,314,315];

  const handleTenantsReceive = (data: any): void => {
    props.setTenantsAvailable(new Map(Object.entries({tenants: data})));
    setTenantsLoaded(true);
  };

  const handlePipedriveInfos = (data: any): void => {
	props.sethandlePipedriveInfos(data);
	setTenantsLoaded(true);
  }

  const handleJSONReceive = (data: any): void => {
    const newProducts: Products = {};
    const newBricks = [];
    const newVirtualProducts = [];
    const newMiscProducts = [];
    for(var product of data) {
      if(product.category == "65" && product.selectable) {
        product.peakPower = product["78b951845df7b8fe6bde24ece4c582ede7aedfd5"];
        product.countPerSquareMeter = product["669c431566951a75566bfb05317f05d504663b74"];
        product.current = product["a554301883660fea25b6aeababf538004bf12d1c"];
        product.cablingSystem = product["37922924a01322cbe465ed7dd4a6d8d61339c2ce"];
        product.availableFor = product["eafb2f1fa84878da5203b51d24afda23275746c7"];
        newBricks.push(product);
      } else if(idsForMiscProducts.includes(product.id)) {
        newMiscProducts.push(product);
      } else if(product.category == "50" || product.category == "51" || product.category == "52") {
        newMiscProducts.push(product);
      } else if(idsForVirtualProducts.includes(product.id)) {
        newVirtualProducts.push(product);
      }
    }
    newProducts.bricks = newBricks;
    newProducts.virtualProducts = newVirtualProducts;
    newProducts.miscProducts = newMiscProducts;
    props.setAvailableProducts(newProducts);
	props.sethandlePipedriveInfos('data');

	setProductsLoaded(true);    
  };

  function queryTenantInfo(): any {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    const url   = `/api/v1/tenants`;
    const body  = {
    };

    instance.acquireTokenSilent(request).then((response) => {
      queryMiddleware(url, body, "POST", response.accessToken)
        .then((data) => handleTenantsReceive(data.data));
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        queryMiddleware(url, body, "POST", response.accessToken)
          .then((data) => handleTenantsReceive(data.data));
      });
    });
  }

  function getContactTypes() : any {
	const request = {
		...loginRequest,
		account: accounts[0]
	};

	const url = `/api/v1/get-pipedriveFields`;
	const body = {};

	instance.acquireTokenSilent(request).then((response) => {
		queryMiddleware(url, body, "POST", response.accessToken)
			.then((data) => handlePipedriveInfos (data.data));
		}).catch((e) => {
		instance.acquireTokenPopup(request).then((response) => {
			queryMiddleware(url, body, "POST", response.accessToken)
			.then((data) => handlePipedriveInfos (data.data));
		});
	});
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
	  	{translate('labelSelectDeal', props.guiLanguage)}
      </Typography>
      <DealDataGrid setSelectedDeal = {props.setSelectedDeal} handleDealData = {props.handleDealData} sethandleDealData = {props.sethandleDealData} guiLanguage = {props.guiLanguage}/>
	  <div style={{ height: 36.5, width: '100%' }}></div>
    </React.Fragment>
  );
}
