import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import PlanningSurvey from './components/PlanningSurvey'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import SignInPage from './components/SignInPage'

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.autarq.com">
        Planungstool
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function App() {
  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        <PlanningSurvey />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignInPage />
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
}
