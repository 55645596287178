import React, { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl';
import { Select, InputLabel, MenuItem } from '@mui/material';
import { translate } from '../../Helper/Dictionary';

export default function GUILanguageSelector(props: any) {
    const updateGUILanguageFromSelector = (e: any): void => {
        props.setGuiLanguage(e.target.value);
    }

    return (
        <FormControl sx={{ my: 2 }} fullWidth>
            <InputLabel id="guiLanguageSelectorLabel">{translate('labelSelectGUILanguage', props.guiLanguage)}</InputLabel>
            <Select
                labelId="GUILanguageSelectorLabel"
                id="GUILanguageSelector"
                value={props.guiLanguage}
                label={translate('labelSelectGUILanguage', props.guiLanguage)}
                onChange={updateGUILanguageFromSelector}
            >
                <MenuItem value={"de-DE"}>{translate('labelGerman', props.guiLanguage)}</MenuItem>
                <MenuItem value={"en-DE"}>{translate('labelEnglish', props.guiLanguage)}</MenuItem>
            </Select>
        </FormControl>
    );
}