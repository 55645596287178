import { translate } from '../../../../../Helper/Dictionary';

export default function pdfAddDraftWatermark(doc, agentUserInput) {
    doc.saveGraphicsState();
    doc.setGState(new doc.GState({opacity: 0.2}));
    doc.setFontSize(104);
    doc.setTextColor(16, 25, 32);
    doc.text(translate("labelDraft", agentUserInput.get("offerLanguage")), 68, 185, { baseline: 'middle', angle: 45 });
    doc.setFontSize(18);
    doc.setTextColor(16, 25, 32);
    doc.restoreGraphicsState();
}