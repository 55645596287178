var Mustache = require('mustache');
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

require('../TT_Firs_Neue_400');
require('../TT_Firs_Neue_500');

import { translate } from '../../../../Helper/Dictionary';
import { getCoverRatioTable } from '../DailyUseForProfile';
import pdfAddHeader from './Helper/PDFAddHeader';
import pdfAddDraftWatermark from './Helper/PDFAddDraftWatermark';

export default function addYieldEstimationSection(doc, agentUserInput, processedTechnicalInformation, selectedDeal, technicalUserInput, draft) {
  doc.addPage();
  pdfAddHeader(doc, agentUserInput, translate("labelYieldPrognosisAnd", agentUserInput.get("offerLanguage")), translate("labelElectricCoverage", agentUserInput.get("offerLanguage")));

  doc.setTextColor(16, 25, 32);
  doc.setFontSize(10);
  doc.setFont('TT_Firs_Neue', 'normal');
  doc.text(
    Mustache.render(translate("textYieldForecastDisclaimer", agentUserInput.get("offerLanguage")), {annualPowerRequirement: parseInt(technicalUserInput.get("annualPowerRequirement")).toLocaleString('de-DE')}),
    20.8,
    55,
    {maxWidth: 165}
  );

  doc.autoTable({
    startY: 85,
    margin: { left: 20.8 },
    tableWidth: 80,
    styles: {font: "TT_Firs_Neue"},
    head: [[translate("labelMonth", agentUserInput.get("offerLanguage")), translate("labelYieldForecastTableTitleDaily", agentUserInput.get("offerLanguage")), translate("labelYieldForecastTableTitleMonthly", agentUserInput.get("offerLanguage")) + '\n']],
    body: [
      [translate("labelJanuary", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("siteOutputDaily")[0].toLocaleString('de-DE'), processedTechnicalInformation.get("siteOutputMonthly")[0].toLocaleString('de-DE')],
      [translate("labelFebruary", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("siteOutputDaily")[1].toLocaleString('de-DE'), processedTechnicalInformation.get("siteOutputMonthly")[1].toLocaleString('de-DE')],
      [translate("labelMarch", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("siteOutputDaily")[2].toLocaleString('de-DE'), processedTechnicalInformation.get("siteOutputMonthly")[2].toLocaleString('de-DE')],
      [translate("labelApril", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("siteOutputDaily")[3].toLocaleString('de-DE'), processedTechnicalInformation.get("siteOutputMonthly")[3].toLocaleString('de-DE')],
      [translate("labelMay", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("siteOutputDaily")[4].toLocaleString('de-DE'), processedTechnicalInformation.get("siteOutputMonthly")[4].toLocaleString('de-DE')],
      [translate("labelJune", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("siteOutputDaily")[5].toLocaleString('de-DE'), processedTechnicalInformation.get("siteOutputMonthly")[5].toLocaleString('de-DE')],
      [translate("labelJuly", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("siteOutputDaily")[6].toLocaleString('de-DE'), processedTechnicalInformation.get("siteOutputMonthly")[6].toLocaleString('de-DE')],
      [translate("labelAugust", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("siteOutputDaily")[7].toLocaleString('de-DE'), processedTechnicalInformation.get("siteOutputMonthly")[7].toLocaleString('de-DE')],
      [translate("labelSeptember", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("siteOutputDaily")[8].toLocaleString('de-DE'), processedTechnicalInformation.get("siteOutputMonthly")[8].toLocaleString('de-DE')],
      [translate("labelOctober", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("siteOutputDaily")[9].toLocaleString('de-DE'), processedTechnicalInformation.get("siteOutputMonthly")[9].toLocaleString('de-DE')],
      [translate("labelNovember", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("siteOutputDaily")[10].toLocaleString('de-DE'), processedTechnicalInformation.get("siteOutputMonthly")[10].toLocaleString('de-DE')],
      [translate("labelDecember", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("siteOutputDaily")[11].toLocaleString('de-DE'), processedTechnicalInformation.get("siteOutputMonthly")[11].toLocaleString('de-DE')],
      ['', translate("labelTotalAmount", agentUserInput.get("offerLanguage")), processedTechnicalInformation.get("siteTotalAnnualOutput").toLocaleString('de-DE')]
    ],
  });
  
  doc.autoTable({
    startY: 85,
    margin: { left: 109.2 },
    tableWidth: 80,
    styles: {font: "TT_Firs_Neue"},
    didDrawCell: (data) => {
      if (data.section === 'body' && data.column.dataKey === 1 && data.row.index != 12) {
          doc.setFillColor(255, 243, 101);
          
          const coverRatios = getCoverRatioTable(technicalUserInput.get("annualPowerRequirement"), 0, processedTechnicalInformation.get("siteOutputDaily"), true, agentUserInput.get("offerLanguage"));
          const width = data.cell.width * Math.min(1, coverRatios[data.row.index][2] / 100);
          doc.rect(data.cell.x, data.cell.y + ((data.cell.height - data.cell.height * 0.8) / 2), width, data.cell.height * 0.8, 'FD');
      }
    },
    head: [[translate("labelTitleDailyNeed", agentUserInput.get("offerLanguage")), translate("labelAutarqy", agentUserInput.get("offerLanguage")) , translate("labelCoverRatio", agentUserInput.get("offerLanguage"))]],
    body: getCoverRatioTable(technicalUserInput.get("annualPowerRequirement"), 0, processedTechnicalInformation.get("siteOutputDaily"), false, agentUserInput.get("offerLanguage"))
  });

  if (draft === true) {
    pdfAddDraftWatermark(doc, agentUserInput);
  }
}