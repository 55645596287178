import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import DownloadPreviewPDF from './ReviewPDFPaneFolder/DownloadPreviewPDF'
import { calculateInverterCount } from './ReviewPDFPaneFolder/Resources/CalculateHelperFunctions.js';
import { getLossForClouding } from './ReviewPDFPaneFolder/Resources/QueryPVGISHelperFunctions.js';
import { useMsal } from "@azure/msal-react";
import { queryMiddleware, middlewareUrl } from "../Helper/queryMiddleware";
import { loginRequest } from "../authConfig";
import { translate } from '../Helper/Dictionary';

export default function ReviewPDFPane(props: any) {
  const {instance, accounts} = useMsal();

  const [pdfButtonDisabled, setPdfButtonDisabled] = React.useState(true);
  const [offer, setOffer] = React.useState(null);

  const [errorState, setErrorState] = React.useState(false);

  const updateAgent = (k: any, v: any) => {
    props.setAgentUserInput(new Map(props.agentUserInput.set(k,v)));
  }

  useEffect(() => {
    console.log(props.tenantsAvailable);

    props.setCanGoOn(false);

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    const url   = `/api/v1/staticmap`;
    const body  = {
      lat: props.technicalUserInput.get("coordinates")["lat"],
      lng: props.technicalUserInput.get("coordinates")["lng"]
    };

    loadTenant(props.agentUserInput.get("tenant"))

    instance.acquireTokenSilent(request).then((response) => {
      queryMiddleware(url, body, "POST", response.accessToken)
        .then((data) => updateProcessedTechnicalInformation("customerRoofPicture", data["staticPicture"]))
        .then(() => queryPVGIS());
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        queryMiddleware(url, body, "POST", response.accessToken)
          .then((data) => updateProcessedTechnicalInformation("customerRoofPicture", data["staticPicture"]))
          .then(() => queryPVGIS());
      });
    });
  }, [])

  const updateProcessedTechnicalInformation = (k: any, v: any) => {
    props.setProcessedTechnicalInformation(new Map(props.processedTechnicalInformation.set(k,v)));
  }

  async function loadTenant(tenantName: string) {
    const { roofPictures, logo, logoMetadata, logoOnAdditionalPages, copyright, dictionary, isExternal } = props.tenantsAvailable.get('tenants').tenantsData.filter((tenant: any) => tenant.tenant === tenantName)[0].data;
    const data = { roofPictures, logo, logoMetadata, logoOnAdditionalPages, copyright, dictionary, isExternal };
    updateAgent(tenantName, data);
  };

  function queryPVGIS(): any {
    const pvgisFetchPromises:any[] = [];

    for (var roof of props.technicalUserInput.get("roofs")) {
      const requestData = {
        latitude: props.technicalUserInput.get("coordinates")["lat"].toString(),
        longitude: props.technicalUserInput.get("coordinates")["lng"].toString(),
        peakpower: "1",
        loss: getLossForClouding(props.availableProducts, roof.get("angle"), roof.get("orientation"), roof.get("clouding")),
        angle: roof.get("angle"),
        aspect: roof.get("orientation")
      };
      pvgisFetchPromises.push(
        fetch(`${middlewareUrl}/api/v1/pvgis`,{
          headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json'
          },
          method: 'POST',
          body : JSON.stringify(requestData)
        })
        .then((data) => data.json())
      )
    }

    Promise.allSettled(pvgisFetchPromises)
    .then((results) => processPVGISresult(results));
  };

  function processPVGISresult(results: any) {
    var roofs = [];

    var promiseNumber = 0;

    for(var result of results) {		
      const parsedResult = JSON.parse(result.value);

      const em = new Array(12), ed = new Array(12);

      for (var i = 0; i < 12; i++){
        ed[i] = parseFloat(parsedResult.outputs.monthly.fixed[i].E_d);
        em[i] = parseFloat(parsedResult.outputs.monthly.fixed[i].E_m);
      }
      //const roof = new Map<string, any>();
      const roof = new Map();
      roof.set("id", props.technicalUserInput.get("roofs")[promiseNumber].get("id"));
      roof.set("ed", ed);
      roof.set("em", em);
      roofs.push(roof);

      promiseNumber = promiseNumber + 1;
    }

    var newProcessedTechnicalInformation = props.processedTechnicalInformation;
    newProcessedTechnicalInformation.set("roofs", roofs);
    
    newProcessedTechnicalInformation = calculateSiteMetrics(newProcessedTechnicalInformation);

    setPdfButtonDisabled(false);
    props.setCanGoOn(true);
    props.setProcessedTechnicalInformation(newProcessedTechnicalInformation);
  };

  function calculateSiteMetrics(newProcessedTechnicalInformation: Map<string, any>) {
    const siteOutputDaily = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const siteOutputMonthly = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    var siteTotalAnnualOutput = 0;

    var roofs = new Array();

    var siteBrickCount = 0;
    var siteTotalPowerRating = 0;
    var roofNumber = 0;
    for (var roof of newProcessedTechnicalInformation.get("roofs")) {
      const outputDaily = new Array(12);
      const outputMonthly = new Array(12);
      var newRoof = new Map();

      const brickCount = parseFloat(props.technicalUserInput.get("brick").get("countPerSquareMeter")) * parseFloat(props.technicalUserInput.get("roofs")[roofNumber].get("area"));
      const peakPower = brickCount
        * parseFloat(props.technicalUserInput.get("brick").get("peakPower"))
        / 1000;

      var totalAnnualOutput = 0;

      for (var i = 0; i <= 11; i++) {
        outputDaily[i] = Math.round(roof.get("ed")[i] * peakPower);
        outputMonthly[i] = Math.round(roof.get("em")[i] * peakPower);
        totalAnnualOutput = totalAnnualOutput + outputMonthly[i];

        siteOutputDaily[i] = siteOutputDaily[i] + outputDaily[i];
        siteOutputMonthly[i] = siteOutputMonthly[i] + outputMonthly[i];
      }
      siteTotalAnnualOutput = siteTotalAnnualOutput + totalAnnualOutput;

      siteBrickCount += brickCount;
      siteTotalPowerRating += peakPower;
      
      newRoof.set("outputDaily", outputDaily);
      newRoof.set("outputMonthly", outputMonthly);
      newRoof.set("totalAnnualOutput", totalAnnualOutput);
      newRoof.set("brickCount", brickCount);
      newRoof.set("peakPower", peakPower);

      roofs.push(newRoof);
      roofNumber = roofNumber + 1;
    }

    const inverterCount = calculateInverterCount(props.availableProducts, props.technicalUserInput, roofs, siteBrickCount);
    if (isNaN(inverterCount) || inverterCount < 0 || inverterCount == undefined) {
      setErrorState(true);
    }

    newProcessedTechnicalInformation.set("siteTotalAnnualOutput", siteTotalAnnualOutput);
    newProcessedTechnicalInformation.set("siteTotalPowerRating", siteTotalPowerRating);
    newProcessedTechnicalInformation.set("siteOutputDaily", siteOutputDaily);
    newProcessedTechnicalInformation.set("siteOutputMonthly", siteOutputMonthly);
    newProcessedTechnicalInformation.set("siteBrickCount", siteBrickCount);
    newProcessedTechnicalInformation.set("inverterCount", inverterCount);

    newProcessedTechnicalInformation.set("roofs", roofs);

    props.setProcessedTechnicalInformation(newProcessedTechnicalInformation);

    return newProcessedTechnicalInformation;
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {translate('labelReviewOfferPage', props.guiLanguage)}
      </Typography>
      <DownloadPreviewPDF offer = {offer} setOffer = {setOffer} verarbeiter = {props.verarbeiter} pdfButtonDisabled={pdfButtonDisabled} selectedDeal = {props.selectedDeal} availableProducts = {props.availableProducts} technicalUserInput={props.technicalUserInput} processedTechnicalInformation={props.processedTechnicalInformation} agentUserInput={props.agentUserInput} guiLanguage = {props.guiLanguage} tenantsAvailable = {props.tenantsAvailable}/>
      <br />
      <Box sx={{ width: '100%' }}>
      <Collapse in={errorState}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorState(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {translate('labelErrorCalculationPVW', props.guiLanguage)}
        </Alert>
      </Collapse>
    </Box>
    </React.Fragment>
  );
}


