import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DealSelection from './DealSelection';
import DetailsPane from './DetailsPane';
import ReviewPDFPane from './ReviewPDFPane';
import AgentSelection from './AgentSelection';
import ChangelogModal from './PlanningSurveyComponents/ChangelogModal';
import { translate } from '../Helper/Dictionary.js';
import GUILanguageSelector from './PlanningSurveyComponents/GUILanguageSelector';
import CompleteSurveyPane from './CompleteSurveyPane';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.autarq.com/">
        autarq GmbH
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['labelSelectDeal', 'labelSelectAgent', 'labelTechnicalInformation', 'labelReviewOfferPage', 'labelDownloadOfferPage'];

function getStepContent(step: number,
  selectedDeal: any,
  setSelectedDeal: any,
  technicalUserInput: any,
  setTechnicalUserInput: any, 
  processedTechnicalInformation:any, 
  setProcessedTechnicalInformation: any,
  availableProducts: any,
  setAvailableProducts: any,
  canGoOn: any,
  setCanGoOn: any,
  agentUserInput: any,
  setAgentUserInput: any,
  guiLanguage: any,
  tenantsAvailable: any,
  setTenantsAvailable: any,
  handlePipedriveInfos: any,
  sethandlePipedriveInfos: any,
  handleDealData: any,
  sethandleDealData: any
  ) {

  switch (step) {
    case 0:
      return <DealSelection setSelectedDeal = {setSelectedDeal} selectedDeal = {selectedDeal} availableProducts = {availableProducts} sethandlePipedriveInfos= {sethandlePipedriveInfos} setAvailableProducts = {setAvailableProducts} canGoOn={canGoOn} setCanGoOn={setCanGoOn} guiLanguage={guiLanguage} setTenantsAvailable={setTenantsAvailable} handleDealData={handleDealData} sethandleDealData={sethandleDealData}/>;
    case 1:
      return <AgentSelection handlePipedriveInfos={handlePipedriveInfos} setTechnicalUserInput = {setTechnicalUserInput} agentUserInput = {agentUserInput} setAgentUserInput = {setAgentUserInput} canGoOn={canGoOn} setCanGoOn={setCanGoOn} setSelectedDeal = {setSelectedDeal} selectedDeal = {selectedDeal} guiLanguage={guiLanguage} tenantsAvailable={tenantsAvailable}/>
    case 2:
      return <DetailsPane agentUserInput = {agentUserInput} selectedDeal = {selectedDeal} technicalUserInput = {technicalUserInput} setTechnicalUserInput = {setTechnicalUserInput} availableProducts = {availableProducts} setAvailableProducts = {setAvailableProducts} canGoOn={canGoOn} setCanGoOn={setCanGoOn} guiLanguage={guiLanguage} tenantsAvailable={tenantsAvailable} handleDealData={handleDealData} sethandleDealData={sethandleDealData}/>;
    case 3:
      return <ReviewPDFPane canGoOn={canGoOn} setCanGoOn={setCanGoOn} availableProducts = {availableProducts} selectedDeal = {selectedDeal} technicalUserInput = {technicalUserInput} processedTechnicalInformation = {processedTechnicalInformation} setProcessedTechnicalInformation = {setProcessedTechnicalInformation} agentUserInput = {agentUserInput} setAgentUserInput = {setAgentUserInput} guiLanguage={guiLanguage} tenantsAvailable={tenantsAvailable}/>;
    case 4:
      return <CompleteSurveyPane availableProducts = {availableProducts} selectedDeal = {selectedDeal} technicalUserInput = {technicalUserInput} processedTechnicalInformation = {processedTechnicalInformation} guiLanguage={guiLanguage} agentUserInput = {agentUserInput} tenantsAvailable={tenantsAvailable}/>
    default:
      throw new Error('Unknown step');
  }
}

const theme = createTheme();

export default function PlanningSurvey() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [guiLanguage, setGuiLanguage] = React.useState("de-DE");
  const [canGoOn, setCanGoOn] = React.useState(false);

  const handleNext = () => {	
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const initialCoordinates = {"coordinates": {"lat": 0, "lng": 0}};

  let initialAgentUserInput = {"tenant": "creaton", "typeOfCustomer": "consumer", "offerLanguage": "de-DE", "salesCountry": "DE"};  

  const [tenantsAvailable, setTenantsAvailable] = React.useState(new Map());
  const [agentUserInput, setAgentUserInput] = React.useState(new Map(Object.entries(initialAgentUserInput)));
  const [selectedDeal, setSelectedDeal] = React.useState(new Map());
  const [technicalUserInput, setTechnicalUserInput] = React.useState(new Map(Object.entries(initialCoordinates)));
  const [processedTechnicalInformation, setProcessedTechnicalInformation] = React.useState(new Map());
  const [availableProducts, setAvailableProducts] = React.useState({});
  const [handlePipedriveInfos, sethandlePipedriveInfos] = React.useState({});
  const [handleDealData, sethandleDealData] = React.useState({});

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            autarq GmbH
          </Typography>
          <Box sx={{ width: '10%', ml: 5 }}>
            <GUILanguageSelector guiLanguage = {guiLanguage} setGuiLanguage = {setGuiLanguage}></GUILanguageSelector>
          </Box>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            {translate('labelPlanningTool', guiLanguage)}
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{translate(label, guiLanguage)}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {
              <React.Fragment>
                {getStepContent(activeStep, selectedDeal, setSelectedDeal, technicalUserInput, setTechnicalUserInput, processedTechnicalInformation, setProcessedTechnicalInformation, availableProducts, setAvailableProducts, canGoOn, setCanGoOn, agentUserInput, setAgentUserInput, guiLanguage, tenantsAvailable, setTenantsAvailable, handlePipedriveInfos, sethandlePipedriveInfos, handleDealData, sethandleDealData)}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {(activeStep !== 0 && activeStep !== (steps.length - 1) ) && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      {translate('buttonBack', guiLanguage)}
                    </Button>
                  )}
                  {activeStep === (steps.length - 1) ? (null) :
                    (
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        disabled={!canGoOn}
                        sx={{ mt: 3, ml: 1 }}
                      >
                        {activeStep === steps.length - 2 ? translate('buttonFinish', guiLanguage) : translate('buttonNext', guiLanguage)}
                      </Button>
                    )
                  }
                  
                </Box>
              </React.Fragment>
            }
          </React.Fragment>
        </Paper>
        <ChangelogModal/>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
