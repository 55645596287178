var Mustache = require('mustache');

require('../TT_Firs_Neue_400');
require('../TT_Firs_Neue_500');

import { translate } from '../../../../Helper/Dictionary';
import { getTemplate } from '../MiscHelperFunctions';
import pdfAddHeader from './Helper/PDFAddHeader';
import pdfAddDraftWatermark from './Helper/PDFAddDraftWatermark';

export default function addVisualisationAndSampleSection(doc, agentUserInput, draft) {
  const tenantName = agentUserInput.get('tenant');

  doc.addPage();
  pdfAddHeader(doc, agentUserInput, translate("labelVisualisation", agentUserInput.get("offerLanguage")), translate("labelAndReferences", agentUserInput.get("offerLanguage")));

  doc.addImage(translate("siteSchemePicture", agentUserInput.get("offerLanguage")), 'jpeg', 20.8, 51, 169.4, 169.4*810/1440);

  const roofPictures = agentUserInput.get(agentUserInput.get("tenant"))["roofPictures"];

  doc.addImage(roofPictures[0], 'jpeg', 20.8, 150, 80, 80*187/255);
  doc.addImage(roofPictures[1], 'jpeg', 110, 150, 80, 80*187/255);
  doc.addImage(roofPictures[2], 'jpeg', 20.8, 220, 80, 80*188/255);
  doc.addImage(roofPictures[3], 'jpeg', 110, 220, 80, 80*188/255);

  doc.setTextColor(16, 25, 32);
  doc.setFontSize(10);
  doc.setFont('TT_Firs_Neue', 'normal');
  doc.text(`${getTemplate(agentUserInput.get(tenantName).dictionary, "copyright", 'language', agentUserInput.get("offerLanguage"))}`, 20.8, 286.5, {maxWidth: 164});

  if (draft === true) {
    pdfAddDraftWatermark(doc, agentUserInput);
  }
};