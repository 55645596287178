import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 700,
  overflow: "hidden",
  overflowY: "scroll",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ChangelogModal = (props: any) => {
  React.useEffect(() => {
    handleOpen();
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Typography align='center'>
        <Button onClick={handleOpen}>Changelog</Button>
      </Typography>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Changelog Planungstool
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Version 1.11.6
          <ul>
            <li>Eingeloggter Azure AD-Benutzer wird als Ersteller im PDF ausgegeben</li>
          </ul>
          Version 1.11.5
          <ul>
            <li>Kommissionierungszuschlag für Creaton-Angeobt hinzugefügt</li>
          </ul>
          Version 1.11.4
          <ul>
            <li>Bei einer Anlagenleistung über 20kWp wird nun bei einer Stückliste richtigerweise kein Preis mehr für das Elektropaket angezeigt</li>
          </ul>
          Version 1.11.3
          <ul>
            <li>Zollkosten für Bauprojektadressen in der Schweiz hinzugefügt</li>
          </ul>
          Version 1.11.2
          <ul>
            <li>Im Adressfeld wird nun korrekt die Hausnummer angezeigt</li>
          </ul>
          Version 1.11.1
          <ul>
            <li>Kleine Fehler im Angebot beseitigt</li>
            <li>Englische Übersetzung des Angebots verbessert</li>
            <li>Titel: Indikatives Angebot zu Unverbindliche Kostenschätzung geändert</li>
          </ul>
          Version 1.11.0
          <ul>
            <li>PDF wird erst als Entwurf angeboten</li>
          </ul>
          Version 1.10.0
          <ul>
            <li>Key-Account-Flags werden berücksichtigt</li>
            <li>Multi-Tenant-Support eingeführt</li>
          </ul>
          Version 1.9.3
          <ul>
            <li>Begleittext Elektropaket geändert</li>
            <li>Überschrift Seite 1 für Mandant Autarq geändert</li>
          </ul>
          Version 1.9.2
          <ul>
            <li>DGNB- und ESMC-Logo auf letzter PDF-Seite hinzugefügt</li>
          </ul>
          Version 1.9.1
          <ul>
            <li>Design-Award-Logos auf Titelseite ergänzt</li>
            <li>Sprachkorrekturen englisch</li>
          </ul>
          Version 1.9.0
          <ul>
            <li>Neuer Loginscreen</li>
            <li>Multi-Tenant-Unterstützung durch API</li>
          </ul>
          Version 1.8.1
          <ul>
            <li>Transportkosten bei bestimmten Angeboten hinzugefügt</li>
            <li>Preisermittlung über Produktvariationen</li>
          </ul>
          Version 1.8.0
          <ul>
            <li>Das ausgegebene PDF ist nun an das neue CI angepasst</li>
          </ul>
          Version 1.7.6
          <ul>
            <li>Realistischere Koeffizienten für die Verluste (insbesondere bei steilen Norddächern) eingefügt</li>
          </ul>
          Version 1.7.5
          <ul>
            <li>Hinzufügen einer Fortschritts- und Erfolgsanzeige für das Hochladen des Angebotes zu Pipedrive</li>
          </ul>
          Version 1.7.4
          <ul>
            <li>Sicherheitsupdates der Projektabhängigkeiten</li>
          </ul>
          Version 1.7.3
          <ul>
            <li>Mehrwertsteuer auf 0% gesenkt</li>
          </ul>
          Version 1.7.2
          <ul>
            <li>Dächer in Himmelsrichtung Nordwest werden nun in Tabelle korrekt angezeigt</li>
          </ul>
          Version 1.7.1
            <ul>
              <li>Preise werden für bestimmte Tenants korrekt angezeigt</li>
            </ul>
          Version 1.7.0
            <ul>
              <li>Unterstützung für mehrere Sprachen</li>
              <li>Englische Version des Indikativen Angebotes</li>
            </ul>
            Version 1.6.0
            <ul>
              <li>Unterstützung für Vertriebsgebiet Schweiz</li>
            </ul>
            Version 1.5.0
            <ul>
              <li>Adressen können nun über in Pipedrive hinterlegte Koordinaten angegeben werden</li>
            </ul>
            Version 1.4.2
            <ul>
              <li>Warnung über lange Lieferzeiten der Notstromkomponenten aufgenommen</li>
            </ul>
            Version 1.4.1
            <ul>
              <li>Aufräumarbeiten im Programmcode</li>
            </ul>
            Version 1.4.0
            <ul>
              <li>Hinzufügen einer Auswahl der Elektrokomponenten</li>
              <li>Ausgabe einer Stückliste für Elektrokomponenten, wenn ausgewählt</li>
              <li>Textverbesserungen in den Tabellen</li>
              <li>Preise für Händler, Partnerhändler, Endkunden und Verarbeiter sind nun vollständig hinterlegt</li>
            </ul>
            Version 1.3.0
            <ul>
              <li>Hinzufügen von Templates zur Unterscheidung von Händlern, Partnerhändlern, Endkunden und Verarbeitern</li>
              <li>Berücksichtigen der Templates bei der Ausgestaltung der Preisliste und Wahl der Preise</li>
            </ul>
            Version 1.2.0
            <ul>
              <li>Fehlermeldung, wenn Berechnung der Anzahl der PVW fehlschlägt</li>
              <li>Verluste durch Verschattungs-Dropdown ersetzt</li>
              <li>Anpassungen im Template für Kunden</li>
              <li>Logos von Dritttenants werden nun ebenso auf den Folgeseiten angezeigt</li>
            </ul>
            Version 1.1.0
            <ul>
              <li>Upload-Funktion zu Pipedrive hinzugefügt</li>
            </ul>
            Version 1.0.2
            <ul>
              <li>Wenn Adressat in Pipedrive Postcode hat, wird dieser benutzt, ansonsten Fallback auf PLZ</li>
            </ul>
            Version 1.0.1
            <ul>
              <li>Der manuell eingegebene Adressat kann nun aus einer Dropdown-Liste aus allen Participants des Deals ausgewählt werden</li>
              <li>Es kann über ein Select unter der Google-Maps-Karte bestimmt werden, ob der Kartenausschnitt im generierten PDF ein- oder ausgeblendet werden soll</li>
              <li>Es wurden Fehler behoben</li>
            </ul>
            Version 1.0.0
            <ul>
              <li>Erstes Produktivrelease</li>
            </ul>
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}

export default ChangelogModal
