var Mustache = require('mustache');
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

require('../TT_Firs_Neue_400');
require('../TT_Firs_Neue_500');

import { translate } from '../../../../Helper/Dictionary';
import { generateBOM } from '../GenerateBOM';
import { getTemplate } from '../MiscHelperFunctions';
import pdfAddHeader from './Helper/PDFAddHeader';
import pdfAddDraftWatermark from './Helper/PDFAddDraftWatermark';

export default function addBOMSection(doc, agentUserInput, availableProducts, processedTechnicalInformation, selectedDeal, technicalUserInput, tenantsAvailable, draft) {
  const tenantName = agentUserInput.get('tenant');
  const typeOfCustomer = agentUserInput.get("typeOfCustomer");

  doc.addPage();
  pdfAddHeader(doc, agentUserInput, translate("labelBOM", agentUserInput.get("offerLanguage")), translate("labelCraftRoof", agentUserInput.get("offerLanguage"))); 

  var inverter90 = 0;
  var inverter45 = 0;
  if( processedTechnicalInformation.get("inverterCount") % 2 == 0 ) {
    inverter90 = processedTechnicalInformation.get("inverterCount") / 2;
    inverter45 = 0;
  } else {
    inverter90 = (processedTechnicalInformation.get("inverterCount") - 1) / 2;
    inverter45 = 1;
  }

  const bomVariables = {
    tenantName: agentUserInput.get('tenant'),
    typeOfCustomer: agentUserInput.get('typeOfCustomer'),
    salesCountry: agentUserInput.get('salesCountry'),
    inverter45,
    inverter90,
  };

  doc.autoTable({
    startY: 55,
    margin: { left: 20.8, bottom: 20, top: 30 },
    tableWidth: 168.4,
    styles: {font: "TT_Firs_Neue"},
    columnStyles: {3: {detailsList: "yes"}},
    rowPageBreak: "avoid",
    halign: "right",
    didDrawPage: (data) => {
      if (draft === true) {
        pdfAddDraftWatermark(doc, agentUserInput);
      }
    },
    head: [
      (technicalUserInput.get("bomProfile") == 1 && typeOfCustomer != "consumer") ? [translate("labelTitlePosition",
      agentUserInput.get("offerLanguage")),
      translate("labelTitleCount",
      agentUserInput.get("offerLanguage")),
      translate("labelTitleNumber",
      agentUserInput.get("offerLanguage")),
      translate("labelTitleDescription",
      agentUserInput.get("offerLanguage")),
      translate("labelTitlePrice",
      agentUserInput.get("offerLanguage")),
      translate("labelTitleTotal",
      agentUserInput.get("offerLanguage"))] : [translate("labelTitlePosition",
      agentUserInput.get("offerLanguage")), translate("labelTitleCount",
      agentUserInput.get("offerLanguage")), translate("labelTitleNumber",
      agentUserInput.get("offerLanguage")), translate("labelTitleDescription",
      agentUserInput.get("offerLanguage"))]
    ],
    body: generateBOM(agentUserInput, availableProducts,  processedTechnicalInformation, selectedDeal, technicalUserInput, tenantsAvailable, bomVariables),
  });

  var newPageAfterComponentsTable = false;
  const logoMetadata = agentUserInput.get(tenantName)["logoMetadata"];

  if (doc.lastAutoTable.finalY > 150) {
    newPageAfterComponentsTable = true;
    doc.addPage();
    pdfAddHeader(doc, agentUserInput, translate("labelTerms", agentUserInput.get("offerLanguage")), translate("labelCraftRoof", agentUserInput.get("offerLanguage")));
    if (draft === true) {
      pdfAddDraftWatermark(doc, agentUserInput);
    }
  }

  doc.setTextColor(16, 25, 32);
  doc.setFontSize(10);
  doc.setFont('TT_Firs_Neue', 'normal');

  const date = new Date(selectedDeal.get("date"));

  const letterVariables = {
    agentName: agentUserInput.get('creatorForename') + ' ' + agentUserInput.get('creatorLastname'),
    dealDate: date.toLocaleDateString('de-DE'),
    siteBrickCount: processedTechnicalInformation.get("siteBrickCount").toLocaleString('de-DE'),
    brickName: technicalUserInput.get("brick").get("name").toString(),
    peakPower: technicalUserInput.get("brick").get("peakPower").toString(),
    siteTotalPowerRating: processedTechnicalInformation.get("siteTotalPowerRating").toLocaleString('de-DE'),
    siteTotalAnnualOutput: processedTechnicalInformation.get("siteTotalAnnualOutput").toLocaleString('de-DE'),
    batteryCapacity: Math.ceil(processedTechnicalInformation.get("siteTotalAnnualOutput")/1000).toLocaleString('de-DE')
  };

  doc.text(Mustache.render(getTemplate(agentUserInput.get(tenantName).dictionary, "technicalDisclaimerTemplate", 'language', agentUserInput.get("offerLanguage")), letterVariables), 20.8, newPageAfterComponentsTable ? 55 : ( doc.lastAutoTable.finalY + 15 ), {maxWidth: 168.4});
};